<template>
  <div class="page">
    <!-- <div class="head">
      <img class="cover" src="/assets/works/MovingBoxes/1.png" />
      <div class="info">
        <div class="title">The Moving Boxes</div>
        <div class="subtitle">Multi-functional Architectural Installation</div>
        <div class="desc">
          <div class="desc-title">Award</div>
          <div class="desc-content">Finalist of 3 with $200 reward</div>

          <div class="desc-title" style="margin: 10px 0 0 0;">Teammates</div>
          <div class="desc-content">Shuang Bi, Neng Zhu</div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="section-item">
        <div class="title">Installation
           Location</div>
        <div class="desc">
          Gallery space in Sophomore architecture studio, UIUC
        </div>
      </div>
      <div class="section-item">
        <div class="title">Stakeholders</div>
        <div class="desc">
          Schools of Architecture, sophomore architecture students, professors
        </div>
      </div>
      <div class="section-item">
        <div class="title">Goal</div>
        <div class="desc">
          Design a problem-solver installation for daily users and build it
          within budget
        </div>
      </div>
    </div> -->

    <div class="sk-ab sk-Project6_RoomSmart">
      <!-- <div class="sk-asset sk-Group-21-EAC77F28-15E3-4022-AF6B-E004B94E1AB6">
        <div class="sk-asset sk-Group-31-91E99C86-E257-4296-A67E-9E7AF2A1211B">
          <div
            class="sk-asset sk-Group-2-Copy-7F0453C1-27A5-4609-9438-CB30902B641E"
          >
            <div
              class="sk-asset sk-Rectangle-BCA64D19-FC4E-419A-B356-0A4B4CBF5902"
            ></div>
            <div
              class="sk-asset sk-Previous-Virtual-Ca-A7446F94-D6A0-4B83-912B-4E0686FF413E"
            >
              <span class="span1"
                >Previous: Virtual Career Fair Product Opportunity Study</span
              >
            </div>
            <div
              class="sk-asset sk-Path-6-BAF1429A-24D0-4298-83BC-92C229B89703"
            ></div>
          </div>
          <div
            class="sk-asset sk-Path-7-34BC9D21-287C-486F-9EE1-989C3E2B6D1D"
          ></div>
        </div>
        <div class="sk-asset sk-Group-32-12EF35E8-325A-4FFC-928F-F36560D50CB7">
          <div
            class="sk-asset sk-Group-2-Copy-2-03350361-EE46-43E6-9F88-F7686128568F"
          >
            <div
              class="sk-asset sk-Rectangle-D0113DA0-36E4-4C3F-BFA3-68BB2B98791C"
            ></div>
            <div
              class="sk-asset sk-Next-UX-Product-o-A640DDD5-CBF2-4B43-B352-A2F19AE86FB1"
            >
              <span class="span1"
                >Next: UX & Product of FurGo Pet Tracker & Device
              </span>
            </div>
            <div
              class="sk-asset sk-Path-6-11B6CE91-0091-4A11-8312-FC1B15A2BA37"
            ></div>
          </div>
          <div
            class="sk-asset sk-Path-7-Copy-CA89FF49-3A71-49E5-9FCE-5368094B60C7"
          ></div>
        </div>
        <div class="sk-asset sk-Group-17-47E49BA7-D1A5-417E-A75F-9660E1BD763C">
          <div
            class="sk-asset sk-Group-12-D07B9B20-CE1C-4513-B64D-A0529A77C2D2"
          >
            <div
              class="sk-asset sk-Group-4-AF61EC60-CCC6-45EA-AACA-AA5527D50EE9"
            ></div>
            <div
              class="sk-asset sk-Group-7-E142382C-56B1-405C-889D-93385A235DD1"
            ></div>
            <div
              class="sk-asset sk-Group-2-454190F3-7FBE-499C-99B7-9DBEBEB912D7"
            ></div>
          </div>
          <div
            class="sk-asset sk-If-you-would-like-to-317E8168-B077-45E3-B550-8A53D4F13821"
          >
            <span class="span1"
              >If you would like to work with an enthutiastic individual, grab a
              coffee with a big cat lover, talk about the crazy stuff going on
              in this world…</span
            >
          </div>
          <div class="sk-asset sk--40C478D4-0E65-4C5E-9EF3-E98D7B227B5C">
            <span class="span1">👇</span>
          </div>
          <div
            class="sk-asset sk-Connect-with-me-FE8EE853-E77E-49B2-880A-867DC74D6ECE"
          >
            <span class="span1">Connect with me: </span>
          </div>
        </div>
      </div> -->
      <div class="sk-asset sk-Group-30-3019A69E-0F8C-46C6-AA51-A7FCCBE94E8B">
        <div
          class="sk-asset sk-Iphone-X-Mockup-BE6BA788-D12D-486D-9C27-6A040F9FDA65"
        ></div>
        <div
          class="sk-asset sk-Academic-Project-Arc-553BDC6E-924E-44C2-8A73-E7AC5699B2D3"
        >
          <span class="span1"
            >Academic Project <br />Architectural Design | UI/UX Design</span
          >
        </div>
        <div
          class="sk-asset sk-App-for-Room-Reserva-975B6F71-ED26-4510-9F23-D784C4362ED0"
        >
          <span class="span1"
            >App for Room Reservation and Resources <br /><br />Honor Students
            Building at UIUC</span
          >
        </div>
        <div
          class="sk-asset sk-based-on-and-inspire-499A6009-783B-415F-9336-D9AE3EB94645"
        >
          <span class="span1">based on and inspired from</span>
        </div>
        <div
          class="sk-asset sk-Roomsmart-for-Isola-A01C3E32-9B99-4CF6-A88B-254E5A4C76DF"
        >
          <span class="span1">Roomsmart for ‘Isolation & Bridges’ </span>
        </div>
      </div>
      <div class="sk-asset sk-Group-29-5F77C0C1-5226-4CCE-A3E7-2523492DE7C3">
        <div
          class="sk-asset sk-Targeted-Users-D83EE54D-4888-4B49-8469-979AF84F1303"
        >
          <span class="span1">Targeted Users</span>
        </div>
        <div
          class="sk-asset sk-Product-Opportunity-29DD5E60-9FDA-4258-952D-ED5D201EEA08"
        >
          <span class="span1">Product Opportunity</span>
        </div>
        <div
          class="sk-asset sk-College-students-at-78354379-CB4B-4131-8A58-55A75BD93A87"
        >
          <span class="span1"
            >College students at UIUC and potentially students aross many
            universities</span
          >
        </div>
        <div
          class="sk-asset sk-How-might-we-organiz-A91FF75E-993F-44F3-8196-72CE8F81DF67"
        >
          <span class="span1"
            >How might we organize the scattered information about university
            facility resources and enourage students to fully utilize
            them?</span
          >
        </div>
        <div
          class="sk-asset sk-Rectangle-ABAE130B-7C3F-49A5-985E-DB5AE33CBC6F"
        ></div>
        <div
          class="sk-asset sk-Rectangle-Copy-49799CC2-D376-40D6-BD83-D8FD673034C7"
        ></div>
        <div
          class="sk-asset sk-Iphone-7-White2x-42704B74-2945-4397-97A1-6E08A291631B"
        ></div>
        <div
          class="sk-asset sk-Iphone-7-White2x-Copy-100F1FC9-E1C3-498A-92C0-725B9CF7F24E"
        ></div>
        <div
          class="sk-asset sk-Asset-1-Copy-6E74C98C-DDE7-4591-AD4B-3BB90EEDE079"
        ></div>
        <div
          class="sk-asset sk-Integrated-Informati-7B77E2BC-7253-425A-98A3-B2FBC27EC12F"
        >
          <span class="span1"
            >Integrated Information of University Facilities</span
          >
        </div>
        <div
          class="sk-asset sk-2Click-Room-Reserva-5773F4AA-A48F-4DD4-9031-0BC8FB68E984"
        >
          <span class="span1">2-Click Room Reservation </span>
        </div>
        <div
          class="sk-asset sk-Solve-for-the-Pain-P-1D402FF9-0E5C-4725-83F3-5A87EB333A4C"
        >
          <span class="span1">Solve for the Pain Point </span>
        </div>
        <div
          class="sk-asset sk-Inclusiveness-Conv-15767FC5-71C6-4287-AD97-2EA44CB45941"
        >
          <span class="span1">Inclusiveness & Convenience</span>
        </div>
        <div
          class="sk-asset sk-No-More-Learning-Cur-C67A6746-7AB0-4E42-8487-4BF58FAF15A0"
        >
          <span class="span1">No More Learning Curve</span>
        </div>
        <div
          class="sk-asset sk-Scattered-informatio-2D75E4C6-4B37-499C-9DDB-34862BB9D5D5"
        >
          <span class="span1"
            >Scattered information about university facility resources caused
            inconvenience and impeded full utilization from more students.</span
          >
        </div>
        <div
          class="sk-asset sk-Facility-recources-t-6E5FA1BD-3F99-48F7-8BB7-0B9CF6374E58"
        >
          <span class="span1"
            >Facility recources that are available to student use are abundant
            but hiden in corner of each department’s website. With information
            of all the usable and reservable space in one app not only saves
            time finding appropriate space but also inclusively invite all
            students to ustilize university resources to their benefits,
            avoiding exclusiveness from information discrepency among student
            groups.</span
          >
        </div>
        <div
          class="sk-asset sk-As-currently-studen-8568CE8E-413D-4FE6-8775-56690A7FABE9"
        >
          <span class="span1"
            >As currently, students make reservations on different pages of
            department, thus going through a different and sometimes annoying
            reservation process as each requires learning and familiarizing the
            process.<br /><br />This app delivers the 2-click easy reservation
            process to students by doing the work of connecting ports of other
            departments. Familirizing with one simple platform saves the effort
            of learning all.
          </span>
        </div>
        <div
          class="sk-asset sk-Asset-1-1109FB84-7BDC-4C61-BF6A-1634FFF6A76C"
        ></div>
        <div
          class="sk-asset sk-Artboard-82x-1389FFA5-C06D-43D1-8F2E-2A7297A8F6F2"
        ></div>
      </div>
      <div class="sk-asset sk-Group-9-E8AFBAFC-8094-43EB-9111-6CAB3854BF98">
        <div class="sk-asset sk-Group-8-C70E6544-086B-4EFB-BC8B-C55A795AAB89">
          <div
            class="sk-asset sk-story-5-440F6FB1-4DF1-49DF-861A-08DD8E3D85F1"
          ></div>
          <div
            class="sk-asset sk-Bitmap-1D8EC6B8-C027-455A-A0E8-59247DB3DCE2"
          ></div>
        </div>
        <div class="sk-asset sk-Group-3-0CF5E135-DDF1-406A-8C6F-A790F91F7473">
          <div
            class="sk-asset sk-story1-2A244603-12CF-4889-BFCE-F871F0ED3404"
          ></div>
          <div
            class="sk-asset sk-Small-group-40E3FA2F-1E61-47CC-97AC-7A2C85AD5B38"
          >
            <span class="span1">Small group</span>
          </div>
          <div class="sk-asset sk-Ok-E4E3C5F5-E382-4EA1-8739-2E7905EE50AB">
            <span class="span1">Ok</span>
          </div>
          <div
            class="sk-asset sk-Need-a-room-for-meet-E9839E98-42B7-465C-9DE2-F94528DE8F43"
          >
            <span class="span1">Need a room for meeting next week</span>
          </div>
        </div>
        <div class="sk-asset sk-Group-6-F68389AD-8DB2-4278-9756-FEA5654D6A67">
          <div
            class="sk-asset sk-story3-9EE234F1-E97A-4036-AD68-FC381922EFD4"
          ></div>
          <div class="sk-asset sk-Ok-490B0F71-6809-4BB3-9BD7-ABA7CFBDF3EB">
            <span class="span1">Ok</span>
          </div>
          <div
            class="sk-asset sk-UGLs-full-Lets-ju-034CE976-5716-4BA3-8D37-9C408C95A169"
          >
            <span class="span1"
              >UGL‘s full. Let’s just meet in our school atrium.</span
            >
          </div>
        </div>
        <div
          class="sk-asset sk-story4-F2DE6878-0E15-46BA-9403-19E4782B0739"
        ></div>
        <div class="sk-asset sk-Group-5-A9BD9B8E-9202-42DE-BC74-B99EB73686E8">
          <div
            class="sk-asset sk-UGL-is-full-Emmw-F8C96A19-BFE0-471E-901F-054E1B6E7AD4"
          >
            <span class="span1"
              >UGL is full. Emm...where can I find other rooms?</span
            >
          </div>
          <div
            class="sk-asset sk-story2-226762D7-FE21-4333-A4F5-65F945FF7986"
          ></div>
          <div class="sk-asset sk--14FE9B12-895C-40B9-8EA8-E2555CE120CA">
            <span class="span1">???</span>
          </div>
          <div class="sk-asset sk--6BDE1F3C-8280-4585-8757-49D876F8A323">
            <span class="span1">??</span>
          </div>
          <div class="sk-asset sk--3F14D9CD-17A7-4859-8881-F89DC8EC8D8D">
            <span class="span1">?</span>
          </div>
          <div class="sk-asset sk--3021CF1E-2893-4D75-BB8A-0FF662CED909">
            <span class="span1">??</span>
          </div>
        </div>
        <div
          class="sk-asset sk-Meeting-in-noise-wit-241E8AE9-91DA-4B02-9A2C-5B19FD4F765C"
        >
          <span class="span1">Meeting in noise with frustration</span>
        </div>
      </div>
      <div class="sk-asset sk-STORYBOARD-50652A8C-9B7A-49AD-9262-0D12AA879948">
        <span class="span1">STORYBOARD</span>
      </div>
      <div class="sk-asset sk-Group-10-E9D9D0DC-C3B8-4E8C-82B2-BAB6E1C76A82">
        <div
          class="sk-asset sk-FUNCTIONALITY-RESEAR-DF51209F-20C6-4EAF-9522-E92BE891C30B"
        >
          <span class="span1"
            >FUNCTIONALITY RESEARCH & INFORMATION ARCHITECTURE</span
          >
        </div>
        <div
          class="sk-asset sk-sitemap-FBAA9608-7A7C-4806-A8CB-4185048630E7"
        ></div>
        <div
          class="sk-asset sk-CONTENT-REQUIREMENTS--B3D5F083-F63E-47E5-9765-AA816F2BF51A"
        >
          <span class="span1">CONTENT REQUIREMENTS </span>
        </div>
        <div
          class="sk-asset sk-from-school-research-result-4A8C2EE9-2F1E-4E1A-816D-7BEC6444CEB7"
        >
          <span class="span1">from school research result</span>
        </div>
        <div
          class="sk-asset sk-1-Building-names-2-C7755819-D55E-4B73-B700-C3308E6AB9F4"
        >
          <span class="span1"
            >1. Building names<br />2. room specific location<br />3. Room types
            and numbers<br />4. Availability--time slot<br />5.
            Contact--address, phone number, email<br />6. Room features/uses<br />7.
            Room equipment<br />8. Reservation policies and guidelines<br />9.
            Room use policy<br />10. My NetID account<br />11. Information
            regarding technical or NetID help</span
          >
        </div>
        <div
          class="sk-asset sk-FUNCTIONALITY-REQUIREMENTS-13BC7955-ED57-432D-99F1-C6F42C04BA75"
        >
          <span class="span1">FUNCTIONALITY REQUIREMENTS</span>
        </div>
        <div class="sk-asset sk---DACF5EE0-5211-47DC-A7F0-18F95A8BFE3B">
          <span class="span1"> </span>
        </div>
        <div
          class="sk-asset sk-from-school-research-result-D200C055-C2C1-45F7-975F-C53E33EC1957"
        >
          <span class="span1">from school research result</span>
        </div>
        <div
          class="sk-asset sk-1-Search-for-rooms-32652C5A-0342-4172-9AF1-ED565DA7AC00"
        >
          <span class="span1"
            >1. Search for rooms using name and filter<br />2. Contact
            corresponding information center<br />3. See room pictures and
            features<br />4. Read reservation related information<br />5.
            Reserve rooms<br />6. Edit/cancel reservation<br />7. Technical
            help<br />8. Send emails within the app<br />9. View existing
            bookings</span
          >
        </div>
      </div>
      <div class="sk-asset sk-WIREFRAME-7D808D87-9ED8-458B-B273-64E9F15D4D5D">
        <span class="span1">WIREFRAME</span>
      </div>
      <div
        class="sk-asset sk-The-app-gives-inclus-6B9F4724-766A-4670-9ABB-4BD74495C73F"
      >
        <span class="span1"
          >The app gives inclusive reservable options to students, where they
          can find information on all reservable spaces aross the campus. Each
          department deals with the reservation requests all on one simple
          platform.<br />Students can quickly reserve a space on both bulding
          page, or reserve after viewing detailed information about a room on
          the room page. Students can easily view and cancel their reservataion
          from their phone.</span
        >
      </div>
      <div
        class="sk-asset sk-Group-39-4087725F-8F03-4DCD-8ABA-3920DCAEABEA"
      ></div>
      <div
        class="sk-asset sk-PROTOTYPE-DESIGN-AD150A32-BCD7-473C-A2C7-F004B88443EB"
      >
        <span class="span1">PROTOTYPE DESIGN</span>
      </div>
      <div
        class="sk-asset sk-Group-27-D40E2368-FC88-4B4E-BA59-4B246587F002"
      ></div>
      <div
        class="sk-asset sk-Group-33-E461AD76-E067-4596-88E8-D7CA2D4F8126"
      ></div>
      <div
        class="sk-asset sk-Group-34-EAD3DF32-9078-44C6-AA8A-C29FB3D827BC"
      ></div>
      <div class="sk-asset sk-UI-Design-0BBDD381-5E10-41E8-8201-6B17A13CDC31">
        <span class="span1">UI Design</span>
      </div>
      <div
        class="sk-asset sk-Facility-Information-860CDFFE-EC5A-4F50-9F4A-0BA6061E7B93"
      >
        <span class="span1">Facility Information All in One</span>
      </div>
      <div
        class="sk-asset sk-Reservation-Userflow-589F8B46-B749-4E17-9140-B51D8002F716"
      >
        <span class="span1">Reservation Userflow </span>
      </div>
      <div
        class="sk-asset sk-Color-palatte-is-bas-00F06621-9090-461D-930D-3FA2A7CAB3A5"
      >
        <span class="span1"
          >Color palatte is based on University spirit color orange and blue
          with a twist for a more clean look and delighted mood.</span
        >
      </div>
      <div
        class="sk-asset sk-This-app-allows-stud-9D769B3C-3E60-4235-8408-4933B57622B4"
      >
        <span class="span1"
          >This app allows students to browse information about all reservable
          and usable space cross the campus, and find availibility of each
          space.</span
        >
      </div>
      <div
        class="sk-asset sk-Students-can-find-de-13BADF52-19EA-4ACB-9B54-09D2D16A6E8E"
      >
        <span class="span1"
          >Students can find details about a space including locations,
          policies, etc., and make reservation of any facility in 2 clicks. They
          can also find all contact information and details in history page with
          easy clicks.</span
        >
      </div>
      <div class="sk-asset sk-Group-11-B6F178D9-F107-459C-A228-E39C7FC29AAC">
        <div
          class="sk-asset sk-CURRENT-PROBLEM-ANAL-6AB509B2-74CC-41E5-8658-B7AA54EBDFC4"
        >
          <span class="span1">CURRENT PROBLEM ANALYSIS</span>
        </div>
        <div
          class="sk-asset sk-How-was-I-inspired-B7AD66D5-B598-44ED-9C21-ACB06F88A861"
        >
          <span class="span1">How was I inspired?</span>
        </div>
        <div
          class="sk-asset sk-A-different-room-requesting-form-A6164D1A-CDC4-46C3-ACA4-3B6FB7FE0B64"
        ></div>
        <div
          class="sk-asset sk-A-public-space-reser-6FEDF2A1-D6F3-45CC-890C-100A269D47F1"
        ></div>
        <div
          class="sk-asset sk-The-main-form-that-c-5A9485C4-65E0-4AAA-A378-727FB769F4B1"
        ></div>
        <div
          class="sk-asset sk-Each-schoolbuilding-55EC8341-BA4F-4728-AF5C-B382067696AB"
        >
          <span class="span1"
            >Each school/building requires students to reserve rooms through
            their department website several years ago. <br /><br />A new room
            reservation website was developed recently but includes only library
            rooms. There are many other spaces of various functions in other
            academic buildings, but currently requiring students to reserve
            through their seperate online forms. <br /><br />Space resouces cost
            time to find information on and figure out how to reserve.
            University website does not have a uniform structure and layout, and
            therefore information is scattered here and there. Website takes on
            different UI designs for room reservation of different buildings,
            making it difficult for users to quickly reserve a room without
            studying the website and the reservation chart.<br /><br />Students
            want to know what rooms on campus they can reserve for their
            specific use, and choose based on what they look like and what
            functions they serve, but the current website does not provide
            comprehensive information.</span
          >
        </div>
        <div
          class="sk-asset sk-Rec-Bullet-CF073E20-F5E5-4FA9-B268-2E77AED3B7EC"
        ></div>
        <div
          class="sk-asset sk-Rec-Bullet-Copy-6C6EF3D3-D21F-40B1-BA7F-61EA298F4A8E"
        ></div>
        <div
          class="sk-asset sk-Rec-Bullet-Copy-2-B1882271-D5A7-43E5-A294-82FED4DFE6B8"
        ></div>
        <div
          class="sk-asset sk-Rec-Bullet-Copy-3-9ABA2DED-C978-4B74-B1F0-9021F171DB0F"
        ></div>
        <div
          class="sk-asset sk-Image-1-42829387-B4A2-4CE2-BFD1-7A42F6D9F354"
        ></div>
        <div
          class="sk-asset sk-Image-3-E410DC35-FDF0-44D5-9AAF-B88EF09BD42B"
        ></div>
        <div
          class="sk-asset sk-Image-5-2D4368B8-285E-4AF6-8FE7-472DCC948D2A"
        ></div>
        <div
          class="sk-asset sk-The-student-honor-bu-B2EBA3A0-77E4-460C-81E8-354C5EFF82C9"
        >
          <span class="span1"
            >The student honor building project requires us to design a building
            that embrace the surrounding while providing an open environment for
            honor students’ daily use, therefore having many rooms that students
            can reserve, such as art studio, outdoor event space and library
            rooms. I was reminded of our time at UIUC having difficulty of
            finding information on reserving rooms, and inspired to design an
            app that includes all resersable rooms and facility information for
            convenience of students.</span
          >
        </div>
      </div>
      <div
        class="sk-asset sk-Int-Rendering-1-FF4A8DC7-6C18-4F3E-84DB-EE89E2205B5A"
      ></div>
      <div
        class="sk-asset sk-Architecture-Design-B33B3391-83A9-47B7-8DBB-D645A94976DD"
      >
        <span class="span1">Architecture Design COMING SOON...</span>
      </div>
    </div>
    <Turnpage :previous="previous" :next="next"></Turnpage>
  </div>
</template>

<script>
import Turnpage from "@/components/turnpage.vue";

export default {
  name: "MovingBoxes",
  components: {
    Turnpage
  },
  data: () => {
    return {
      previous: {
        title: "INSTALLATION DESIGN",
        name: "MovingBoxes"
      },
      next: {
        title: "FINTECH PRODUCT OPPORTUNITIES",
        name: "Capstone"
      },
      handler: null
    };
  }
};
</script>

<style lang="scss" scoped>
.page {
  text-align: left;
}

/* Generated by Genus plugin */

/* Boiler plate CSS 
sk-ab is css style applied to full art board
sk-asset is common css property applied to every css style generated by genus plugin 
*/

.sk-ab {
  position: relative;
  overflow: hidden;
}

.sk-asset {
  position: absolute;
  background-repeat: no-repeat;
}

/* Specific to Project#6_RoomSmart */
.sk-ab.sk-Project6_RoomSmart {
  background-color: #ffffffff;
  width: 100%;
  height: 9020px;
}

.sk-asset.sk-Rectangle-BCA64D19-FC4E-419A-B356-0A4B4CBF5902 {
  background-image: url("/assets/works/RoomSmart/images/Rectangle-BCA64D19-FC4E-419A-B356-0A4B4CBF5902.png");
  left: -6px;
  top: -6px;
  width: 620px;
  height: 70px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Rectangle-BCA64D19-FC4E-419A-B356-0A4B4CBF5902 {
    background-image: url("/assets/works/RoomSmart/images/Rectangle-BCA64D19-FC4E-419A-B356-0A4B4CBF5902@2x.png");
    background-size: 620px 70px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Rectangle-BCA64D19-FC4E-419A-B356-0A4B4CBF5902 {
    background-image: url("/assets/works/RoomSmart/images/Rectangle-BCA64D19-FC4E-419A-B356-0A4B4CBF5902@3x.png");
    background-size: 620px 70px;
  }
}
.sk-asset.sk-Previous-Virtual-Ca-A7446F94-D6A0-4B83-912B-4E0686FF413E {
  left: 8px;
  top: 19px;
  width: 620px;
  height: 33px;
}

.sk-asset.sk-Previous-Virtual-Ca-A7446F94-D6A0-4B83-912B-4E0686FF413E {
  font-family: "Andale Mono";
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #826febff;
  font-size: 18px;

  opacity: 1;
  text-align: center;
  font-kerning: -1;
}

.sk-asset.sk-Previous-Virtual-Ca-A7446F94-D6A0-4B83-912B-4E0686FF413E .span1 {
  font-family: "Andale Mono";
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #826feb;
  font-size: 18px;
}

.sk-asset.sk-Path-6-BAF1429A-24D0-4298-83BC-92C229B89703 {
  background-image: url("/assets/works/RoomSmart/images/Path-6-BAF1429A-24D0-4298-83BC-92C229B89703.png");
  left: 19px;
  top: 16px;
  width: 15px;
  height: 26px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Path-6-BAF1429A-24D0-4298-83BC-92C229B89703 {
    background-image: url("/assets/works/RoomSmart/images/Path-6-BAF1429A-24D0-4298-83BC-92C229B89703@2x.png");
    background-size: 15px 26px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Path-6-BAF1429A-24D0-4298-83BC-92C229B89703 {
    background-image: url("/assets/works/RoomSmart/images/Path-6-BAF1429A-24D0-4298-83BC-92C229B89703@3x.png");
    background-size: 15px 26px;
  }
}
.sk-asset.sk-Group-2-Copy-7F0453C1-27A5-4609-9438-CB30902B641E {
  left: 0px;
  top: 0px;
  width: 628px;
  height: 58px;
}

.sk-asset.sk-Path-7-34BC9D21-287C-486F-9EE1-989C3E2B6D1D {
  background-image: url("/assets/works/RoomSmart/images/Path-7-34BC9D21-287C-486F-9EE1-989C3E2B6D1D.png");
  left: 40px;
  top: 29px;
  width: 1px;
  height: 1px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Path-7-34BC9D21-287C-486F-9EE1-989C3E2B6D1D {
    background-image: url("/assets/works/RoomSmart/images/Path-7-34BC9D21-287C-486F-9EE1-989C3E2B6D1D@2x.png");
    background-size: 1px 1px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Path-7-34BC9D21-287C-486F-9EE1-989C3E2B6D1D {
    background-image: url("/assets/works/RoomSmart/images/Path-7-34BC9D21-287C-486F-9EE1-989C3E2B6D1D@3x.png");
    background-size: 1px 1px;
  }
}
.sk-asset.sk-Group-31-91E99C86-E257-4296-A67E-9E7AF2A1211B {
  left: 0px;
  top: 0px;
  width: 628px;
  height: 58px;
}

.sk-asset.sk-Rectangle-D0113DA0-36E4-4C3F-BFA3-68BB2B98791C {
  background-image: url("/assets/works/RoomSmart/images/Rectangle-D0113DA0-36E4-4C3F-BFA3-68BB2B98791C.png");
  left: 16px;
  top: -6px;
  width: 571px;
  height: 70px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Rectangle-D0113DA0-36E4-4C3F-BFA3-68BB2B98791C {
    background-image: url("/assets/works/RoomSmart/images/Rectangle-D0113DA0-36E4-4C3F-BFA3-68BB2B98791C@2x.png");
    background-size: 571px 70px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Rectangle-D0113DA0-36E4-4C3F-BFA3-68BB2B98791C {
    background-image: url("/assets/works/RoomSmart/images/Rectangle-D0113DA0-36E4-4C3F-BFA3-68BB2B98791C@3x.png");
    background-size: 571px 70px;
  }
}
.sk-asset.sk-Next-UX-Product-o-A640DDD5-CBF2-4B43-B352-A2F19AE86FB1 {
  left: 0px;
  top: 19px;
  width: 581px;
  height: 33px;
}

.sk-asset.sk-Next-UX-Product-o-A640DDD5-CBF2-4B43-B352-A2F19AE86FB1 {
  font-family: "Andale Mono";
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #826febff;
  font-size: 18px;

  opacity: 1;
  text-align: center;
  font-kerning: -1;
}

.sk-asset.sk-Next-UX-Product-o-A640DDD5-CBF2-4B43-B352-A2F19AE86FB1 .span1 {
  font-family: "Andale Mono";
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #826feb;
  font-size: 18px;
}

.sk-asset.sk-Path-6-11B6CE91-0091-4A11-8312-FC1B15A2BA37 {
  background-image: url("/assets/works/RoomSmart/images/Path-6-11B6CE91-0091-4A11-8312-FC1B15A2BA37.png");
  left: 547px;
  top: 16px;
  width: 15px;
  height: 26px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Path-6-11B6CE91-0091-4A11-8312-FC1B15A2BA37 {
    background-image: url("/assets/works/RoomSmart/images/Path-6-11B6CE91-0091-4A11-8312-FC1B15A2BA37@2x.png");
    background-size: 15px 26px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Path-6-11B6CE91-0091-4A11-8312-FC1B15A2BA37 {
    background-image: url("/assets/works/RoomSmart/images/Path-6-11B6CE91-0091-4A11-8312-FC1B15A2BA37@3x.png");
    background-size: 15px 26px;
  }
}
.sk-asset.sk-Group-2-Copy-2-03350361-EE46-43E6-9F88-F7686128568F {
  left: 0px;
  top: 0px;
  width: 581px;
  height: 58px;
}

.sk-asset.sk-Path-7-Copy-CA89FF49-3A71-49E5-9FCE-5368094B60C7 {
  background-image: url("/assets/works/RoomSmart/images/Path-7-Copy-CA89FF49-3A71-49E5-9FCE-5368094B60C7.png");
  left: 13px;
  top: 29px;
  width: 1px;
  height: 1px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Path-7-Copy-CA89FF49-3A71-49E5-9FCE-5368094B60C7 {
    background-image: url("/assets/works/RoomSmart/images/Path-7-Copy-CA89FF49-3A71-49E5-9FCE-5368094B60C7@2x.png");
    background-size: 1px 1px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Path-7-Copy-CA89FF49-3A71-49E5-9FCE-5368094B60C7 {
    background-image: url("/assets/works/RoomSmart/images/Path-7-Copy-CA89FF49-3A71-49E5-9FCE-5368094B60C7@3x.png");
    background-size: 1px 1px;
  }
}
.sk-asset.sk-Group-32-12EF35E8-325A-4FFC-928F-F36560D50CB7 {
  left: 779px;
  top: 0px;
  width: 581px;
  height: 58px;
}

.sk-asset.sk-Group-4-AF61EC60-CCC6-45EA-AACA-AA5527D50EE9 {
  background-image: url("/assets/works/RoomSmart/images/Group-4-AF61EC60-CCC6-45EA-AACA-AA5527D50EE9.png");
  left: 0px;
  top: 0px;
  width: 40px;
  height: 40px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Group-4-AF61EC60-CCC6-45EA-AACA-AA5527D50EE9 {
    background-image: url("/assets/works/RoomSmart/images/Group-4-AF61EC60-CCC6-45EA-AACA-AA5527D50EE9@2x.png");
    background-size: 40px 40px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Group-4-AF61EC60-CCC6-45EA-AACA-AA5527D50EE9 {
    background-image: url("/assets/works/RoomSmart/images/Group-4-AF61EC60-CCC6-45EA-AACA-AA5527D50EE9@3x.png");
    background-size: 40px 40px;
  }
}
.sk-asset.sk-Group-7-E142382C-56B1-405C-889D-93385A235DD1 {
  background-image: url("/assets/works/RoomSmart/images/Group-7-E142382C-56B1-405C-889D-93385A235DD1.png");
  left: 155px;
  top: 0px;
  width: 40px;
  height: 40px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Group-7-E142382C-56B1-405C-889D-93385A235DD1 {
    background-image: url("/assets/works/RoomSmart/images/Group-7-E142382C-56B1-405C-889D-93385A235DD1@2x.png");
    background-size: 40px 40px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Group-7-E142382C-56B1-405C-889D-93385A235DD1 {
    background-image: url("/assets/works/RoomSmart/images/Group-7-E142382C-56B1-405C-889D-93385A235DD1@3x.png");
    background-size: 40px 40px;
  }
}
.sk-asset.sk-Group-2-454190F3-7FBE-499C-99B7-9DBEBEB912D7 {
  background-image: url("/assets/works/RoomSmart/images/Group-2-454190F3-7FBE-499C-99B7-9DBEBEB912D7.png");
  left: 80px;
  top: 0px;
  width: 40px;
  height: 40px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Group-2-454190F3-7FBE-499C-99B7-9DBEBEB912D7 {
    background-image: url("/assets/works/RoomSmart/images/Group-2-454190F3-7FBE-499C-99B7-9DBEBEB912D7@2x.png");
    background-size: 40px 40px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Group-2-454190F3-7FBE-499C-99B7-9DBEBEB912D7 {
    background-image: url("/assets/works/RoomSmart/images/Group-2-454190F3-7FBE-499C-99B7-9DBEBEB912D7@3x.png");
    background-size: 40px 40px;
  }
}
.sk-asset.sk-Group-12-D07B9B20-CE1C-4513-B64D-A0529A77C2D2 {
  left: 323px;
  top: 161px;
  width: 195px;
  height: 40px;
}

.sk-asset.sk-If-you-would-like-to-317E8168-B077-45E3-B550-8A53D4F13821 {
  left: 0px;
  top: 0px;
  width: 840px;
  height: 72px;
}

.sk-asset.sk-If-you-would-like-to-317E8168-B077-45E3-B550-8A53D4F13821 {
  font-family: "Avenir", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 24px;
  line-height: 36px;
  opacity: 1;
  text-align: justified;
  font-kerning: 0;
}

.sk-asset.sk-If-you-would-like-to-317E8168-B077-45E3-B550-8A53D4F13821 .span1 {
  font-family: "Avenir", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 24px;
}

.sk-asset.sk--40C478D4-0E65-4C5E-9EF3-E98D7B227B5C {
  left: 403px;
  top: 96px;
  width: 36px;
  height: 36px;
}

.sk-asset.sk--40C478D4-0E65-4C5E-9EF3-E98D7B227B5C {
  font-family: "Apple Color Emoji";
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 36px;
  line-height: 36px;
  opacity: 1;
  text-align: justified;
  font-kerning: 0;
}

.sk-asset.sk--40C478D4-0E65-4C5E-9EF3-E98D7B227B5C .span1 {
  font-family: "Apple Color Emoji";
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 36px;
}

.sk-asset.sk-Connect-with-me-FE8EE853-E77E-49B2-880A-867DC74D6ECE {
  left: 89px;
  top: 153px;
  width: 223px;
  height: 36px;
}

.sk-asset.sk-Connect-with-me-FE8EE853-E77E-49B2-880A-867DC74D6ECE {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 24px;
  line-height: 36px;
  opacity: 1;
  text-align: center;
  font-kerning: 0;
}

.sk-asset.sk-Connect-with-me-FE8EE853-E77E-49B2-880A-867DC74D6ECE .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 24px;
}

.sk-asset.sk-Group-17-47E49BA7-D1A5-417E-A75F-9660E1BD763C {
  left: 260px;
  top: 172px;
  width: 840px;
  height: 201px;
}

.sk-asset.sk-Group-21-EAC77F28-15E3-4022-AF6B-E004B94E1AB6 {
  left: 40px;
  top: 9402px;
  width: 1360px;
  height: 373px;
}

.sk-asset.sk-Iphone-X-Mockup-BE6BA788-D12D-486D-9C27-6A040F9FDA65 {
  background-image: url("/assets/works/RoomSmart/images/Iphone-X-Mockup-BE6BA788-D12D-486D-9C27-6A040F9FDA65.png");
  left: 0px;
  top: 89px;
  width: 100%;
  height: 789px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Iphone-X-Mockup-BE6BA788-D12D-486D-9C27-6A040F9FDA65 {
    background-image: url("/assets/works/RoomSmart/images/Iphone-X-Mockup-BE6BA788-D12D-486D-9C27-6A040F9FDA65@2x.png");
    background-size: 100% 789px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Iphone-X-Mockup-BE6BA788-D12D-486D-9C27-6A040F9FDA65 {
    background-image: url("/assets/works/RoomSmart/images/Iphone-X-Mockup-BE6BA788-D12D-486D-9C27-6A040F9FDA65@3x.png");
    background-size: 100% 789px;
  }
}
.sk-asset.sk-Academic-Project-Arc-553BDC6E-924E-44C2-8A73-E7AC5699B2D3 {
  left: 181px;
  top: 780px;
  width: 404px;
  height: 59px;
}

.sk-asset.sk-Academic-Project-Arc-553BDC6E-924E-44C2-8A73-E7AC5699B2D3 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #404040ff;
  font-size: 18px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Academic-Project-Arc-553BDC6E-924E-44C2-8A73-E7AC5699B2D3 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #404040;
  font-size: 18px;
}

.sk-asset.sk-App-for-Room-Reserva-975B6F71-ED26-4510-9F23-D784C4362ED0 {
  left: 181px;
  top: 547px;
  width: 530px;
  height: 168px;
}

.sk-asset.sk-App-for-Room-Reserva-975B6F71-ED26-4510-9F23-D784C4362ED0 {
  font-family: "DIN Alternate";
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 30px;
  line-height: 48px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-App-for-Room-Reserva-975B6F71-ED26-4510-9F23-D784C4362ED0 .span1 {
  font-family: "DIN Alternate";
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 30px;
}

.sk-asset.sk-based-on-and-inspire-499A6009-783B-415F-9336-D9AE3EB94645 {
  left: 181px;
  top: 604px;
  width: 404px;
  height: 59px;
}

.sk-asset.sk-based-on-and-inspire-499A6009-783B-415F-9336-D9AE3EB94645 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #404040ff;
  font-size: 18px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-based-on-and-inspire-499A6009-783B-415F-9336-D9AE3EB94645 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #404040;
  font-size: 18px;
}

.sk-asset.sk-Roomsmart-for-Isola-A01C3E32-9B99-4CF6-A88B-254E5A4C76DF {
  left: 181px;
  top: 414px;
  width: 363px;
  height: 110px;
}

.sk-asset.sk-Roomsmart-for-Isola-A01C3E32-9B99-4CF6-A88B-254E5A4C76DF {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #6b83bdff;
  font-size: 36px;
  line-height: 48px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Roomsmart-for-Isola-A01C3E32-9B99-4CF6-A88B-254E5A4C76DF .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #6b83bd;
  font-size: 36px;
}

.sk-asset.sk-Group-30-3019A69E-0F8C-46C6-AA51-A7FCCBE94E8B {
  left: 0px;
  top: -177px;
  width: 100%;
  height: 966px;
}

.sk-asset.sk-Rectangle-7DF3452E-2092-42CE-B3F2-FC8D873C3576 {
  background-image: url("/assets/works/RoomSmart/images/Rectangle-7DF3452E-2092-42CE-B3F2-FC8D873C3576.png");
  left: 0px;
  top: 0px;
  width: 100%;
  height: 83px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Rectangle-7DF3452E-2092-42CE-B3F2-FC8D873C3576 {
    background-image: url("/assets/works/RoomSmart/images/Rectangle-7DF3452E-2092-42CE-B3F2-FC8D873C3576@2x.png");
    background-size: 100% 83px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Rectangle-7DF3452E-2092-42CE-B3F2-FC8D873C3576 {
    background-image: url("/assets/works/RoomSmart/images/Rectangle-7DF3452E-2092-42CE-B3F2-FC8D873C3576@3x.png");
    background-size: 100% 83px;
  }
}
.sk-asset.sk-Works-D6FC610F-833B-43A6-B2E5-AEE02F022DA7 {
  left: 1055px;
  top: 29px;
  width: 52px;
  height: 18px;
}

.sk-asset.sk-Works-D6FC610F-833B-43A6-B2E5-AEE02F022DA7 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #826febff;
  font-size: 18px;

  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Works-D6FC610F-833B-43A6-B2E5-AEE02F022DA7 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #826feb;
  font-size: 18px;
}

.sk-asset.sk-Sides-20CCA4CD-6356-4701-A395-66BD8B3AA278 {
  left: 1167px;
  top: 31px;
  width: 38px;
  height: 16px;
}

.sk-asset.sk-Sides-20CCA4CD-6356-4701-A395-66BD8B3AA278 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #7e7e7eff;
  font-size: 16px;

  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Sides-20CCA4CD-6356-4701-A395-66BD8B3AA278 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #7e7e7e;
  font-size: 16px;
}

.sk-asset.sk-Resume-50037A6D-0DA2-4125-BA64-7932837ED755 {
  left: 1265px;
  top: 31px;
  width: 57px;
  height: 16px;
}

.sk-asset.sk-Resume-50037A6D-0DA2-4125-BA64-7932837ED755 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #7e7e7eff;
  font-size: 16px;

  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Resume-50037A6D-0DA2-4125-BA64-7932837ED755 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #7e7e7e;
  font-size: 16px;
}

.sk-asset.sk--S--256306A6-EEF2-463E-9379-B8012EDA1735 {
  left: 121px;
  top: 32px;
  width: 54px;
  height: 24px;
}

.sk-asset.sk--S--256306A6-EEF2-463E-9379-B8012EDA1735 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 24px;

  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk--S--256306A6-EEF2-463E-9379-B8012EDA1735 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 24px;
}

.sk-asset.sk-Group-5AD925DD-07BC-471A-9599-E527E193BA58 {
  left: 0px;
  top: 0px;
  width: 100%;
  height: 83px;
}

.sk-asset.sk-Targeted-Users-D83EE54D-4888-4B49-8469-979AF84F1303 {
  left: 200px;
  top: 0px;
  width: 234px;
  height: 33px;
}

.sk-asset.sk-Targeted-Users-D83EE54D-4888-4B49-8469-979AF84F1303 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 21px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Targeted-Users-D83EE54D-4888-4B49-8469-979AF84F1303 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 21px;
}

.sk-asset.sk-Product-Opportunity-29DD5E60-9FDA-4258-952D-ED5D201EEA08 {
  left: 553px;
  top: 0px;
  width: 460px;
  height: 33px;
}

.sk-asset.sk-Product-Opportunity-29DD5E60-9FDA-4258-952D-ED5D201EEA08 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 21px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Product-Opportunity-29DD5E60-9FDA-4258-952D-ED5D201EEA08 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 21px;
}

.sk-asset.sk-College-students-at-78354379-CB4B-4131-8A58-55A75BD93A87 {
  left: 200px;
  top: 38px;
  width: 274px;
  height: 83px;
}

.sk-asset.sk-College-students-at-78354379-CB4B-4131-8A58-55A75BD93A87 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #404040ff;
  font-size: 18px;
  line-height: 24px;
  opacity: 1;
  text-align: justified;
  font-kerning: 0;
}

.sk-asset.sk-College-students-at-78354379-CB4B-4131-8A58-55A75BD93A87 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #404040;
  font-size: 18px;
}

.sk-asset.sk-How-might-we-organiz-A91FF75E-993F-44F3-8196-72CE8F81DF67 {
  left: 553px;
  top: 38px;
  width: 487px;
  height: 81px;
}

.sk-asset.sk-How-might-we-organiz-A91FF75E-993F-44F3-8196-72CE8F81DF67 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #404040ff;
  font-size: 18px;
  line-height: 24px;
  opacity: 1;
  text-align: justified;
  font-kerning: 0;
}

.sk-asset.sk-How-might-we-organiz-A91FF75E-993F-44F3-8196-72CE8F81DF67 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #404040;
  font-size: 18px;
}

.sk-asset.sk-Rectangle-ABAE130B-7C3F-49A5-985E-DB5AE33CBC6F {
  background-image: url("/assets/works/RoomSmart/images/Rectangle-ABAE130B-7C3F-49A5-985E-DB5AE33CBC6F.png");
  left: 0px;
  top: 199px;
  width: 750px;
  height: 750px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Rectangle-ABAE130B-7C3F-49A5-985E-DB5AE33CBC6F {
    background-image: url("/assets/works/RoomSmart/images/Rectangle-ABAE130B-7C3F-49A5-985E-DB5AE33CBC6F@2x.png");
    background-size: 750px 750px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Rectangle-ABAE130B-7C3F-49A5-985E-DB5AE33CBC6F {
    background-image: url("/assets/works/RoomSmart/images/Rectangle-ABAE130B-7C3F-49A5-985E-DB5AE33CBC6F@3x.png");
    background-size: 750px 750px;
  }
}
.sk-asset.sk-Rectangle-Copy-49799CC2-D376-40D6-BD83-D8FD673034C7 {
  background-image: url("/assets/works/RoomSmart/images/Rectangle-Copy-49799CC2-D376-40D6-BD83-D8FD673034C7.png");
  left: 0px;
  top: 1013px;
  width: 750px;
  height: 750px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Rectangle-Copy-49799CC2-D376-40D6-BD83-D8FD673034C7 {
    background-image: url("/assets/works/RoomSmart/images/Rectangle-Copy-49799CC2-D376-40D6-BD83-D8FD673034C7@2x.png");
    background-size: 750px 750px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Rectangle-Copy-49799CC2-D376-40D6-BD83-D8FD673034C7 {
    background-image: url("/assets/works/RoomSmart/images/Rectangle-Copy-49799CC2-D376-40D6-BD83-D8FD673034C7@3x.png");
    background-size: 750px 750px;
  }
}
.sk-asset.sk-Iphone-7-White2x-42704B74-2945-4397-97A1-6E08A291631B {
  background-image: url("/assets/works/RoomSmart/images/Iphone-7-White2x-42704B74-2945-4397-97A1-6E08A291631B.png");
  left: 168px;
  top: 234px;
  width: 370px;
  height: 708px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Iphone-7-White2x-42704B74-2945-4397-97A1-6E08A291631B {
    background-image: url("/assets/works/RoomSmart/images/Iphone-7-White2x-42704B74-2945-4397-97A1-6E08A291631B@2x.png");
    background-size: contain;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Iphone-7-White2x-42704B74-2945-4397-97A1-6E08A291631B {
    background-image: url("/assets/works/RoomSmart/images/Iphone-7-White2x-42704B74-2945-4397-97A1-6E08A291631B@3x.png");
    background-size: contain;
  }
}
.sk-asset.sk-Iphone-7-White2x-Copy-100F1FC9-E1C3-498A-92C0-725B9CF7F24E {
  background-image: url("/assets/works/RoomSmart/images/Iphone-7-White2x-Copy-100F1FC9-E1C3-498A-92C0-725B9CF7F24E.png");
  left: 170px;
  top: 1048px;
  width: 369px;
  height: 708px;
  z-index: 1;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Iphone-7-White2x-Copy-100F1FC9-E1C3-498A-92C0-725B9CF7F24E {
    background-image: url("/assets/works/RoomSmart/images/Iphone-7-White2x-Copy-100F1FC9-E1C3-498A-92C0-725B9CF7F24E@2x.png");
    background-size: contain;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Iphone-7-White2x-Copy-100F1FC9-E1C3-498A-92C0-725B9CF7F24E {
    background-image: url("/assets/works/RoomSmart/images/Iphone-7-White2x-Copy-100F1FC9-E1C3-498A-92C0-725B9CF7F24E@3x.png");
    background-size: contain;
  }
}
.sk-asset.sk-Asset-1-Copy-6E74C98C-DDE7-4591-AD4B-3BB90EEDE079 {
  background-image: url("/assets/works/RoomSmart/images/Asset-1-Copy-6E74C98C-DDE7-4591-AD4B-3BB90EEDE079.png");
  left: 219px;
  top: 1146px;
  width: 270px;
  height: 480px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Asset-1-Copy-6E74C98C-DDE7-4591-AD4B-3BB90EEDE079 {
    background-image: url("/assets/works/RoomSmart/images/Asset-1-Copy-6E74C98C-DDE7-4591-AD4B-3BB90EEDE079@2x.png");
    background-size: 270px 480px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Asset-1-Copy-6E74C98C-DDE7-4591-AD4B-3BB90EEDE079 {
    background-image: url("/assets/works/RoomSmart/images/Asset-1-Copy-6E74C98C-DDE7-4591-AD4B-3BB90EEDE079@3x.png");
    background-size: 270px 480px;
  }
}
.sk-asset.sk-Integrated-Informati-7B77E2BC-7253-425A-98A3-B2FBC27EC12F {
  left: 853px;
  top: 296px;
  width: 387px;
  height: 52px;
}

.sk-asset.sk-Integrated-Informati-7B77E2BC-7253-425A-98A3-B2FBC27EC12F {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #f5a967ff;
  font-size: 18px;
  line-height: 36px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Integrated-Informati-7B77E2BC-7253-425A-98A3-B2FBC27EC12F .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #f5a967;
  font-size: 18px;
}

.sk-asset.sk-2Click-Room-Reserva-5773F4AA-A48F-4DD4-9031-0BC8FB68E984 {
  left: 853px;
  top: 1136px;
  width: 387px;
  height: 52px;
}

.sk-asset.sk-2Click-Room-Reserva-5773F4AA-A48F-4DD4-9031-0BC8FB68E984 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #f5a967ff;
  font-size: 18px;
  line-height: 36px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-2Click-Room-Reserva-5773F4AA-A48F-4DD4-9031-0BC8FB68E984 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #f5a967;
  font-size: 18px;
}

.sk-asset.sk-Solve-for-the-Pain-P-1D402FF9-0E5C-4725-83F3-5A87EB333A4C {
  left: 853px;
  top: 351px;
  width: 387px;
  height: 55px;
}

.sk-asset.sk-Solve-for-the-Pain-P-1D402FF9-0E5C-4725-83F3-5A87EB333A4C {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 30px;
  line-height: 36px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Solve-for-the-Pain-P-1D402FF9-0E5C-4725-83F3-5A87EB333A4C .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 30px;
}

.sk-asset.sk-Inclusiveness-Conv-15767FC5-71C6-4287-AD97-2EA44CB45941 {
  left: 853px;
  top: 539px;
  width: 405px;
  height: 55px;
}

.sk-asset.sk-Inclusiveness-Conv-15767FC5-71C6-4287-AD97-2EA44CB45941 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 30px;
  line-height: 36px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Inclusiveness-Conv-15767FC5-71C6-4287-AD97-2EA44CB45941 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 30px;
}

.sk-asset.sk-No-More-Learning-Cur-C67A6746-7AB0-4E42-8487-4BF58FAF15A0 {
  left: 853px;
  top: 1191px;
  width: 387px;
  height: 55px;
}

.sk-asset.sk-No-More-Learning-Cur-C67A6746-7AB0-4E42-8487-4BF58FAF15A0 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 30px;
  line-height: 36px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-No-More-Learning-Cur-C67A6746-7AB0-4E42-8487-4BF58FAF15A0 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 30px;
}

.sk-asset.sk-Scattered-informatio-2D75E4C6-4B37-499C-9DDB-34862BB9D5D5 {
  left: 853px;
  top: 403px;
  width: 374px;
  height: 92px;
}

.sk-asset.sk-Scattered-informatio-2D75E4C6-4B37-499C-9DDB-34862BB9D5D5 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #404040ff;
  font-size: 18px;
  line-height: 27px;
  opacity: 1;
  text-align: justified;
  font-kerning: 0;
}

.sk-asset.sk-Scattered-informatio-2D75E4C6-4B37-499C-9DDB-34862BB9D5D5 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #404040;
  font-size: 18px;
}

.sk-asset.sk-Facility-recources-t-6E5FA1BD-3F99-48F7-8BB7-0B9CF6374E58 {
  left: 853px;
  top: 591px;
  width: 374px;
  height: 295px;
}

.sk-asset.sk-Facility-recources-t-6E5FA1BD-3F99-48F7-8BB7-0B9CF6374E58 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #404040ff;
  font-size: 18px;
  line-height: 27px;
  opacity: 1;
  text-align: justified;
  font-kerning: 0;
}

.sk-asset.sk-Facility-recources-t-6E5FA1BD-3F99-48F7-8BB7-0B9CF6374E58 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #404040;
  font-size: 18px;
}

.sk-asset.sk-As-currently-studen-8568CE8E-413D-4FE6-8775-56690A7FABE9 {
  left: 853px;
  top: 1243px;
  width: 374px;
  height: 338px;
}

.sk-asset.sk-As-currently-studen-8568CE8E-413D-4FE6-8775-56690A7FABE9 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #404040ff;
  font-size: 18px;
  line-height: 27px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-As-currently-studen-8568CE8E-413D-4FE6-8775-56690A7FABE9 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #404040;
  font-size: 18px;
}

.sk-asset.sk-Asset-1-1109FB84-7BDC-4C61-BF6A-1634FFF6A76C {
  background-image: url("/assets/works/RoomSmart/images/Mock Part 1.gif");
  left: 219px;
  top: 334px;
  width: 270px;
  height: 480px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Asset-1-1109FB84-7BDC-4C61-BF6A-1634FFF6A76C {
    background-image: url("/assets/works/RoomSmart/images/Mock Part 1.gif");
    background-size: 270px 480px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Asset-1-1109FB84-7BDC-4C61-BF6A-1634FFF6A76C {
    background-image: url("/assets/works/RoomSmart/images/Mock Part 1.gif");
    background-size: 270px 480px;
  }
}
.sk-asset.sk-Artboard-82x-1389FFA5-C06D-43D1-8F2E-2A7297A8F6F2 {
  background-image: url("/assets/works/RoomSmart/images/Mock Part 2.gif");
  left: 220px;
  top: 1148px;
  width: 270px;
  height: 480px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Artboard-82x-1389FFA5-C06D-43D1-8F2E-2A7297A8F6F2 {
    background-image: url("/assets/works/RoomSmart/images/Mock Part 2.gif");
    background-size: 270px 480px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Artboard-82x-1389FFA5-C06D-43D1-8F2E-2A7297A8F6F2 {
    background-image: url("/assets/works/RoomSmart/images/Mock Part 2.gif");
    background-size: 270px 480px;
  }
}
.sk-asset.sk-Group-29-5F77C0C1-5226-4CCE-A3E7-2523492DE7C3 {
  left: 100px;
  top: 847px;
  width: 1258px;
  height: 1763px;
}

.sk-asset.sk-story-5-440F6FB1-4DF1-49DF-861A-08DD8E3D85F1 {
  background-image: url("/assets/works/RoomSmart/images/story-5-440F6FB1-4DF1-49DF-861A-08DD8E3D85F1.png");
  left: 203px;
  top: 6px;
  width: 195px;
  height: 169px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-story-5-440F6FB1-4DF1-49DF-861A-08DD8E3D85F1 {
    background-image: url("/assets/works/RoomSmart/images/story-5-440F6FB1-4DF1-49DF-861A-08DD8E3D85F1@2x.png");
    background-size: 195px 169px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-story-5-440F6FB1-4DF1-49DF-861A-08DD8E3D85F1 {
    background-image: url("/assets/works/RoomSmart/images/story-5-440F6FB1-4DF1-49DF-861A-08DD8E3D85F1@3x.png");
    background-size: 195px 169px;
  }
}
.sk-asset.sk-Bitmap-1D8EC6B8-C027-455A-A0E8-59247DB3DCE2 {
  background-image: url("/assets/works/RoomSmart/images/Bitmap-1D8EC6B8-C027-455A-A0E8-59247DB3DCE2.png");
  left: 0px;
  top: 0px;
  width: 233px;
  height: 102px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Bitmap-1D8EC6B8-C027-455A-A0E8-59247DB3DCE2 {
    background-image: url("/assets/works/RoomSmart/images/Bitmap-1D8EC6B8-C027-455A-A0E8-59247DB3DCE2@2x.png");
    background-size: 233px 102px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Bitmap-1D8EC6B8-C027-455A-A0E8-59247DB3DCE2 {
    background-image: url("/assets/works/RoomSmart/images/Bitmap-1D8EC6B8-C027-455A-A0E8-59247DB3DCE2@3x.png");
    background-size: 233px 102px;
  }
}
.sk-asset.sk-Group-8-C70E6544-086B-4EFB-BC8B-C55A795AAB89 {
  left: 554px;
  top: 227px;
  width: 398px;
  height: 175px;
}

.sk-asset.sk-story1-2A244603-12CF-4889-BFCE-F871F0ED3404 {
  background-image: url("/assets/works/RoomSmart/images/story1-2A244603-12CF-4889-BFCE-F871F0ED3404.png");
  left: 6px;
  top: 27px;
  width: 229px;
  height: 209px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-story1-2A244603-12CF-4889-BFCE-F871F0ED3404 {
    background-image: url("/assets/works/RoomSmart/images/story1-2A244603-12CF-4889-BFCE-F871F0ED3404@2x.png");
    background-size: 229px 209px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-story1-2A244603-12CF-4889-BFCE-F871F0ED3404 {
    background-image: url("/assets/works/RoomSmart/images/story1-2A244603-12CF-4889-BFCE-F871F0ED3404@3x.png");
    background-size: 229px 209px;
  }
}
.sk-asset.sk-Small-group-40E3FA2F-1E61-47CC-97AC-7A2C85AD5B38 {
  left: 75px;
  top: 0px;
  width: 74px;
  height: 18px;
}

.sk-asset.sk-Small-group-40E3FA2F-1E61-47CC-97AC-7A2C85AD5B38 {
  font-family: "LingWai SC";
  font-stretch: "undefined";
  font-weight: 500;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 18px;
  line-height: 18px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Small-group-40E3FA2F-1E61-47CC-97AC-7A2C85AD5B38 .span1 {
  font-family: "LingWai SC";
  font-weight: 500;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 18px;
}

.sk-asset.sk-Ok-E4E3C5F5-E382-4EA1-8739-2E7905EE50AB {
  left: 38px;
  top: 98px;
  width: 17px;
  height: 18px;
}

.sk-asset.sk-Ok-E4E3C5F5-E382-4EA1-8739-2E7905EE50AB {
  font-family: "LingWai SC";
  font-stretch: "undefined";
  font-weight: 500;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 18px;
  line-height: 18px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Ok-E4E3C5F5-E382-4EA1-8739-2E7905EE50AB .span1 {
  font-family: "LingWai SC";
  font-weight: 500;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 18px;
}

.sk-asset.sk-Need-a-room-for-meet-E9839E98-42B7-465C-9DE2-F94528DE8F43 {
  left: 112px;
  top: 84px;
  width: 120px;
  height: 36px;
}

.sk-asset.sk-Need-a-room-for-meet-E9839E98-42B7-465C-9DE2-F94528DE8F43 {
  font-family: "LingWai SC";
  font-stretch: "undefined";
  font-weight: 500;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 18px;
  line-height: 18px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Need-a-room-for-meet-E9839E98-42B7-465C-9DE2-F94528DE8F43 .span1 {
  font-family: "LingWai SC";
  font-weight: 500;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 18px;
}

.sk-asset.sk-Group-3-0CF5E135-DDF1-406A-8C6F-A790F91F7473 {
  left: 0px;
  top: 0px;
  width: 240px;
  height: 236px;
}

.sk-asset.sk-story3-9EE234F1-E97A-4036-AD68-FC381922EFD4 {
  background-image: url("/assets/works/RoomSmart/images/story3-9EE234F1-E97A-4036-AD68-FC381922EFD4.png");
  left: 0px;
  top: 0px;
  width: 263px;
  height: 201px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-story3-9EE234F1-E97A-4036-AD68-FC381922EFD4 {
    background-image: url("/assets/works/RoomSmart/images/story3-9EE234F1-E97A-4036-AD68-FC381922EFD4@2x.png");
    background-size: 263px 201px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-story3-9EE234F1-E97A-4036-AD68-FC381922EFD4 {
    background-image: url("/assets/works/RoomSmart/images/story3-9EE234F1-E97A-4036-AD68-FC381922EFD4@3x.png");
    background-size: 263px 201px;
  }
}
.sk-asset.sk-Ok-490B0F71-6809-4BB3-9BD7-ABA7CFBDF3EB {
  left: 216px;
  top: 45px;
  width: 17px;
  height: 18px;
}

.sk-asset.sk-Ok-490B0F71-6809-4BB3-9BD7-ABA7CFBDF3EB {
  font-family: "LingWai SC";
  font-stretch: "undefined";
  font-weight: 500;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 18px;
  line-height: 18px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Ok-490B0F71-6809-4BB3-9BD7-ABA7CFBDF3EB .span1 {
  font-family: "LingWai SC";
  font-weight: 500;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 18px;
}

.sk-asset.sk-UGLs-full-Lets-ju-034CE976-5716-4BA3-8D37-9C408C95A169 {
  left: 31px;
  top: 21px;
  width: 116px;
  height: 54px;
}

.sk-asset.sk-UGLs-full-Lets-ju-034CE976-5716-4BA3-8D37-9C408C95A169 {
  font-family: "LingWai SC";
  font-stretch: "undefined";
  font-weight: 500;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 18px;
  line-height: 18px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-UGLs-full-Lets-ju-034CE976-5716-4BA3-8D37-9C408C95A169 .span1 {
  font-family: "LingWai SC";
  font-weight: 500;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 18px;
}

.sk-asset.sk-Group-6-F68389AD-8DB2-4278-9756-FEA5654D6A67 {
  left: 423px;
  top: 4px;
  width: 263px;
  height: 200px;
}

.sk-asset.sk-story4-F2DE6878-0E15-46BA-9403-19E4782B0739 {
  background-image: url("/assets/works/RoomSmart/images/story4-F2DE6878-0E15-46BA-9403-19E4782B0739.png");
  left: 1000px;
  top: 22px;
  width: 240px;
  height: 277px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-story4-F2DE6878-0E15-46BA-9403-19E4782B0739 {
    background-image: url("/assets/works/RoomSmart/images/story4-F2DE6878-0E15-46BA-9403-19E4782B0739@2x.png");
    background-size: 240px 277px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-story4-F2DE6878-0E15-46BA-9403-19E4782B0739 {
    background-image: url("/assets/works/RoomSmart/images/story4-F2DE6878-0E15-46BA-9403-19E4782B0739@3x.png");
    background-size: 240px 277px;
  }
}
.sk-asset.sk-UGL-is-full-Emmw-F8C96A19-BFE0-471E-901F-054E1B6E7AD4 {
  left: 18px;
  top: 75px;
  width: 80px;
  height: 72px;
}

.sk-asset.sk-UGL-is-full-Emmw-F8C96A19-BFE0-471E-901F-054E1B6E7AD4 {
  font-family: "LingWai SC";
  font-stretch: "undefined";
  font-weight: 500;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 18px;
  line-height: 18px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-UGL-is-full-Emmw-F8C96A19-BFE0-471E-901F-054E1B6E7AD4 .span1 {
  font-family: "LingWai SC";
  font-weight: 500;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 18px;
}

.sk-asset.sk-story2-226762D7-FE21-4333-A4F5-65F945FF7986 {
  background-image: url("/assets/works/RoomSmart/images/story2-226762D7-FE21-4333-A4F5-65F945FF7986.png");
  left: 0px;
  top: 43px;
  width: 285px;
  height: 143px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-story2-226762D7-FE21-4333-A4F5-65F945FF7986 {
    background-image: url("/assets/works/RoomSmart/images/story2-226762D7-FE21-4333-A4F5-65F945FF7986@2x.png");
    background-size: 285px 143px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-story2-226762D7-FE21-4333-A4F5-65F945FF7986 {
    background-image: url("/assets/works/RoomSmart/images/story2-226762D7-FE21-4333-A4F5-65F945FF7986@3x.png");
    background-size: 285px 143px;
  }
}
.sk-asset.sk--14FE9B12-895C-40B9-8EA8-E2555CE120CA {
  left: 155px;
  top: 93px;
  width: 80px;
  height: 18px;
}

.sk-asset.sk--14FE9B12-895C-40B9-8EA8-E2555CE120CA {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #fb9b2aff;
  font-size: 18px;
  line-height: 18px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk--14FE9B12-895C-40B9-8EA8-E2555CE120CA .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #fb9b2a;
  font-size: 18px;
}

.sk-asset.sk--6BDE1F3C-8280-4585-8757-49D876F8A323 {
  left: 228px;
  top: 66px;
  width: 29px;
  height: 18px;
}

.sk-asset.sk--6BDE1F3C-8280-4585-8757-49D876F8A323 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #fb9b2aff;
  font-size: 18px;
  line-height: 18px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk--6BDE1F3C-8280-4585-8757-49D876F8A323 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #fb9b2a;
  font-size: 18px;
}

.sk-asset.sk--3F14D9CD-17A7-4859-8881-F89DC8EC8D8D {
  left: 206px;
  top: 4px;
  width: 29px;
  height: 18px;
}

.sk-asset.sk--3F14D9CD-17A7-4859-8881-F89DC8EC8D8D {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #fb9b2aff;
  font-size: 18px;
  line-height: 18px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk--3F14D9CD-17A7-4859-8881-F89DC8EC8D8D .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #fb9b2a;
  font-size: 18px;
}

.sk-asset.sk--3021CF1E-2893-4D75-BB8A-0FF662CED909 {
  left: 109px;
  top: 7px;
  width: 29px;
  height: 18px;
}

.sk-asset.sk--3021CF1E-2893-4D75-BB8A-0FF662CED909 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #fb9b2aff;
  font-size: 18px;
  line-height: 18px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk--3021CF1E-2893-4D75-BB8A-0FF662CED909 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #fb9b2a;
  font-size: 18px;
}

.sk-asset.sk-Group-5-A9BD9B8E-9202-42DE-BC74-B99EB73686E8 {
  left: 166px;
  top: 209px;
  width: 285px;
  height: 186px;
}

.sk-asset.sk-Meeting-in-noise-wit-241E8AE9-91DA-4B02-9A2C-5B19FD4F765C {
  left: 1000px;
  top: 0px;
  width: 240px;
  height: 33px;
}

.sk-asset.sk-Meeting-in-noise-wit-241E8AE9-91DA-4B02-9A2C-5B19FD4F765C {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #f5a967ff;
  font-size: 18px;
  line-height: 18px;
  opacity: 1;
  text-align: left;
  font-kerning: -1;
}

.sk-asset.sk-Meeting-in-noise-wit-241E8AE9-91DA-4B02-9A2C-5B19FD4F765C .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #f5a967;
  font-size: 18px;
}

.sk-asset.sk-Group-9-E8AFBAFC-8094-43EB-9111-6CAB3854BF98 {
  left: 100px;
  top: 3957px;
  width: 1240px;
  height: 402px;
}

.sk-asset.sk-STORYBOARD-50652A8C-9B7A-49AD-9262-0D12AA879948 {
  left: 300px;
  top: 3874px;
  width: 520px;
  height: 33px;
}

.sk-asset.sk-STORYBOARD-50652A8C-9B7A-49AD-9262-0D12AA879948 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #f5a967ff;
  font-size: 21px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-STORYBOARD-50652A8C-9B7A-49AD-9262-0D12AA879948 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #f5a967;
  font-size: 21px;
}

.sk-asset.sk-FUNCTIONALITY-RESEAR-DF51209F-20C6-4EAF-9522-E92BE891C30B {
  left: 5px;
  top: 0px;
  width: 671px;
  height: 33px;
}

.sk-asset.sk-FUNCTIONALITY-RESEAR-DF51209F-20C6-4EAF-9522-E92BE891C30B {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #f5a967ff;
  font-size: 21px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-FUNCTIONALITY-RESEAR-DF51209F-20C6-4EAF-9522-E92BE891C30B .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #f5a967;
  font-size: 21px;
}

.sk-asset.sk-sitemap-FBAA9608-7A7C-4806-A8CB-4185048630E7 {
  background-image: url("/assets/works/RoomSmart/images/sitemap-FBAA9608-7A7C-4806-A8CB-4185048630E7.png");
  left: 0px;
  top: 412px;
  width: 844px;
  height: 541px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-sitemap-FBAA9608-7A7C-4806-A8CB-4185048630E7 {
    background-image: url("/assets/works/RoomSmart/images/sitemap-FBAA9608-7A7C-4806-A8CB-4185048630E7@2x.png");
    background-size: 844px 541px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-sitemap-FBAA9608-7A7C-4806-A8CB-4185048630E7 {
    background-image: url("/assets/works/RoomSmart/images/sitemap-FBAA9608-7A7C-4806-A8CB-4185048630E7@3x.png");
    background-size: 844px 541px;
  }
}
.sk-asset.sk-CONTENT-REQUIREMENTS--B3D5F083-F63E-47E5-9765-AA816F2BF51A {
  left: 85px;
  top: 83px;
  width: 248px;
  height: 28px;
}

.sk-asset.sk-CONTENT-REQUIREMENTS--B3D5F083-F63E-47E5-9765-AA816F2BF51A {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #404040ff;
  font-size: 18px;

  opacity: 1;
  text-align: left;
  font-kerning: null;
}

.sk-asset.sk-CONTENT-REQUIREMENTS--B3D5F083-F63E-47E5-9765-AA816F2BF51A .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #404040;
  font-size: 18px;
}

.sk-asset.sk-from-school-research-result-4A8C2EE9-2F1E-4E1A-816D-7BEC6444CEB7 {
  left: 85px;
  top: 104px;
  width: 171px;
  height: 22px;
}

.sk-asset.sk-from-school-research-result-4A8C2EE9-2F1E-4E1A-816D-7BEC6444CEB7 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #404040ff;
  font-size: 14px;

  opacity: 1;
  text-align: left;
  font-kerning: null;
}

.sk-asset.sk-from-school-research-result-4A8C2EE9-2F1E-4E1A-816D-7BEC6444CEB7
  .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #404040;
  font-size: 14px;
}

.sk-asset.sk-1-Building-names-2-C7755819-D55E-4B73-B700-C3308E6AB9F4 {
  left: 85px;
  top: 130px;
  width: 314px;
  height: 232px;
}

.sk-asset.sk-1-Building-names-2-C7755819-D55E-4B73-B700-C3308E6AB9F4 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #404040ff;
  font-size: 14px;

  opacity: 1;
  text-align: left;
  font-kerning: null;
}

.sk-asset.sk-1-Building-names-2-C7755819-D55E-4B73-B700-C3308E6AB9F4 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #404040;
  font-size: 14px;
}

.sk-asset.sk-FUNCTIONALITY-REQUIREMENTS-13BC7955-ED57-432D-99F1-C6F42C04BA75 {
  left: 463px;
  top: 82px;
  width: 302px;
  height: 28px;
}

.sk-asset.sk-FUNCTIONALITY-REQUIREMENTS-13BC7955-ED57-432D-99F1-C6F42C04BA75 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #404040ff;
  font-size: 18px;

  opacity: 1;
  text-align: left;
  font-kerning: null;
}

.sk-asset.sk-FUNCTIONALITY-REQUIREMENTS-13BC7955-ED57-432D-99F1-C6F42C04BA75
  .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #404040;
  font-size: 18px;
}

.sk-asset.sk---DACF5EE0-5211-47DC-A7F0-18F95A8BFE3B {
  left: 661px;
  top: 87px;
  width: 5px;
  height: 22px;
}

.sk-asset.sk---DACF5EE0-5211-47DC-A7F0-18F95A8BFE3B {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #404040ff;
  font-size: 14px;

  opacity: 1;
  text-align: left;
  font-kerning: null;
}

.sk-asset.sk---DACF5EE0-5211-47DC-A7F0-18F95A8BFE3B .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #404040;
  font-size: 14px;
}

.sk-asset.sk-from-school-research-result-D200C055-C2C1-45F7-975F-C53E33EC1957 {
  left: 463px;
  top: 103px;
  width: 171px;
  height: 22px;
}

.sk-asset.sk-from-school-research-result-D200C055-C2C1-45F7-975F-C53E33EC1957 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #404040ff;
  font-size: 14px;

  opacity: 1;
  text-align: left;
  font-kerning: null;
}

.sk-asset.sk-from-school-research-result-D200C055-C2C1-45F7-975F-C53E33EC1957
  .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #404040;
  font-size: 14px;
}

.sk-asset.sk-1-Search-for-rooms-32652C5A-0342-4172-9AF1-ED565DA7AC00 {
  left: 463px;
  top: 137px;
  width: 283px;
  height: 190px;
}

.sk-asset.sk-1-Search-for-rooms-32652C5A-0342-4172-9AF1-ED565DA7AC00 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #404040ff;
  font-size: 14px;

  opacity: 1;
  text-align: left;
  font-kerning: null;
}

.sk-asset.sk-1-Search-for-rooms-32652C5A-0342-4172-9AF1-ED565DA7AC00 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #404040;
  font-size: 14px;
}

.sk-asset.sk-Group-10-E9D9D0DC-C3B8-4E8C-82B2-BAB6E1C76A82 {
  left: 295px;
  top: 4559px;
  width: 844px;
  height: 953px;
}

.sk-asset.sk-WIREFRAME-7D808D87-9ED8-458B-B273-64E9F15D4D5D {
  left: 300px;
  top: 5725px;
  width: 671px;
  height: 33px;
}

.sk-asset.sk-WIREFRAME-7D808D87-9ED8-458B-B273-64E9F15D4D5D {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #f5a967ff;
  font-size: 21px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-WIREFRAME-7D808D87-9ED8-458B-B273-64E9F15D4D5D .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #f5a967;
  font-size: 21px;
}

.sk-asset.sk-The-app-gives-inclus-6B9F4724-766A-4670-9ABB-4BD74495C73F {
  left: 295px;
  top: 5794px;
  width: 844px;
  height: 162px;
}

.sk-asset.sk-The-app-gives-inclus-6B9F4724-766A-4670-9ABB-4BD74495C73F {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #404040ff;
  font-size: 18px;

  opacity: 1;
  text-align: justified;
  font-kerning: null;
}

.sk-asset.sk-The-app-gives-inclus-6B9F4724-766A-4670-9ABB-4BD74495C73F .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #404040;
  font-size: 18px;
}

.sk-asset.sk-Group-39-4087725F-8F03-4DCD-8ABA-3920DCAEABEA {
  background-image: url("/assets/works/RoomSmart/images/Group-39-4087725F-8F03-4DCD-8ABA-3920DCAEABEA.png");
  left: 100px;
  top: 6016px;
  width: 1240px;
  height: 734px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Group-39-4087725F-8F03-4DCD-8ABA-3920DCAEABEA {
    background-image: url("/assets/works/RoomSmart/images/Group-39-4087725F-8F03-4DCD-8ABA-3920DCAEABEA@2x.png");
    background-size: 1240px 734px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Group-39-4087725F-8F03-4DCD-8ABA-3920DCAEABEA {
    background-image: url("/assets/works/RoomSmart/images/Group-39-4087725F-8F03-4DCD-8ABA-3920DCAEABEA@3x.png");
    background-size: 1240px 734px;
  }
}
.sk-asset.sk-PROTOTYPE-DESIGN-AD150A32-BCD7-473C-A2C7-F004B88443EB {
  left: 300px;
  top: 7016px;
  width: 671px;
  height: 33px;
}

.sk-asset.sk-PROTOTYPE-DESIGN-AD150A32-BCD7-473C-A2C7-F004B88443EB {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #f5a967ff;
  font-size: 21px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-PROTOTYPE-DESIGN-AD150A32-BCD7-473C-A2C7-F004B88443EB .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #f5a967;
  font-size: 21px;
}

.sk-asset.sk-Group-27-D40E2368-FC88-4B4E-BA59-4B246587F002 {
  background-image: url("/assets/works/RoomSmart/images/Group-27-D40E2368-FC88-4B4E-BA59-4B246587F002.png");
  left: 0px;
  top: 7094px;
  width: 971px;
  height: 391px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Group-27-D40E2368-FC88-4B4E-BA59-4B246587F002 {
    background-image: url("/assets/works/RoomSmart/images/Group-27-D40E2368-FC88-4B4E-BA59-4B246587F002@2x.png");
    background-size: 971px 391px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Group-27-D40E2368-FC88-4B4E-BA59-4B246587F002 {
    background-image: url("/assets/works/RoomSmart/images/Group-27-D40E2368-FC88-4B4E-BA59-4B246587F002@3x.png");
    background-size: 971px 391px;
  }
}
.sk-asset.sk-Group-33-E461AD76-E067-4596-88E8-D7CA2D4F8126 {
  background-image: url("/assets/works/RoomSmart/images/Group-33-E461AD76-E067-4596-88E8-D7CA2D4F8126.png");
  left: 0px;
  top: 7485px;
  width: 971px;
  height: 390px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Group-33-E461AD76-E067-4596-88E8-D7CA2D4F8126 {
    background-image: url("/assets/works/RoomSmart/images/Group-33-E461AD76-E067-4596-88E8-D7CA2D4F8126@2x.png");
    background-size: 971px 390px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Group-33-E461AD76-E067-4596-88E8-D7CA2D4F8126 {
    background-image: url("/assets/works/RoomSmart/images/Group-33-E461AD76-E067-4596-88E8-D7CA2D4F8126@3x.png");
    background-size: 971px 390px;
  }
}
.sk-asset.sk-Group-34-EAD3DF32-9078-44C6-AA8A-C29FB3D827BC {
  background-image: url("/assets/works/RoomSmart/images/Group-34-EAD3DF32-9078-44C6-AA8A-C29FB3D827BC.png");
  left: 0px;
  top: 7875px;
  width: 971px;
  height: 391px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Group-34-EAD3DF32-9078-44C6-AA8A-C29FB3D827BC {
    background-image: url("/assets/works/RoomSmart/images/Group-34-EAD3DF32-9078-44C6-AA8A-C29FB3D827BC@2x.png");
    background-size: 971px 391px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Group-34-EAD3DF32-9078-44C6-AA8A-C29FB3D827BC {
    background-image: url("/assets/works/RoomSmart/images/Group-34-EAD3DF32-9078-44C6-AA8A-C29FB3D827BC@3x.png");
    background-size: 971px 391px;
  }
}
.sk-asset.sk-UI-Design-0BBDD381-5E10-41E8-8201-6B17A13CDC31 {
  left: 1044px;
  top: 7139px;
  width: 322px;
  height: 55px;
}

.sk-asset.sk-UI-Design-0BBDD381-5E10-41E8-8201-6B17A13CDC31 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 30px;
  line-height: 36px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-UI-Design-0BBDD381-5E10-41E8-8201-6B17A13CDC31 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 30px;
}

.sk-asset.sk-Facility-Information-860CDFFE-EC5A-4F50-9F4A-0BA6061E7B93 {
  left: 1044px;
  top: 7542px;
  width: 296px;
  height: 100px;
}

.sk-asset.sk-Facility-Information-860CDFFE-EC5A-4F50-9F4A-0BA6061E7B93 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 30px;
  line-height: 42px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Facility-Information-860CDFFE-EC5A-4F50-9F4A-0BA6061E7B93 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 30px;
}

.sk-asset.sk-Reservation-Userflow-589F8B46-B749-4E17-9140-B51D8002F716 {
  left: 1044px;
  top: 7938px;
  width: 296px;
  height: 100px;
}

.sk-asset.sk-Reservation-Userflow-589F8B46-B749-4E17-9140-B51D8002F716 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 30px;
  line-height: 42px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Reservation-Userflow-589F8B46-B749-4E17-9140-B51D8002F716 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 30px;
}

.sk-asset.sk-Color-palatte-is-bas-00F06621-9090-461D-930D-3FA2A7CAB3A5 {
  left: 1044px;
  top: 7194px;
  width: 311px;
  height: 133px;
}

.sk-asset.sk-Color-palatte-is-bas-00F06621-9090-461D-930D-3FA2A7CAB3A5 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #404040ff;
  font-size: 18px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Color-palatte-is-bas-00F06621-9090-461D-930D-3FA2A7CAB3A5 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #404040;
  font-size: 18px;
}

.sk-asset.sk-This-app-allows-stud-9D769B3C-3E60-4235-8408-4933B57622B4 {
  left: 1044px;
  top: 7639px;
  width: 311px;
  height: 146px;
}

.sk-asset.sk-This-app-allows-stud-9D769B3C-3E60-4235-8408-4933B57622B4 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #404040ff;
  font-size: 18px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-This-app-allows-stud-9D769B3C-3E60-4235-8408-4933B57622B4 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #404040;
  font-size: 18px;
}

.sk-asset.sk-Students-can-find-de-13BADF52-19EA-4ACB-9B54-09D2D16A6E8E {
  left: 1044px;
  top: 8006px;
  width: 296px;
  height: 168px;
}

.sk-asset.sk-Students-can-find-de-13BADF52-19EA-4ACB-9B54-09D2D16A6E8E {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #404040ff;
  font-size: 18px;
  line-height: 24px;
  opacity: 1;
  text-align: justified;
  font-kerning: 0;
}

.sk-asset.sk-Students-can-find-de-13BADF52-19EA-4ACB-9B54-09D2D16A6E8E .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #404040;
  font-size: 18px;
}

.sk-asset.sk-CURRENT-PROBLEM-ANAL-6AB509B2-74CC-41E5-8658-B7AA54EBDFC4 {
  left: 0px;
  top: 314px;
  width: 520px;
  height: 33px;
}

.sk-asset.sk-CURRENT-PROBLEM-ANAL-6AB509B2-74CC-41E5-8658-B7AA54EBDFC4 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #f5a967ff;
  font-size: 21px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-CURRENT-PROBLEM-ANAL-6AB509B2-74CC-41E5-8658-B7AA54EBDFC4 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #f5a967;
  font-size: 21px;
}

.sk-asset.sk-How-was-I-inspired-B7AD66D5-B598-44ED-9C21-ACB06F88A861 {
  left: 0px;
  top: 0px;
  width: 520px;
  height: 33px;
}

.sk-asset.sk-How-was-I-inspired-B7AD66D5-B598-44ED-9C21-ACB06F88A861 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #f5a967ff;
  font-size: 21px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-How-was-I-inspired-B7AD66D5-B598-44ED-9C21-ACB06F88A861 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #f5a967;
  font-size: 21px;
}

.sk-asset.sk-A-different-room-requesting-form-A6164D1A-CDC4-46C3-ACA4-3B6FB7FE0B64 {
  background-image: url("/assets/works/RoomSmart/images/A-different-room-requesting-form-A6164D1A-CDC4-46C3-ACA4-3B6FB7FE0B64.png");
  left: 609px;
  top: 962px;
  width: 209px;
  height: 16px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-A-different-room-requesting-form-A6164D1A-CDC4-46C3-ACA4-3B6FB7FE0B64 {
    background-image: url("/assets/works/RoomSmart/images/A-different-room-requesting-form-A6164D1A-CDC4-46C3-ACA4-3B6FB7FE0B64@2x.png");
    background-size: 209px 16px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-A-different-room-requesting-form-A6164D1A-CDC4-46C3-ACA4-3B6FB7FE0B64 {
    background-image: url("/assets/works/RoomSmart/images/A-different-room-requesting-form-A6164D1A-CDC4-46C3-ACA4-3B6FB7FE0B64@3x.png");
    background-size: 209px 16px;
  }
}
.sk-asset.sk-A-public-space-reser-6FEDF2A1-D6F3-45CC-890C-100A269D47F1 {
  background-image: url("/assets/works/RoomSmart/images/A-public-space-reser-6FEDF2A1-D6F3-45CC-890C-100A269D47F1.png");
  left: 614px;
  top: 738px;
  width: 200px;
  height: 16px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-A-public-space-reser-6FEDF2A1-D6F3-45CC-890C-100A269D47F1 {
    background-image: url("/assets/works/RoomSmart/images/A-public-space-reser-6FEDF2A1-D6F3-45CC-890C-100A269D47F1@2x.png");
    background-size: 200px 16px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-A-public-space-reser-6FEDF2A1-D6F3-45CC-890C-100A269D47F1 {
    background-image: url("/assets/works/RoomSmart/images/A-public-space-reser-6FEDF2A1-D6F3-45CC-890C-100A269D47F1@3x.png");
    background-size: 200px 16px;
  }
}
.sk-asset.sk-The-main-form-that-c-5A9485C4-65E0-4AAA-A378-727FB769F4B1 {
  background-image: url("/assets/works/RoomSmart/images/The-main-form-that-c-5A9485C4-65E0-4AAA-A378-727FB769F4B1.png");
  left: 587px;
  top: 526px;
  width: 256px;
  height: 30px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-The-main-form-that-c-5A9485C4-65E0-4AAA-A378-727FB769F4B1 {
    background-image: url("/assets/works/RoomSmart/images/The-main-form-that-c-5A9485C4-65E0-4AAA-A378-727FB769F4B1@2x.png");
    background-size: 256px 30px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-The-main-form-that-c-5A9485C4-65E0-4AAA-A378-727FB769F4B1 {
    background-image: url("/assets/works/RoomSmart/images/The-main-form-that-c-5A9485C4-65E0-4AAA-A378-727FB769F4B1@3x.png");
    background-size: 256px 30px;
  }
}
.sk-asset.sk-Each-schoolbuilding-55EC8341-BA4F-4728-AF5C-B382067696AB {
  left: 41px;
  top: 387px;
  width: 484px;
  height: 560px;
}

.sk-asset.sk-Each-schoolbuilding-55EC8341-BA4F-4728-AF5C-B382067696AB {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #404040ff;
  font-size: 18px;

  opacity: 1;
  text-align: justified;
  font-kerning: null;
}

.sk-asset.sk-Each-schoolbuilding-55EC8341-BA4F-4728-AF5C-B382067696AB .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #404040;
  font-size: 18px;
}

.sk-asset.sk-Rec-Bullet-CF073E20-F5E5-4FA9-B268-2E77AED3B7EC {
  background-image: url("/assets/works/RoomSmart/images/Rec-Bullet-CF073E20-F5E5-4FA9-B268-2E77AED3B7EC.png");
  left: 10px;
  top: 392px;
  width: 11px;
  height: 11px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Rec-Bullet-CF073E20-F5E5-4FA9-B268-2E77AED3B7EC {
    background-image: url("/assets/works/RoomSmart/images/Rec-Bullet-CF073E20-F5E5-4FA9-B268-2E77AED3B7EC@2x.png");
    background-size: 11px 11px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Rec-Bullet-CF073E20-F5E5-4FA9-B268-2E77AED3B7EC {
    background-image: url("/assets/works/RoomSmart/images/Rec-Bullet-CF073E20-F5E5-4FA9-B268-2E77AED3B7EC@3x.png");
    background-size: 11px 11px;
  }
}
.sk-asset.sk-Rec-Bullet-Copy-6C6EF3D3-D21F-40B1-BA7F-61EA298F4A8E {
  background-image: url("/assets/works/RoomSmart/images/Rec-Bullet-Copy-6C6EF3D3-D21F-40B1-BA7F-61EA298F4A8E.png");
  left: 10px;
  top: 477px;
  width: 11px;
  height: 11px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Rec-Bullet-Copy-6C6EF3D3-D21F-40B1-BA7F-61EA298F4A8E {
    background-image: url("/assets/works/RoomSmart/images/Rec-Bullet-Copy-6C6EF3D3-D21F-40B1-BA7F-61EA298F4A8E@2x.png");
    background-size: 11px 11px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Rec-Bullet-Copy-6C6EF3D3-D21F-40B1-BA7F-61EA298F4A8E {
    background-image: url("/assets/works/RoomSmart/images/Rec-Bullet-Copy-6C6EF3D3-D21F-40B1-BA7F-61EA298F4A8E@3x.png");
    background-size: 11px 11px;
  }
}
.sk-asset.sk-Rec-Bullet-Copy-2-B1882271-D5A7-43E5-A294-82FED4DFE6B8 {
  background-image: url("/assets/works/RoomSmart/images/Rec-Bullet-Copy-2-B1882271-D5A7-43E5-A294-82FED4DFE6B8.png");
  left: 10px;
  top: 643px;
  width: 11px;
  height: 11px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Rec-Bullet-Copy-2-B1882271-D5A7-43E5-A294-82FED4DFE6B8 {
    background-image: url("/assets/works/RoomSmart/images/Rec-Bullet-Copy-2-B1882271-D5A7-43E5-A294-82FED4DFE6B8@2x.png");
    background-size: 11px 11px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Rec-Bullet-Copy-2-B1882271-D5A7-43E5-A294-82FED4DFE6B8 {
    background-image: url("/assets/works/RoomSmart/images/Rec-Bullet-Copy-2-B1882271-D5A7-43E5-A294-82FED4DFE6B8@3x.png");
    background-size: 11px 11px;
  }
}
.sk-asset.sk-Rec-Bullet-Copy-3-9ABA2DED-C978-4B74-B1F0-9021F171DB0F {
  background-image: url("/assets/works/RoomSmart/images/Rec-Bullet-Copy-3-9ABA2DED-C978-4B74-B1F0-9021F171DB0F.png");
  left: 10px;
  top: 862px;
  width: 11px;
  height: 11px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Rec-Bullet-Copy-3-9ABA2DED-C978-4B74-B1F0-9021F171DB0F {
    background-image: url("/assets/works/RoomSmart/images/Rec-Bullet-Copy-3-9ABA2DED-C978-4B74-B1F0-9021F171DB0F@2x.png");
    background-size: 11px 11px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Rec-Bullet-Copy-3-9ABA2DED-C978-4B74-B1F0-9021F171DB0F {
    background-image: url("/assets/works/RoomSmart/images/Rec-Bullet-Copy-3-9ABA2DED-C978-4B74-B1F0-9021F171DB0F@3x.png");
    background-size: 11px 11px;
  }
}
.sk-asset.sk-Image-1-42829387-B4A2-4CE2-BFD1-7A42F6D9F354 {
  background-image: url("/assets/works/RoomSmart/images/Image-1-42829387-B4A2-4CE2-BFD1-7A42F6D9F354.png");
  left: 582px;
  top: 371px;
  width: 264px;
  height: 148px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Image-1-42829387-B4A2-4CE2-BFD1-7A42F6D9F354 {
    background-image: url("/assets/works/RoomSmart/images/Image-1-42829387-B4A2-4CE2-BFD1-7A42F6D9F354@2x.png");
    background-size: 264px 148px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Image-1-42829387-B4A2-4CE2-BFD1-7A42F6D9F354 {
    background-image: url("/assets/works/RoomSmart/images/Image-1-42829387-B4A2-4CE2-BFD1-7A42F6D9F354@3x.png");
    background-size: 264px 148px;
  }
}
.sk-asset.sk-Image-3-E410DC35-FDF0-44D5-9AAF-B88EF09BD42B {
  background-image: url("/assets/works/RoomSmart/images/Image-3-E410DC35-FDF0-44D5-9AAF-B88EF09BD42B.png");
  left: 581px;
  top: 573px;
  width: 265px;
  height: 159px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Image-3-E410DC35-FDF0-44D5-9AAF-B88EF09BD42B {
    background-image: url("/assets/works/RoomSmart/images/Image-3-E410DC35-FDF0-44D5-9AAF-B88EF09BD42B@2x.png");
    background-size: 265px 159px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Image-3-E410DC35-FDF0-44D5-9AAF-B88EF09BD42B {
    background-image: url("/assets/works/RoomSmart/images/Image-3-E410DC35-FDF0-44D5-9AAF-B88EF09BD42B@3x.png");
    background-size: 265px 159px;
  }
}
.sk-asset.sk-Image-5-2D4368B8-285E-4AF6-8FE7-472DCC948D2A {
  background-image: url("/assets/works/RoomSmart/images/Image-5-2D4368B8-285E-4AF6-8FE7-472DCC948D2A.png");
  left: 581px;
  top: 764px;
  width: 265px;
  height: 190px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Image-5-2D4368B8-285E-4AF6-8FE7-472DCC948D2A {
    background-image: url("/assets/works/RoomSmart/images/Image-5-2D4368B8-285E-4AF6-8FE7-472DCC948D2A@2x.png");
    background-size: 265px 190px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Image-5-2D4368B8-285E-4AF6-8FE7-472DCC948D2A {
    background-image: url("/assets/works/RoomSmart/images/Image-5-2D4368B8-285E-4AF6-8FE7-472DCC948D2A@3x.png");
    background-size: 265px 190px;
  }
}
.sk-asset.sk-The-student-honor-bu-B2EBA3A0-77E4-460C-81E8-354C5EFF82C9 {
  left: 0px;
  top: 46px;
  width: 846px;
  height: 135px;
}

.sk-asset.sk-The-student-honor-bu-B2EBA3A0-77E4-460C-81E8-354C5EFF82C9 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #404040ff;
  font-size: 18px;

  opacity: 1;
  text-align: justified;
  font-kerning: null;
}

.sk-asset.sk-The-student-honor-bu-B2EBA3A0-77E4-460C-81E8-354C5EFF82C9 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #404040;
  font-size: 18px;
}

.sk-asset.sk-Group-11-B6F178D9-F107-459C-A228-E39C7FC29AAC {
  left: 295px;
  top: 2736px;
  width: 846px;
  height: 981px;
}

.sk-asset.sk-Int-Rendering-1-FF4A8DC7-6C18-4F3E-84DB-EE89E2205B5A {
  background-image: url("/assets/works/RoomSmart/images/Int-Rendering-1-FF4A8DC7-6C18-4F3E-84DB-EE89E2205B5A.png");
  left: 462px;
  top: 8627px;
  width: 516px;
  height: 385px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Int-Rendering-1-FF4A8DC7-6C18-4F3E-84DB-EE89E2205B5A {
    background-image: url("/assets/works/RoomSmart/images/Int-Rendering-1-FF4A8DC7-6C18-4F3E-84DB-EE89E2205B5A@2x.png");
    background-size: 516px 385px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Int-Rendering-1-FF4A8DC7-6C18-4F3E-84DB-EE89E2205B5A {
    background-image: url("/assets/works/RoomSmart/images/Int-Rendering-1-FF4A8DC7-6C18-4F3E-84DB-EE89E2205B5A@3x.png");
    background-size: 516px 385px;
  }
}
.sk-asset.sk-Architecture-Design-B33B3391-83A9-47B7-8DBB-D645A94976DD {
  left: 271px;
  top: 8562px;
  width: 899px;
  height: 45px;
}

.sk-asset.sk-Architecture-Design-B33B3391-83A9-47B7-8DBB-D645A94976DD {
  font-family: "PT Sans";
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #8499ccff;
  font-size: 42px;
  line-height: 24px;
  opacity: 1;
  text-align: center;
  font-kerning: 0;
}

.sk-asset.sk-Architecture-Design-B33B3391-83A9-47B7-8DBB-D645A94976DD .span1 {
  font-family: "PT Sans";
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #8499cc;
  font-size: 42px;
}

.sk-asset.sk-374-Project-1int-2-6B8D0C91-DAFA-4D53-91A8-3109CA7CD503 {
  background-image: url("/assets/works/RoomSmart/images/374-Project-1int-2-6B8D0C91-DAFA-4D53-91A8-3109CA7CD503.png");
  left: 2236px;
  top: 8974px;
  width: 1639px;
  height: 1229px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-374-Project-1int-2-6B8D0C91-DAFA-4D53-91A8-3109CA7CD503 {
    background-image: url("/assets/works/RoomSmart/images/374-Project-1int-2-6B8D0C91-DAFA-4D53-91A8-3109CA7CD503@2x.png");
    background-size: 1639px 1229px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-374-Project-1int-2-6B8D0C91-DAFA-4D53-91A8-3109CA7CD503 {
    background-image: url("/assets/works/RoomSmart/images/374-Project-1int-2-6B8D0C91-DAFA-4D53-91A8-3109CA7CD503@3x.png");
    background-size: 1639px 1229px;
  }
}
</style>
