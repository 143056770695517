<template>
  <div class="page">
    <div class="head">
      <div class="subtitle">Know What Your Pet’s Up To and No More Worries</div>
      <div class="title">
        <span class="title--orange">FurGO</span> PET TRACKER | MONITOR | APP
      </div>
      <div class="tip">App Interaction Design | IoT Design | User Research</div>
    </div>
    <div class="item-block-1">
      <img class="item-1" src="/assets/works/pet/1.png" />
    </div>
    <div class="item-block" id="1">
      <div class="item-title">BACKGROUND INFO</div>
      <div class="r-s-c mt-90">
        <div class="c-s-s">
          <div class="h1 w-464">
            10 million dogs and cats are lost or stolen in the U.S. every year.
          </div>
          <div class="h2 w-342 mt-18">
            One in three pets will become lost at some point during their
            life.Research Study: 11-16% of dogs and 12-18% of cats are likely to
            go missing at least once every 5 year.
            <br /><br />--American Humane Association
          </div>
        </div>
        <img class="item-2" src="/assets/works/pet/2.png" />
      </div>
    </div>
    <div class="item-block-2">
      <img class="item-3" src="/assets/works/pet/3.png" />
      <div class="item-block-2-text">
        I wanted to develop technology to help pet owners trace and find their
        pets when they are alone outside.
      </div>
    </div>

    <div class="item-block" id="2">
      <div class="item-title">HOW IT WORKS</div>
      <div class="r-b-c w-full mt-46">
        <div class="c-s-s mt-10-">
          <div class="h1 w-357">Bluetooth Tracking</div>
          <div class="h2 w-357 mt-25">
            Bluetooth tracker is securely attached to the pet's collar and can
            be paired + tracked on their phone app. Tracker was designed to be
            retangular shape with curved-edged all around to bring
            comfortability to pets.
          </div>
        </div>
        <img class="item-4" src="/assets/works/pet/4.png" />
      </div>

      <div class="r-b-c w-full mt-95">
        <img class="item-4-2" src="/assets/works/pet/4_2.gif" />
        <div class="c-s-s">
          <div class="h1 w-464">Simple Onboarding</div>
          <div class="h2 w-464 mt-25">
            A very simple and delightful onboarding experience for first-time
            users. It takes on a minimal design to present only the needed
            information at each step to avoid confusion. Easy device setting up
            with flexbility to skip steps to speed up the experience gives users
            freedom of control.
          </div>
        </div>
      </div>
    </div>

    <div class="w-full flex-center bg-blue-10">
      <img class="item-5" src="/assets/works/pet/5.png" />
    </div>

    <div class="r-b-s w-full item-block-3">
      <img class="item-6-2 mt-55" src="/assets/works/pet/6_2.gif" />
      <img class="item-6" src="/assets/works/pet/6.png" />
      <div class="c-s-s self-center">
        <div class="h1 w-357">Customize Safe Zone & Get Alerts</div>
        <div class="h2 w-336 mt-46">
          Set your own safe zone around your house or around the park by chosing
          radius or customizing the shape on drag. <br /><br />When your pets
          are outside by themselves or play in your backyard, turn on safe zone
          and you’ll get alert once they’re detect to be out of the safe zone.
        </div>
      </div>
    </div>

    <div class="w-full r-c-c bg-blue-5 item-block-4">
      <div class="c-s-s w-288">
        <div class="h1">Know Your Pets Health</div>
        <div class="h2 mt-38">
          Check the trip statistics of past trips of your pets and keep a record
          of their exercise health, with details of active/inactive time and
          exercise intensity.<br /><br />Ex. Fat cat losing weight!
        </div>
      </div>

      <img class="item-7 ml-78" src="/assets/works/pet/7.png" />
    </div>

    <div class="c-s-s w-full item-block-5" id="3">
      <div class="item-title">USER RESEARCH</div>
      <div class="r-c-c text-bottom self-center no-p mt-46">
        <p>
          <span class="h-b">20</span>
          <span class="h-s ml-17">Survey</span>
        </p>
        <p class="ml-92">
          <span class="h-b">5</span>
          <span class="h-s ml-17">Interviews</span>
        </p>
      </div>

      <div class="h1 mt-50">Cases of Needs</div>

      <div class="r-b-s w-full mt-60 text-center">
        <div class="c-s-c w-300">
          <div class="item-8">
            <img class="item-8-1" src="/assets/works/pet/8-1.png" />
          </div>

          <div class="h3 mt-28">Pets missing from sight</div>
          <div class="h4 mt-8">
            Pet owners walk pets leashed and unleashed or let play on their own.
          </div>
        </div>
        <div class="c-s-c w-300">
          <div class="item-8">
            <img class="item-8-2" src="/assets/works/pet/8-2.png" />
          </div>
          <div class="h3 mt-28">Interact while away</div>
          <div class="h4 mt-8">
            Pet owners are concerned and miss their pets while they’re gone on
            trips, and would like to monitor and play with pets.
          </div>
        </div>
        <div class="c-s-c w-300">
          <div class="item-8">
            <img class="item-8-3" src="/assets/works/pet/8-3.png" />
          </div>
          <div class="h3 mt-28">Outdoor pets / alone activities</div>
          <div class="h4 mt-8">
            Pets who would be let out on themselves or some cats kept in the
            outdoor. They can esaily get lost.
          </div>
        </div>
      </div>
      <img class="item-9 self-center" src="/assets/works/pet/9.png" />
    </div>

    <div class="c-s-s w-full item-block-6" id="4">
      <div class="item-title">PERSONA + STORYBOARDING</div>
      <img class="item-10" src="/assets/works/pet/10.png" />
      <img class="item-11" src="/assets/works/pet/11.png" />
      <img class="item-12" src="/assets/works/pet/12.png" />
      <img class="item-13" src="/assets/works/pet/13.png" />
    </div>

    <div class="c-s-s w-full item-block-6">
      <div class="r-b-s w-full">
        <div class="h1 w-185">Insights Informed Design Guidelines</div>
        <div class="c-c-c">
          <div class="r-b-s">
            <div class="c-s-s w-275">
              <img class="item-14-1" src="/assets/works/pet/14-1.png" />
              <div class="h5 mt-21">Multiple Resorts</div>
              <div class="h4 mt-6">
                Develop multiple functions complimentary to each other for
                different scenarios to help users find their pets
              </div>
            </div>
            <div class="c-s-s w-275 ml-92">
              <img class="item-14-2" src="/assets/works/pet/14-2.png" />
              <div class="h5 mt-21">Make it Fun!</div>
              <div class="h4 mt-6">
                Features and UI visuals need to both assist with fun
                interactions between users and pets.
              </div>
            </div>
          </div>
          <div class="r-b-s mt-50">
            <div class="c-s-s w-275">
              <img class="item-14-3" src="/assets/works/pet/14-3.png" />
              <div class="h5 mt-21">Informative</div>
              <div class="h4 mt-6">
                Provide enough information about pets to bring peace of mind and
                a better sense of control to users
              </div>
            </div>
            <div class="c-s-s w-275 ml-92">
              <img class="item-14-4" src="/assets/works/pet/14-4.png" />
              <div class="h5 mt-21">Ease of Use</div>
              <div class="h4 mt-6">
                Tracking function in particular needs to be very easy to
                understand and employ to enable efficient use in emergency
                cases.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="c-s-s w-full item-block-7" id="5">
      <div class="item-title ml-100">IDEATION</div>
      <div class="h1 mt-100 ml-100">Functionality Map</div>
      <img class="item-15 self-center" src="/assets/works/pet/15.png" />

      <div class="h1 ml-100">Wireframes + Heuristic Evaluation</div>
      <img class="item-16 self-center" src="/assets/works/pet/16.png" />
      <img class="item-17 self-center" src="/assets/works/pet/17.png" />
    </div>

    <div class="c-s-s w-full item-block-6" id="6">
      <div class="item-title">Hi-fidelity Mock-ups</div>
      <img class="item-18 mt-60 self-center" src="/assets/works/pet/18.png" />
    </div>

    <img class="item-19 self-center" src="/assets/works/pet/19.png" />
    <Turnpage :next="next"></Turnpage>
    <Quicklinks :list="quicklinks"></Quicklinks>
  </div>
</template>

<script>
import Bus from "@/bus";
import Turnpage from "@/components/turnpage.vue";
import Quicklinks from "@/components/quicklinks.vue";

export default {
  name: "MovingBoxes",
  components: {
    Turnpage,
    Quicklinks,
  },
  data: () => {
    return {
      previous: {
        title: "Desktop Software Product Design",
        name: "SmartConnect",
      },
      next: {
        title: "Studio Installation Design",
        name: "MovingBoxes",
      },
      handler: null,
      quicklinks: [
        {
          title: "Background",
          id: "1",
        },
        {
          title: "How It Works",
          id: "2",
        },
        {
          title: "User Research",
          id: "3",
        },
        {
          title: "Persona + Storyboarding",
          id: "4",
        },
        {
          title: "Ideation",
          id: "5",
        },
        {
          title: "Hi-Fi",
          id: "6",
        },
      ],
    };
  },
  beforeCreate() {
    Bus.$emit("navstyle", "background-color: rgba(251, 251, 251, 0.7);");
    Bus.$emit("bodystyle", "background-color: #fbfbfb;");
  },
  beforeDestroy() {
    Bus.$emit("navstyle", "");
    Bus.$emit("bodystyle", "");
  },
};
</script>

<style lang="scss" scoped>
.no-p {
  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }
}

.ml-78 {
  margin-left: 78px;
}

.ml-17 {
  margin-left: 17px;
}

.ml-24 {
  margin-left: 24px;
}

.ml-92 {
  margin-left: 92px;
}

.ml-100 {
  margin-left: 100px;
}

.mt-10- {
  margin-top: -80px;
}

.mt-6 {
  margin-top: 6px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-18 {
  margin-top: 18px;
}

.mt-21 {
  margin-top: 21px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-28 {
  margin-top: 28px;
}

.mt-38 {
  margin-top: 38px;
}

.mt-46 {
  margin-top: 46px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.bg-blue-5 {
  background-color: rgba(231, 242, 239, 0.5);
}

.bg-blue-10 {
  background-color: #e7f2ef;
}

.self-center {
  align-self: center;
}

.text-center {
  text-align: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.r-s-c {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  align-items: center;
}

.r-c-e {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: flex-end;
}

.r-b-s {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.r-b-c {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.r-c-c {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.c-c-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.c-s-s {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-items: flex-start;
}

.c-s-c {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-items: center;
}

.w-full {
  width: 100%;
}

.w-185 {
  width: 185px;
}

.w-275 {
  width: 288px;
}

.w-288 {
  width: 288px;
}

.w-300 {
  width: 300px;
}

.w-342 {
  width: 342px;
}

.w-357 {
  width: 357px;
}

.w-336 {
  width: 336px;
}

.w-464 {
  width: 464px;
}

.h1 {
  font-family: Fredoka One;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 130%;
  color: #454262;
}

.h2 {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: rgba(69, 66, 98, 0.7);
}

.h3 {
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  color: #454262;
}

.h4 {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: rgba(69, 66, 98, 0.7);
}

.h5 {
  font-family: Fredoka One;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 130%;
  color: #454262;
}

.text-bottom {
  vertical-align: bottom;
}

.h-b {
  font-family: Nunito;
  font-style: normal;
  font-weight: 900;
  font-size: 60px;
  line-height: 82px;
  color: #454262;
}

.h-s {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  color: #454262;
}

.page {
  text-align: left;
  background-color: #fbfbfb;
  padding: 0 0 100px 0;
}

.head {
  position: relative;
  width: 100%;
  height: auto;
  padding: 80px 0 52px 0;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-items: center;

  .subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -0.02em;
    color: rgba($color: #454262, $alpha: 0.6);
  }

  .title {
    font-family: Fredoka One;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #454262;

    &--orange {
      color: #f1896d;
    }
  }

  .tip {
    margin: 117px 0 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: rgba($color: #454262, $alpha: 0.6);
  }
}

.item- {
  &block-1 {
    width: 100%;
    height: auto;
    padding: 145px 0 103px 0;
    background: linear-gradient(
      270deg,
      rgba(255, 201, 187, 0.6) 0%,
      rgba(208, 241, 231, 0.6) 100%
    );
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &1 {
    width: 952px;
    height: auto;
  }

  &block {
    width: 100%;
    box-sizing: border-box;
    padding: 200px 254px;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: center;
  }

  &title {
    padding: 5px 10px;
    align-self: flex-start;
    font-family: Fredoka One;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 130%;
    color: #f48a6e;
    background: linear-gradient(180deg, #e1f5ef 0%, #fdddd5 100%);
  }

  // &row {
  //   display: flex;
  //   flex-direction: row;
  //   justify-items: center;
  //   align-items: center;
  // }

  &2 {
    width: 464px;
    height: auto;
  }

  &block-2 {
    position: relative;
    width: 100%;
    height: auto;

    &-text {
      position: absolute;
      top: 151px;
      left: 244px;
      width: 550px;
      font-family: Fredoka One;
      font-style: normal;
      font-weight: normal;
      font-size: 36px;
      line-height: 150%;
      color: #ffffff;
    }
  }

  &3 {
    width: 100%;
    height: auto;
  }

  // &row-l {
  //   display: flex;
  //   flex-direction: column;
  //   justify-items: center;
  //   align-items: flex-start;

  //   &-title {
  //     font-family: Roboto;
  //     font-style: normal;
  //     font-weight: bold;
  //     font-size: 36px;
  //     line-height: 42px;
  //     color: #454262;
  //   }

  //   &-desc {
  //     font-family: Nunito Sans;
  //     margin: 45px 0 0 0;
  //     font-style: normal;
  //     font-weight: normal;
  //     font-size: 15px;
  //     line-height: 22px;
  //     color: #454262;
  //   }

  //   &-tip {
  //     margin: 40px 0 0 0;
  //     font-family: Roboto;
  //     font-style: normal;
  //     font-weight: bold;
  //     font-size: 28px;
  //     line-height: 33px;
  //     color: #f19c7c;
  //   }
  // }

  &4 {
    width: 464px;
    height: auto;
  }

  &4-2 {
    width: 281px;
    height: 573px;
    height: auto;
  }

  &5 {
    width: 1152px;
    height: auto;
  }

  &block-3 {
    box-sizing: border-box;
    padding: 220px 144px 210px;
  }

  &6-2 {
    width: 200px;
    height: auto;
  }

  &6 {
    width: 520px;
    height: auto;
  }

  &7 {
    width: 586px;
    height: auto;
  }

  &block-4 {
    box-sizing: border-box;
    padding: 91px 244px 100px;
  }

  &block-5 {
    box-sizing: border-box;
    padding: 150px 244px 100px 244px;
  }

  &8 {
    width: auto;
    height: 91px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }

  &8-1 {
    width: 119px;
    height: auto;
  }

  &8-2 {
    width: 138px;
    height: auto;
  }

  &8-3 {
    width: 182px;
    height: auto;
  }

  &9 {
    margin: 294px 0 0 0;
    width: 952px;
    height: auto;
  }

  &block-6 {
    box-sizing: border-box;
    padding: 150px 244px 100px 244px;
  }

  &10 {
    margin: 76px 0 0 0;
    width: 952px;
    height: auto;
  }

  &11 {
    margin: 80px 0 0 0;
    width: 952px;
    height: auto;
  }

  &12 {
    margin: 25px 0 0 0;
    width: 952px;
    height: auto;
  }

  &13 {
    margin: 24px 0 0 0;
    width: 952px;
    height: auto;
  }

  &block-6 {
    box-sizing: border-box;
    padding: 150px 244px 100px 244px;
  }

  &14-1 {
    width: 56px;
    height: auto;
  }

  &14-2 {
    width: 56px;
    height: auto;
  }

  &14-3 {
    width: 63px;
    height: auto;
  }

  &14-4 {
    width: 51px;
    height: auto;
  }

  &block-7 {
    box-sizing: border-box;
    padding: 100px 144px 100px 144px;
  }

  &15 {
    margin: 80px 0 216px 0;
    width: 1152px;
    height: auto;
  }

  &16 {
    margin: 66px 0 0 0;
    width: 1152px;
    height: auto;
  }

  &17 {
    width: 798px;
    height: auto;
  }

  &18 {
    width: 952px;
    height: auto;
  }

  &19 {
    margin: 150px 0 100px 0;
    width: 1152px;
    height: auto;
  }
}
</style>
