var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"turnpage",style:({
    'justify-content':
      _vm.previous && _vm.next
        ? 'space-between'
        : _vm.previous
        ? 'flex-start'
        : 'flex-end'
  })},[(_vm.previous)?_c('button',{staticClass:"turnpage-item",on:{"click":function($event){return _vm.clickItem(_vm.previous)}}},[_c('div',{staticClass:"arrow"},[_c('svg',{attrs:{"width":"100%","height":"100%","viewBox":"0,0,15,28"}},[_c('path',{attrs:{"d":"M 14 1 L 1 13.5 L 14 27","fill":"transparent","stroke":"rgba(146,129,236,1)","stroke-miterlimit":"10"}})])]),_c('div',{staticClass:"turnpage-title",staticStyle:{"margin":"0 0 0 10px"}},[_vm._v(" Previous: "+_vm._s(_vm.previous.title)+" ")])]):_vm._e(),(_vm.next)?_c('button',{staticClass:"turnpage-item",on:{"click":function($event){return _vm.clickItem(_vm.next)}}},[_c('div',{staticClass:"turnpage-title",staticStyle:{"margin":"0 10px 0 0"}},[_vm._v(" Next: "+_vm._s(_vm.next.title)+" ")]),_c('div',{staticClass:"arrow"},[_c('svg',{attrs:{"width":"100%","height":"100%","viewBox":"0,0,15,28"}},[_c('path',{attrs:{"d":"M 1 1 L 14 13.5 L 1 27","fill":"transparent","stroke":"rgba(146,129,236,1)","stroke-miterlimit":"10"}})])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }