<template>
  <div class="page">
    <div style="width: 100%; height: 100%; position:relative; margin:auto;">
      <div class="sk-ab sk-Project4_Moving-Boxes-Copy">
        <div
          class="sk-asset sk-Rectangle-8455F8A1-4A94-42FD-ADB5-E7E8A5E1A52F"
        ></div>
        <div
          class="sk-asset sk-Rectangle-Copy-1EE2C032-94E9-436B-BBC9-143B0C6D8777"
        ></div>
        <div
          class="sk-asset sk-Rectangle-Copy-3-13869B08-4C81-4AAF-863A-B814D236D0C2"
        ></div>
        <div
          class="sk-asset sk-Rectangle-Copy-4-41CC2DC4-0B5D-40EF-BCC6-7F88520C7747"
        ></div>
        <div
          class="sk-asset sk-Rectangle-Copy-2-175B48E5-2767-43D0-B309-B2C2F04C111E"
        ></div>
        <div
          class="sk-asset sk-Rectangle-Copy-6-CB179454-F60D-419B-A3AE-E66DF4C93B4E"
        ></div>
        <div
          class="sk-asset sk-The-multipurpose-ga-2CDD97E0-1083-4AE3-9119-2005FD281EF2"
        >
          <span class="span1"
            >The multi-purpose gallary space in sophomore studio serve functions
            including </span
          ><span class="span2"
            >students physical needs, software tutorial and lectures, pin-up for
            group critique, and exhibitions</span
          ><span class="span3">
            for both sophomore students and other groups or visitors. It is
            therefore in need of an installation to fill the emptiness and serve
            students with a </span
          ><span class="span4">hand buildable design</span
          ><span class="span5">
            within the budget of $500 and the winner will eventually build their
            design.
          </span>
        </div>
        <div
          class="sk-asset sk-What-and-Why-is-the-1B0E20AB-E1E3-485C-AF95-F8EECAA7A7C6"
        >
          <span class="span1"
            >What and Why is the School of Architecture Asking?</span
          >
        </div>
        <div
          class="sk-asset sk-BACKGROUND-INFO-5A73D443-3AC1-498E-BAA0-A830276AAD0F"
        >
          <span class="span1">BACKGROUND INFO</span>
        </div>
        <div
          class="sk-asset sk-USER-BEHAVIOR-ANALYS-EAE40720-089B-4BE4-AA31-2EC6C34C9C86"
        >
          <span class="span1">USER BEHAVIOR ANALYSIS</span>
        </div>
        <div
          class="sk-asset sk-Identify-Problems-99EFD970-D0E6-41E7-B99E-4BFA2AD28B09"
        >
          <span class="span1">Identify Problems</span>
        </div>
        <div
          class="sk-asset sk-Daily-Needs-FAED9F75-E355-45FE-B33F-517523E6201D"
        >
          <span class="span1">Daily Needs</span>
        </div>
        <div
          class="sk-asset sk-Targeted-Gallery-Spa-70DF3313-7C38-40F0-9BD4-B46A6D01760B"
        >
          <span class="span1">Targeted Gallery Space</span>
        </div>
        <div
          class="sk-asset sk-Design-Development-2158CBE5-6F7B-42E7-8065-A3ED8B9773B4"
        >
          <span class="span1">Design Development </span>
        </div>
        <div
          class="sk-asset sk-SPACE-ANALYSIS-F9DCAEA0-332B-42B7-900F-20C07F35161E"
        >
          <span class="span1">SPACE ANALYSIS</span>
        </div>
        <div class="sk-asset sk-Group-10-1BA39206-A1A9-4F49-A7B6-608D4C9AE2A4">
          <div
            class="sk-asset sk-Teammates-0E0D2DA9-0F84-4202-88E7-8740DE76F7E9"
          >
            <span class="span1">Teammates</span>
          </div>
          <div class="sk-asset sk-Award-B5FE6D40-E668-4DA0-A01E-522BA9D78E5C">
            <span class="span1">Award</span>
          </div>
          <div
            class="sk-asset sk-Shuang-Bi-Neng-Zhu-192CB9FB-63D6-44BD-B635-FD70CE070574"
          >
            <span class="span1">Shuang Bi, Neng Zhu</span>
          </div>
          <div
            class="sk-asset sk-Finalist-of-3-with--C7802B2B-88B4-46DF-A4A3-AC60690BC45A"
          >
            <span class="span1">Finalist of 3 with $200 reward</span>
          </div>
          <div
            class="sk-asset sk-Multifunctional-Arc-Copy-103A80B2-64B6-4E96-B9E2-257A594C1168"
          >
            <span class="span1"
              >Multi-functional Architectural Installation
            </span>
          </div>
          <div
            class="sk-asset sk-The-Moving-Boxes-892F2F76-3AEA-4C32-89A2-6E2E50F000C7"
          >
            <span class="span1">The Moving Boxes </span>
          </div>
        </div>
        <div class="sk-asset sk-Group-3-C93481F5-3D5B-43F1-945D-58A05DDF3C0E">
          <div
            class="sk-asset sk-Installation-Locatio-F6D5B9D1-C294-4C89-B652-7B1CD1C0A8A0"
          >
            <span class="span1">Installation Location</span>
          </div>
          <div
            class="sk-asset sk-Gallery-space-in-Sop-F5234622-BAC6-4DC0-A49C-C4E70C8BA230"
          >
            <span class="span1"
              >Gallery space in Sophomore architecture studio, UIUC</span
            >
          </div>
        </div>
        <div class="sk-asset sk-Group-6-EFD38441-3A8F-4C7C-BED0-0B95F8537EFB">
          <div
            class="sk-asset sk-Stakeholders-81866DFA-8CFB-42D6-981D-4F51A174616C"
          >
            <span class="span1">Stakeholders</span>
          </div>
          <div
            class="sk-asset sk-Schools-of-Architect-69D07E49-9107-4305-96C5-242F27960694"
          >
            <span class="span1"
              >Schools of Architecture, sophomore architecture students,
              professors</span
            >
          </div>
        </div>
        <div class="sk-asset sk-Group-8-613AB0C8-87F2-4366-90EE-4C1B79BCA0E7">
          <div class="sk-asset sk-Goal-2FB1BD22-713C-4DEC-8B7A-F5E28391B3F1">
            <span class="span1">Goal</span>
          </div>
          <div
            class="sk-asset sk-Design-a-problemsol-51C43F4E-029F-450C-A693-974FEE098755"
          >
            <span class="span1"
              >Design a problem-solver installation for daily users and build it
              within budget</span
            >
          </div>
        </div>
        <div
          class="sk-asset sk-Rendering-cover-8F9081F3-1B7B-45EC-AA97-EC9520A1701C"
        ></div>
        <!-- <div class="sk-asset sk-Group-4DC7E41C-0CB2-40F5-BBE2-E15FBA0872ED">
          <div
            class="sk-asset sk-Rectangle-2CFE8D83-340D-4410-B150-315947EADA71"
          ></div>
          <div class="sk-asset sk-Works-8C577EBA-B3E2-4CEF-9AC9-A6C45B5C7B28">
            <span class="span1">Works</span>
          </div>
          <div class="sk-asset sk-Sides-F550B244-437C-4A41-99C6-0EE7B249C157">
            <span class="span1">Sides</span>
          </div>
          <div class="sk-asset sk-Resume-FE02C4A2-D595-48FE-A86A-37FB6C9A77C9">
            <span class="span1">Resume</span>
          </div>
          <div class="sk-asset sk--S--16552D1B-7816-46A4-B950-B714B6D225BB">
            <span class="span1">[ S ]</span>
          </div>
        </div> -->

        <div class="sk-asset sk-18-EB11836F-34AE-4F85-B5A5-161DBD8087FE"></div>
        <div class="sk-asset sk-19-19B3002A-D084-40B4-A5E7-33A34585CA1C"></div>
        <div class="sk-asset sk-20-A190A3E2-C706-4A5C-90CF-25107DD88570"></div>

        <!--  -->
        <div class="sk-asset checklist">
          <p class="checklist-title">FUNCTIONS IN APPLICATION</p>
          <div class="checklist-line">
            <div class="checklist-icon"></div>
            <span class="checklist-text"
              >Enclore for privary of critique, meetings, and students
              resting</span
            >
          </div>
          <div class="checklist-line">
            <div class="checklist-icon"></div>
            <span class="checklist-text">Provide more seatings</span>
          </div>
          <div class="checklist-line">
            <div class="checklist-icon"></div>
            <span class="checklist-text"
              >Provide functional surface for placing models/anything on
              hand</span
            >
          </div>
          <div class="checklist-line">
            <div class="checklist-icon"></div>
            <span class="checklist-text"
              >Provide storage space and viewing opportunity for models</span
            >
          </div>
          <div class="checklist-line">
            <div class="checklist-icon"></div>
            <span class="checklist-text"
              >Fill the empty space with a right size installation</span
            >
          </div>
        </div>
        <!-- <div
          class="sk-asset sk-Enclore-for-privary-B054018F-834B-4C4B-9D7C-0F648CD4F3BE"
        >
          <span class="span1"
            >Enclore for privary of critique, meetings, and students resting<br />Provide
            more seatings<br />Provide functional surface for placing
            models/anything on hand<br />Provide storage space and viewing
            opportunity for models<br />Fill the empty space with a right size
            installation <br /><br /><br
          /></span>
        </div>
        <div
          class="sk-asset sk-Functionality-Requir-5B332534-AB05-4891-BD97-E40C958D34F4"
        >
          <span class="span1">Functionality Requirement Checklist</span>
        </div>
        <div
          class="sk-asset sk-FUNCTIONS-IN-APPLICA-CA7DED45-6F53-47C2-B3FA-556C7AEDED1A"
        >
          <span class="span1">FUNCTIONS IN APPLICATION</span>
        </div>
        <div class="sk-asset sk-Group-9-F08314C8-D324-49FA-AE3F-17FADB9FF38C">
          <div class="sk-asset sk-Group-5-419CCCBA-3815-4CC1-B9EE-8ECB75434593">
            <div
              class="sk-asset sk-Fill-1-9EA8B027-0F4D-4357-9F7A-D66DD355F9E6"
            ></div>
            <div
              class="sk-asset sk-Stroke-3-635EEC69-1606-4F5C-882A-23597D14855C"
            ></div>
          </div>
          <div
            class="sk-asset sk-Fill-6-00EDE34F-E2A3-4768-B559-BBE421065432"
          ></div>
          <div
            class="sk-asset sk-Stroke-7-1EFA41F1-23E8-4FE7-95F1-37D169A0DFD9"
          ></div>
        </div>
        <div
          class="sk-asset sk-Group-9-Copy-2-2B5D37EB-7274-43B1-B77F-C4CA69DDBB3A"
        >
          <div class="sk-asset sk-Group-5-41726151-F7A5-4CD0-BA98-0DDF60C38927">
            <div
              class="sk-asset sk-Fill-1-90A3CE80-BFB0-4161-84C2-0006CD80E857"
            ></div>
            <div
              class="sk-asset sk-Stroke-3-22F260B3-5E3A-490A-9159-F31E2F736DA4"
            ></div>
          </div>
          <div
            class="sk-asset sk-Fill-6-AA2C039D-7D33-4B5B-B4D1-BE297C2D3D77"
          ></div>
          <div
            class="sk-asset sk-Stroke-7-B69EA206-7ABA-4038-9D44-C47FAA373FBF"
          ></div>
        </div>
        <div
          class="sk-asset sk-Group-9-Copy-58CDA9BC-66F4-48C8-A60C-C0C84EFC5667"
        >
          <div class="sk-asset sk-Group-5-5242C3AA-95A5-4400-AE2F-E60E4DB2EB38">
            <div
              class="sk-asset sk-Fill-1-0AF994D3-47BF-4A67-8B16-25FD5D4545BB"
            ></div>
            <div
              class="sk-asset sk-Stroke-3-C25E7F58-FCB6-47A7-9810-60C3573DA730"
            ></div>
          </div>
          <div
            class="sk-asset sk-Fill-6-76F44AEF-4F53-4488-BFFE-7868DE24AE33"
          ></div>
          <div
            class="sk-asset sk-Stroke-7-526A7FD3-003D-488E-BABB-9FA0FF1A65AF"
          ></div>
        </div>
        <div
          class="sk-asset sk-Group-9-Copy-3-A01EF832-71F2-4490-8591-8DE92CE551A2"
        >
          <div class="sk-asset sk-Group-5-7F2114ED-F802-497B-A92D-D7D8041206D2">
            <div
              class="sk-asset sk-Fill-1-E3D62B99-EFBE-4AF9-B418-C0B34DB690EF"
            ></div>
            <div
              class="sk-asset sk-Stroke-3-5ACCAD82-D556-441D-B44F-AE3DE029C8D6"
            ></div>
          </div>
          <div
            class="sk-asset sk-Fill-6-1B138D9C-3CE7-4305-B017-4A0A702C2642"
          ></div>
          <div
            class="sk-asset sk-Stroke-7-000B5D52-EAA1-41A9-8FC9-91EDB846E7CA"
          ></div>
        </div>
        <div
          class="sk-asset sk-Group-9-Copy-4-36855F70-F440-4AD1-A7E3-CFF986718E8A"
        >
          <div class="sk-asset sk-Group-5-4ABE4F57-43CA-4123-A934-6FFEDD103555">
            <div
              class="sk-asset sk-Fill-1-B11B4800-B0E7-431E-89B6-ECC286EC4C95"
            ></div>
            <div
              class="sk-asset sk-Stroke-3-ABE7ADDA-D0C9-4F3A-9FD4-7576A7D04A65"
            ></div>
          </div>
          <div
            class="sk-asset sk-Fill-6-2665CA3B-CAFE-43F7-AE9C-A2DFBD10319A"
          ></div>
          <div
            class="sk-asset sk-Stroke-7-7B69A0A5-F2D8-49CD-8380-35A9A10C57C1"
          ></div>
        </div> -->
        <!--  -->
        <div
          class="sk-asset sk-CONSTRUCTION-DETAILS-F4A74FB7-9648-473F-A5B3-149B1E5CC5BF"
        >
          <span class="span1">CONSTRUCTION DETAILS & CONSIDERATION</span>
        </div>
        <div class="sk-asset sk-Group-29-C58726B2-72E8-49E9-8862-C4142AEB28B5">
          <div class="sk-asset sk-155-773F136E-4F02-4206-8927-1D3E3DF03D4E">
            <span class="span1">15.5”</span>
          </div>
          <div
            class="sk-asset sk-Group-15765-44E09454-3C63-4C0E-90A0-BF0044E0FE44"
          >
            <div
              class="sk-asset sk-Stroke-15690-37A91F5E-CD25-47EF-BD6C-63B2548F5957"
            ></div>
          </div>
        </div>
        <div
          class="sk-asset sk-STORY-BOARD-ABE0E935-F990-42E4-8EA1-413EF82F80D3"
        >
          <span class="span1">STORY BOARD</span>
        </div>
        <div
          class="sk-asset sk-The-daily-story-of-o-4A002358-419F-46B1-A358-527B420F86F8"
        >
          <span class="span1">The daily story of our studio</span>
        </div>
        <!-- <div class="sk-asset sk-Group-31-7A59E674-63C9-404B-BE17-8A8932C38E18">
          <div
            class="sk-asset sk-Group-2-Copy-0CB1ED92-B726-4719-A41E-B694951ED040"
          >
            <div
              class="sk-asset sk-Rectangle-34625DBB-8E8F-4CEE-84F4-CBC2D6B60265"
            ></div>
            <div
              class="sk-asset sk-Previous-Roomsmart-61D013F3-0707-4AA6-8D18-03E0C9D5580F"
            >
              <span class="span1">Previous: Roomsmart UI/ UX Design</span>
            </div>
            <div
              class="sk-asset sk-Path-6-AA8661A9-B816-44C5-8C3D-97A2C58BD911"
            ></div>
          </div>
          <div
            class="sk-asset sk-Path-7-37CF0F9F-F9A7-40D7-936E-A62C6ACAB28F"
          ></div>
        </div>
        <div class="sk-asset sk-Group-32-249AB99E-5EE0-49DF-97AE-D594A1470CA8">
          <div
            class="sk-asset sk-Group-2-Copy-2-B9FED4C4-5BE1-4EF2-99FD-F1895620D0B0"
          >
            <div
              class="sk-asset sk-Rectangle-FADE1527-B1B1-4E25-8215-8304C96149CE"
            ></div>
            <div
              class="sk-asset sk-Next-UX-Product-o-75BC796B-3D07-4F02-ADD2-0811D59B2D29"
            >
              <span class="span1"
                >Next: UX & Product of FurGo Pet Tracker & Monitor
              </span>
            </div>
            <div
              class="sk-asset sk-Path-6-E08B407C-F5FF-4D5F-8E21-9BED4D106128"
            ></div>
          </div>
          <div
            class="sk-asset sk-Path-7-Copy-212CE0B6-23D2-49CA-930C-0F893FF3AAC1"
          ></div>
        </div>
        <div class="sk-asset sk-Group-17-5C4928EE-3338-4E4E-AF23-00C1F44B7B2B">
          <div
            class="sk-asset sk-Group-12-DF7419D7-0D7B-476F-B8D6-4377B7D18E9E"
          >
            <div
              class="sk-asset sk-Group-4-7A5BFAB8-97E7-4C4E-AA78-0ADAFA170412"
            ></div>
            <div
              class="sk-asset sk-Group-7-87472541-2D64-4549-A373-DEBA73798B53"
            ></div>
            <div
              class="sk-asset sk-Group-2-605F5F22-0E1D-4B39-B189-4BD4C2C18147"
            ></div>
          </div>
          <div
            class="sk-asset sk-If-you-would-like-to-D941655C-6E52-4E8A-A029-CF59F75F650E"
          >
            <span class="span1"
              >If you would like to work with an enthutiastic individual, grab a
              coffee with a big cat lover, talk about the crazy stuff going on
              in this world…</span
            >
          </div>
          <div class="sk-asset sk--0FB45336-9DA0-4944-95D1-C96AC1AD5BC0">
            <span class="span1">👇</span>
          </div>
          <div
            class="sk-asset sk-Connect-with-me-169D3EAB-C1A2-4614-801E-CC55FE00DAA8"
          >
            <span class="span1">Connect with me: </span>
          </div>
        </div> -->
        <div class="sk-asset sk-2-0476C3E8-E6B2-4A42-8B96-313A838EBC2C"></div>
        <div class="sk-asset sk-3-8CC93CA1-C4FC-4960-A81F-D7800C0CAB9C"></div>
        <div class="sk-asset sk-4-980EA145-EBA6-42AC-9E01-A8B9DD346F58"></div>
        <div class="sk-asset sk-5-E53D199F-5340-46C8-B2D6-D40F803B70A0"></div>
        <div class="sk-asset sk-6-D99F7D7D-9827-478F-B7FA-692787D34510"></div>
        <div class="sk-asset sk-7-377085EC-5711-4DA5-BED0-459A2EAD22D3"></div>
        <div class="sk-asset sk-8-B2B72AA7-B28B-4A18-BAC4-4D3338F5772E"></div>
        <div class="sk-asset sk-9-E014ED80-7759-416D-97ED-6C7669F39FE6"></div>
        <div class="sk-asset sk-10-A4CE260E-1584-4F69-86F7-71B7CF2EB0A5"></div>
        <div class="sk-asset sk-11-9F824A3E-E692-4FBA-9330-A5B887EFBEC0"></div>
        <div class="sk-asset sk-12-21F3B8FE-6468-41A9-AF4A-0530176A4F22"></div>
        <div class="sk-asset sk-13-F4C529CA-0708-4C01-B45C-DAB2981E7484"></div>
        <div class="sk-asset sk-14-760818D6-7113-4D3D-B2B2-7F98FC0D3CCA"></div>
        <div class="sk-asset sk-15-B0E3DC2A-C110-4670-B524-B4D06DDB5D3B"></div>
        <div class="sk-asset sk-16-6C003A71-FF8E-4F0E-A0E0-7BF0C7EADE7E"></div>
        <div class="sk-asset sk-17-5D5184C1-66A1-477B-917A-2193D659D0C2"></div>
        <div class="sk-asset sk-21-3AB3E7E0-5532-42B7-99E9-A9B21413FF84"></div>
        <div class="sk-asset sk-22-EEF65462-D6E2-47EC-BABC-F09B23E2DC45"></div>
      </div>
    </div>
    <Turnpage :previous="previous" :next="next"></Turnpage>
  </div>
</template>

<script>
import Turnpage from "@/components/turnpage.vue";
export default {
  name: "MovingBoxes",
  components: {
    Turnpage
  },
  data: () => {
    return {
      previous: {
        title: "Political Engagement Website for Minorities",
        link: "http://www.wetheasians.com"
      },
      next: null,
      // next: {
      //   title: "SIMPLE ROOM RESERVATION SYSTEM",
      //   name: "RoomSmart"
      // },
      handler: null
    };
  },
  mounted() {
    let top = document.getElementsByClassName(
      "sk-asset sk-18-EB11836F-34AE-4F85-B5A5-161DBD8087FE"
    )[0].offsetTop;
    let bottom = document.getElementsByClassName(
      "sk-asset sk-20-A190A3E2-C706-4A5C-90CF-25107DD88570"
    )[0].offsetTop;

    let monitor = () => {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;

      if (scrollTop > top && scrollTop < bottom) {
        document.getElementsByClassName("sk-asset checklist")[0].style.top =
          scrollTop + "px";
      }

      this.handler = window.requestAnimationFrame(monitor);
    };

    monitor();
  },
  destroyed() {
    if (this.handler) {
      window.cancelAnimationFrame(this.handler);
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 100px 0;
}

.checklist {
  left: 932px;
  top: 10708px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &-title {
    position: relative;
    color: #58595b;
    font-size: 21px;
    font-family: "TsukuARdGothic-Bold", sans-serif, serif;
    font-weight: bold;
    text-align: left;
  }

  &-line {
    margin: 0 0 23px 0;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &-icon {
    margin: 0 16px 0 0;
    width: 23px;
    height: 23px;
    background-image: url("/assets/works/MovingBoxes/images/checkbox.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  &-text {
    width: 359px;
    text-align: left;
    color: #58595b;
    font-size: 18px;
    font-family: "TsukuARdGothic-Regular", sans-serif, serif;
    line-height: 23px;
  }
}

/* Generated by Genus plugin */

/* Boiler plate CSS
sk-ab is css style applied to full art board
sk-asset is common css property applied to every css style generated by genus plugin
*/

.sk-ab {
  position: relative;
  overflow: hidden;
}

.sk-asset {
  position: absolute;
  background-repeat: no-repeat;
}

/* Specific to Project#4_Moving Boxes Copy */
.sk-ab.sk-Project4_Moving-Boxes-Copy {
  background-color: #ffffffff;
  width: 100%;
  height: 13423px;
}

.sk-asset.sk-Rectangle-8455F8A1-4A94-42FD-ADB5-E7E8A5E1A52F {
  background-image: url("/assets/works/MovingBoxes/images/Rectangle-8455F8A1-4A94-42FD-ADB5-E7E8A5E1A52F.png");
  left: 452px;
  top: 90px;
  width: 108px;
  height: 108px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Rectangle-8455F8A1-4A94-42FD-ADB5-E7E8A5E1A52F {
    background-image: url("/assets/works/MovingBoxes/images/Rectangle-8455F8A1-4A94-42FD-ADB5-E7E8A5E1A52F@2x.png");
    background-size: 108px 108px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Rectangle-8455F8A1-4A94-42FD-ADB5-E7E8A5E1A52F {
    background-image: url("/assets/works/MovingBoxes/images/Rectangle-8455F8A1-4A94-42FD-ADB5-E7E8A5E1A52F@3x.png");
    background-size: 108px 108px;
  }
}
.sk-asset.sk-Rectangle-Copy-1EE2C032-94E9-436B-BBC9-143B0C6D8777 {
  background-image: url("/assets/works/MovingBoxes/images/Rectangle-Copy-1EE2C032-94E9-436B-BBC9-143B0C6D8777.png");
  left: 363px;
  top: 162px;
  width: 108px;
  height: 108px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Rectangle-Copy-1EE2C032-94E9-436B-BBC9-143B0C6D8777 {
    background-image: url("/assets/works/MovingBoxes/images/Rectangle-Copy-1EE2C032-94E9-436B-BBC9-143B0C6D8777@2x.png");
    background-size: 108px 108px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Rectangle-Copy-1EE2C032-94E9-436B-BBC9-143B0C6D8777 {
    background-image: url("/assets/works/MovingBoxes/images/Rectangle-Copy-1EE2C032-94E9-436B-BBC9-143B0C6D8777@3x.png");
    background-size: 108px 108px;
  }
}
.sk-asset.sk-Rectangle-Copy-3-13869B08-4C81-4AAF-863A-B814D236D0C2 {
  background-image: url("/assets/works/MovingBoxes/images/Rectangle-Copy-3-13869B08-4C81-4AAF-863A-B814D236D0C2.png");
  left: 598px;
  top: 569px;
  width: 108px;
  height: 108px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Rectangle-Copy-3-13869B08-4C81-4AAF-863A-B814D236D0C2 {
    background-image: url("/assets/works/MovingBoxes/images/Rectangle-Copy-3-13869B08-4C81-4AAF-863A-B814D236D0C2@2x.png");
    background-size: 108px 108px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Rectangle-Copy-3-13869B08-4C81-4AAF-863A-B814D236D0C2 {
    background-image: url("/assets/works/MovingBoxes/images/Rectangle-Copy-3-13869B08-4C81-4AAF-863A-B814D236D0C2@3x.png");
    background-size: 108px 108px;
  }
}
.sk-asset.sk-Rectangle-Copy-4-41CC2DC4-0B5D-40EF-BCC6-7F88520C7747 {
  background-image: url("/assets/works/MovingBoxes/images/Rectangle-Copy-4-41CC2DC4-0B5D-40EF-BCC6-7F88520C7747.png");
  left: 661px;
  top: 644px;
  width: 108px;
  height: 108px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Rectangle-Copy-4-41CC2DC4-0B5D-40EF-BCC6-7F88520C7747 {
    background-image: url("/assets/works/MovingBoxes/images/Rectangle-Copy-4-41CC2DC4-0B5D-40EF-BCC6-7F88520C7747@2x.png");
    background-size: 108px 108px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Rectangle-Copy-4-41CC2DC4-0B5D-40EF-BCC6-7F88520C7747 {
    background-image: url("/assets/works/MovingBoxes/images/Rectangle-Copy-4-41CC2DC4-0B5D-40EF-BCC6-7F88520C7747@3x.png");
    background-size: 108px 108px;
  }
}
.sk-asset.sk-Rectangle-Copy-2-175B48E5-2767-43D0-B309-B2C2F04C111E {
  background-image: url("/assets/works/MovingBoxes/images/Rectangle-Copy-2-175B48E5-2767-43D0-B309-B2C2F04C111E.png");
  left: 773px;
  top: 216px;
  width: 108px;
  height: 108px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Rectangle-Copy-2-175B48E5-2767-43D0-B309-B2C2F04C111E {
    background-image: url("/assets/works/MovingBoxes/images/Rectangle-Copy-2-175B48E5-2767-43D0-B309-B2C2F04C111E@2x.png");
    background-size: 108px 108px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Rectangle-Copy-2-175B48E5-2767-43D0-B309-B2C2F04C111E {
    background-image: url("/assets/works/MovingBoxes/images/Rectangle-Copy-2-175B48E5-2767-43D0-B309-B2C2F04C111E@3x.png");
    background-size: 108px 108px;
  }
}
.sk-asset.sk-Rectangle-Copy-6-CB179454-F60D-419B-A3AE-E66DF4C93B4E {
  background-image: url("/assets/works/MovingBoxes/images/Rectangle-Copy-6-CB179454-F60D-419B-A3AE-E66DF4C93B4E.png");
  left: 132px;
  top: 564px;
  width: 99px;
  height: 99px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Rectangle-Copy-6-CB179454-F60D-419B-A3AE-E66DF4C93B4E {
    background-image: url("/assets/works/MovingBoxes/images/Rectangle-Copy-6-CB179454-F60D-419B-A3AE-E66DF4C93B4E@2x.png");
    background-size: 99px 99px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Rectangle-Copy-6-CB179454-F60D-419B-A3AE-E66DF4C93B4E {
    background-image: url("/assets/works/MovingBoxes/images/Rectangle-Copy-6-CB179454-F60D-419B-A3AE-E66DF4C93B4E@3x.png");
    background-size: 99px 99px;
  }
}
.sk-asset.sk-The-multipurpose-ga-2CDD97E0-1083-4AE3-9119-2005FD281EF2 {
  left: 300px;
  top: 1061px;
  width: 840px;
  height: 167px;
}

.sk-asset.sk-The-multipurpose-ga-2CDD97E0-1083-4AE3-9119-2005FD281EF2 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  color: #404040ff;
  font-size: 18px;
  line-height: 30px;
  opacity: 1;
  text-align: justified;
  font-kerning: 0;
}

.sk-asset.sk-The-multipurpose-ga-2CDD97E0-1083-4AE3-9119-2005FD281EF2 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  color: #404040;
  font-size: 18px;
}

.sk-asset.sk-The-multipurpose-ga-2CDD97E0-1083-4AE3-9119-2005FD281EF2 .span2 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  color: #404040;
  font-size: 18px;
}

.sk-asset.sk-The-multipurpose-ga-2CDD97E0-1083-4AE3-9119-2005FD281EF2 .span3 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  color: #404040;
  font-size: 18px;
}

.sk-asset.sk-The-multipurpose-ga-2CDD97E0-1083-4AE3-9119-2005FD281EF2 .span4 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  color: #404040;
  font-size: 18px;
}

.sk-asset.sk-The-multipurpose-ga-2CDD97E0-1083-4AE3-9119-2005FD281EF2 .span5 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  color: #404040;
  font-size: 18px;
}

.sk-asset.sk-What-and-Why-is-the-1B0E20AB-E1E3-485C-AF95-F8EECAA7A7C6 {
  left: 300px;
  top: 1028px;
  width: 520px;
  height: 33px;
}

.sk-asset.sk-What-and-Why-is-the-1B0E20AB-E1E3-485C-AF95-F8EECAA7A7C6 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #ce7182ff;
  font-size: 21px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-What-and-Why-is-the-1B0E20AB-E1E3-485C-AF95-F8EECAA7A7C6 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #ce7182;
  font-size: 21px;
}

.sk-asset.sk-BACKGROUND-INFO-5A73D443-3AC1-498E-BAA0-A830276AAD0F {
  left: 300px;
  top: 1328px;
  width: 520px;
  height: 33px;
}

.sk-asset.sk-BACKGROUND-INFO-5A73D443-3AC1-498E-BAA0-A830276AAD0F {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #ce7182ff;
  font-size: 21px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-BACKGROUND-INFO-5A73D443-3AC1-498E-BAA0-A830276AAD0F .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #ce7182;
  font-size: 21px;
}

.sk-asset.sk-USER-BEHAVIOR-ANALYS-EAE40720-089B-4BE4-AA31-2EC6C34C9C86 {
  left: 300px;
  top: 3534px;
  width: 520px;
  height: 33px;
}

.sk-asset.sk-USER-BEHAVIOR-ANALYS-EAE40720-089B-4BE4-AA31-2EC6C34C9C86 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #ce7182ff;
  font-size: 21px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-USER-BEHAVIOR-ANALYS-EAE40720-089B-4BE4-AA31-2EC6C34C9C86 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #ce7182;
  font-size: 21px;
}

.sk-asset.sk-Identify-Problems-99EFD970-D0E6-41E7-B99E-4BFA2AD28B09 {
  left: 300px;
  top: 4009px;
  width: 221px;
  height: 33px;
}

.sk-asset.sk-Identify-Problems-99EFD970-D0E6-41E7-B99E-4BFA2AD28B09 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #c26b6aff;
  font-size: 21px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Identify-Problems-99EFD970-D0E6-41E7-B99E-4BFA2AD28B09 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #c26b6a;
  font-size: 21px;
}

.sk-asset.sk-Daily-Needs-FAED9F75-E355-45FE-B33F-517523E6201D {
  left: 903px;
  top: 3643px;
  // width: 86px;
  height: 21px;
}

.sk-asset.sk-Daily-Needs-FAED9F75-E355-45FE-B33F-517523E6201D {
  font-family: "Apple Symbols";
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #ca6f65ff;
  font-size: 21px;
  line-height: null.0px;
  opacity: 1;
  text-align: left;
  font-kerning: null;
}

.sk-asset.sk-Daily-Needs-FAED9F75-E355-45FE-B33F-517523E6201D .span1 {
  font-family: "Apple Symbols";
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #ca6f65;
  font-size: 21px;
}

.sk-asset.sk-Targeted-Gallery-Spa-70DF3313-7C38-40F0-9BD4-B46A6D01760B {
  left: 454px;
  top: 3642px;
  width: 171px;
  height: 21px;
}

.sk-asset.sk-Targeted-Gallery-Spa-70DF3313-7C38-40F0-9BD4-B46A6D01760B {
  font-family: "Apple Symbols";
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #ca6f65ff;
  font-size: 21px;
  line-height: null.0px;
  opacity: 1;
  text-align: left;
  font-kerning: null;
}

.sk-asset.sk-Targeted-Gallery-Spa-70DF3313-7C38-40F0-9BD4-B46A6D01760B .span1 {
  font-family: "Apple Symbols";
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #ca6f65;
  font-size: 21px;
}

.sk-asset.sk-Design-Development-2158CBE5-6F7B-42E7-8065-A3ED8B9773B4 {
  left: 300px;
  top: 8033px;
  width: 520px;
  height: 33px;
}

.sk-asset.sk-Design-Development-2158CBE5-6F7B-42E7-8065-A3ED8B9773B4 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #ce7182ff;
  font-size: 21px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Design-Development-2158CBE5-6F7B-42E7-8065-A3ED8B9773B4 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #ce7182;
  font-size: 21px;
}

.sk-asset.sk-SPACE-ANALYSIS-F9DCAEA0-332B-42B7-900F-20C07F35161E {
  left: 301px;
  top: 2274px;
  width: 520px;
  height: 33px;
}

.sk-asset.sk-SPACE-ANALYSIS-F9DCAEA0-332B-42B7-900F-20C07F35161E {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #ce7182ff;
  font-size: 21px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-SPACE-ANALYSIS-F9DCAEA0-332B-42B7-900F-20C07F35161E .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #ce7182;
  font-size: 21px;
}

.sk-asset.sk-Teammates-0E0D2DA9-0F84-4202-88E7-8740DE76F7E9 {
  left: 0px;
  top: 278px;
  width: 404px;
  height: 33px;
}

.sk-asset.sk-Teammates-0E0D2DA9-0F84-4202-88E7-8740DE76F7E9 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 18px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Teammates-0E0D2DA9-0F84-4202-88E7-8740DE76F7E9 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 18px;
}

.sk-asset.sk-Award-B5FE6D40-E668-4DA0-A01E-522BA9D78E5C {
  left: 0px;
  top: 204px;
  width: 404px;
  height: 33px;
}

.sk-asset.sk-Award-B5FE6D40-E668-4DA0-A01E-522BA9D78E5C {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 18px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Award-B5FE6D40-E668-4DA0-A01E-522BA9D78E5C .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 18px;
}

.sk-asset.sk-Shuang-Bi-Neng-Zhu-192CB9FB-63D6-44BD-B635-FD70CE070574 {
  left: 0px;
  top: 306px;
  width: 404px;
  height: 46px;
}

.sk-asset.sk-Shuang-Bi-Neng-Zhu-192CB9FB-63D6-44BD-B635-FD70CE070574 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 18px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Shuang-Bi-Neng-Zhu-192CB9FB-63D6-44BD-B635-FD70CE070574 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 18px;
}

.sk-asset.sk-Finalist-of-3-with--C7802B2B-88B4-46DF-A4A3-AC60690BC45A {
  left: 0px;
  top: 232px;
  width: 404px;
  height: 46px;
}

.sk-asset.sk-Finalist-of-3-with--C7802B2B-88B4-46DF-A4A3-AC60690BC45A {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 18px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Finalist-of-3-with--C7802B2B-88B4-46DF-A4A3-AC60690BC45A .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 18px;
}

.sk-asset.sk-Multifunctional-Arc-Copy-103A80B2-64B6-4E96-B9E2-257A594C1168 {
  left: 0px;
  top: 71px;
  width: 404px;
  height: 126px;
}

.sk-asset.sk-Multifunctional-Arc-Copy-103A80B2-64B6-4E96-B9E2-257A594C1168 {
  font-family: "DIN Alternate";
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 36px;
  line-height: 42px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Multifunctional-Arc-Copy-103A80B2-64B6-4E96-B9E2-257A594C1168
  .span1 {
  font-family: "DIN Alternate";
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 36px;
}

.sk-asset.sk-The-Moving-Boxes-892F2F76-3AEA-4C32-89A2-6E2E50F000C7 {
  left: 0px;
  top: 0px;
  width: 404px;
  height: 46px;
}

.sk-asset.sk-The-Moving-Boxes-892F2F76-3AEA-4C32-89A2-6E2E50F000C7 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #ce7182ff;
  font-size: 36px;
  line-height: 36px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-The-Moving-Boxes-892F2F76-3AEA-4C32-89A2-6E2E50F000C7 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #ce7182;
  font-size: 36px;
}

.sk-asset.sk-Group-10-1BA39206-A1A9-4F49-A7B6-608D4C9AE2A4 {
  left: 918px;
  top: 258px;
  width: 404px;
  height: 352px;
}

.sk-asset.sk-Installation-Locatio-F6D5B9D1-C294-4C89-B652-7B1CD1C0A8A0 {
  left: 0px;
  top: 0px;
  width: 210px;
  height: 33px;
}

.sk-asset.sk-Installation-Locatio-F6D5B9D1-C294-4C89-B652-7B1CD1C0A8A0 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 21px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Installation-Locatio-F6D5B9D1-C294-4C89-B652-7B1CD1C0A8A0 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 21px;
}

.sk-asset.sk-Gallery-space-in-Sop-F5234622-BAC6-4DC0-A49C-C4E70C8BA230 {
  left: 0px;
  top: 38px;
  width: 234px;
  height: 83px;
}

.sk-asset.sk-Gallery-space-in-Sop-F5234622-BAC6-4DC0-A49C-C4E70C8BA230 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #404040ff;
  font-size: 18px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Gallery-space-in-Sop-F5234622-BAC6-4DC0-A49C-C4E70C8BA230 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #404040;
  font-size: 18px;
}

.sk-asset.sk-Group-3-C93481F5-3D5B-43F1-945D-58A05DDF3C0E {
  left: 300px;
  top: 837px;
  width: 234px;
  height: 121px;
}

.sk-asset.sk-Stakeholders-81866DFA-8CFB-42D6-981D-4F51A174616C {
  left: 0px;
  top: 0px;
  width: 234px;
  height: 33px;
}

.sk-asset.sk-Stakeholders-81866DFA-8CFB-42D6-981D-4F51A174616C {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 21px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Stakeholders-81866DFA-8CFB-42D6-981D-4F51A174616C .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 21px;
}

.sk-asset.sk-Schools-of-Architect-69D07E49-9107-4305-96C5-242F27960694 {
  left: 0px;
  top: 38px;
  width: 234px;
  height: 83px;
}

.sk-asset.sk-Schools-of-Architect-69D07E49-9107-4305-96C5-242F27960694 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #404040ff;
  font-size: 18px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Schools-of-Architect-69D07E49-9107-4305-96C5-242F27960694 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #404040;
  font-size: 18px;
}

.sk-asset.sk-Group-6-EFD38441-3A8F-4C7C-BED0-0B95F8537EFB {
  left: 598px;
  top: 837px;
  width: 234px;
  height: 121px;
}

.sk-asset.sk-Goal-2FB1BD22-713C-4DEC-8B7A-F5E28391B3F1 {
  left: 0px;
  top: 0px;
  width: 245px;
  height: 33px;
}

.sk-asset.sk-Goal-2FB1BD22-713C-4DEC-8B7A-F5E28391B3F1 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 21px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Goal-2FB1BD22-713C-4DEC-8B7A-F5E28391B3F1 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 21px;
}

.sk-asset.sk-Design-a-problemsol-51C43F4E-029F-450C-A693-974FEE098755 {
  left: 0px;
  top: 38px;
  width: 259px;
  height: 83px;
}

.sk-asset.sk-Design-a-problemsol-51C43F4E-029F-450C-A693-974FEE098755 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #404040ff;
  font-size: 18px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Design-a-problemsol-51C43F4E-029F-450C-A693-974FEE098755 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #404040;
  font-size: 18px;
}

.sk-asset.sk-Group-8-613AB0C8-87F2-4366-90EE-4C1B79BCA0E7 {
  left: 881px;
  top: 837px;
  width: 259px;
  height: 121px;
}

.sk-asset.sk-Rendering-cover-8F9081F3-1B7B-45EC-AA97-EC9520A1701C {
  background-image: url("/assets/works/MovingBoxes/images/Rendering-cover-8F9081F3-1B7B-45EC-AA97-EC9520A1701C.png");
  left: 118px;
  top: 248px;
  width: 687px;
  height: 369px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Rendering-cover-8F9081F3-1B7B-45EC-AA97-EC9520A1701C {
    background-image: url("/assets/works/MovingBoxes/images/Rendering-cover-8F9081F3-1B7B-45EC-AA97-EC9520A1701C@2x.png");
    background-size: 687px 369px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Rendering-cover-8F9081F3-1B7B-45EC-AA97-EC9520A1701C {
    background-image: url("/assets/works/MovingBoxes/images/Rendering-cover-8F9081F3-1B7B-45EC-AA97-EC9520A1701C@3x.png");
    background-size: 687px 369px;
  }
}
.sk-asset.sk-Rectangle-2CFE8D83-340D-4410-B150-315947EADA71 {
  background-image: url("/assets/works/MovingBoxes/images/Rectangle-2CFE8D83-340D-4410-B150-315947EADA71.png");
  left: 0px;
  top: 0px;
  width: 100%;
  height: 70px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Rectangle-2CFE8D83-340D-4410-B150-315947EADA71 {
    background-image: url("/assets/works/MovingBoxes/images/Rectangle-2CFE8D83-340D-4410-B150-315947EADA71@2x.png");
    background-size: 100% 70px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Rectangle-2CFE8D83-340D-4410-B150-315947EADA71 {
    background-image: url("/assets/works/MovingBoxes/images/Rectangle-2CFE8D83-340D-4410-B150-315947EADA71@3x.png");
    background-size: 100% 70px;
  }
}
.sk-asset.sk-Works-8C577EBA-B3E2-4CEF-9AC9-A6C45B5C7B28 {
  left: 1055px;
  top: 29px;
  width: 52px;
  height: 18px;
}

.sk-asset.sk-Works-8C577EBA-B3E2-4CEF-9AC9-A6C45B5C7B28 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #826febff;
  font-size: 18px;
  line-height: null.0px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Works-8C577EBA-B3E2-4CEF-9AC9-A6C45B5C7B28 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #826feb;
  font-size: 18px;
}

.sk-asset.sk-Sides-F550B244-437C-4A41-99C6-0EE7B249C157 {
  left: 1167px;
  top: 31px;
  width: 38px;
  height: 16px;
}

.sk-asset.sk-Sides-F550B244-437C-4A41-99C6-0EE7B249C157 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #7e7e7eff;
  font-size: 16px;
  line-height: null.0px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Sides-F550B244-437C-4A41-99C6-0EE7B249C157 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #7e7e7e;
  font-size: 16px;
}

.sk-asset.sk-Resume-FE02C4A2-D595-48FE-A86A-37FB6C9A77C9 {
  left: 1265px;
  top: 31px;
  width: 57px;
  height: 16px;
}

.sk-asset.sk-Resume-FE02C4A2-D595-48FE-A86A-37FB6C9A77C9 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #7e7e7eff;
  font-size: 16px;
  line-height: null.0px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-Resume-FE02C4A2-D595-48FE-A86A-37FB6C9A77C9 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #7e7e7e;
  font-size: 16px;
}

.sk-asset.sk--S--16552D1B-7816-46A4-B950-B714B6D225BB {
  left: 121px;
  top: 32px;
  width: 54px;
  height: 24px;
}

.sk-asset.sk--S--16552D1B-7816-46A4-B950-B714B6D225BB {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 24px;
  line-height: null.0px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk--S--16552D1B-7816-46A4-B950-B714B6D225BB .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 24px;
}

.sk-asset.sk-Group-4DC7E41C-0CB2-40F5-BBE2-E15FBA0872ED {
  left: 0px;
  top: 0px;
  width: 100%;
  height: 70px;
}

.sk-asset.sk-Enclore-for-privary-B054018F-834B-4C4B-9D7C-0F648CD4F3BE {
  left: 970px;
  top: 10752px;
  width: 359px;
  height: 299px;
}

.sk-asset.sk-Enclore-for-privary-B054018F-834B-4C4B-9D7C-0F648CD4F3BE {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #58595bff;
  font-size: 18px;
  line-height: 23px;
  opacity: 1;
  text-align: left;
  font-kerning: null;
}

.sk-asset.sk-Enclore-for-privary-B054018F-834B-4C4B-9D7C-0F648CD4F3BE .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #58595b;
  font-size: 18px;
}

.sk-asset.sk-Functionality-Requir-5B332534-AB05-4891-BD97-E40C958D34F4 {
  left: 932px;
  top: 10708px;
  width: 371px;
  height: 32px;
}

.sk-asset.sk-Functionality-Requir-5B332534-AB05-4891-BD97-E40C958D34F4 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #58595bff;
  font-size: 21px;
  line-height: null.0px;
  opacity: 1;
  text-align: justified;
  font-kerning: null;
}

.sk-asset.sk-Functionality-Requir-5B332534-AB05-4891-BD97-E40C958D34F4 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #58595b;
  font-size: 21px;
}

.sk-asset.sk-FUNCTIONS-IN-APPLICA-CA7DED45-6F53-47C2-B3FA-556C7AEDED1A {
  left: 312px;
  top: 10298px;
  width: 520px;
  height: 33px;
}

.sk-asset.sk-FUNCTIONS-IN-APPLICA-CA7DED45-6F53-47C2-B3FA-556C7AEDED1A {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #ce7182ff;
  font-size: 21px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-FUNCTIONS-IN-APPLICA-CA7DED45-6F53-47C2-B3FA-556C7AEDED1A .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #ce7182;
  font-size: 21px;
}

.sk-asset.sk-18-EB11836F-34AE-4F85-B5A5-161DBD8087FE {
  background-image: url("/assets/works/MovingBoxes/images/18-EB11836F-34AE-4F85-B5A5-161DBD8087FE.png");
  left: 126px;
  top: 10693px;
  width: 739px;
  height: 398px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-18-EB11836F-34AE-4F85-B5A5-161DBD8087FE {
    background-image: url("/assets/works/MovingBoxes/images/18-EB11836F-34AE-4F85-B5A5-161DBD8087FE@2x.png");
    background-size: 739px 398px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-18-EB11836F-34AE-4F85-B5A5-161DBD8087FE {
    background-image: url("/assets/works/MovingBoxes/images/18-EB11836F-34AE-4F85-B5A5-161DBD8087FE@3x.png");
    background-size: 739px 398px;
  }
}
.sk-asset.sk-19-19B3002A-D084-40B4-A5E7-33A34585CA1C {
  background-image: url("/assets/works/MovingBoxes/images/19-19B3002A-D084-40B4-A5E7-33A34585CA1C.png");
  left: 125px;
  top: 11153px;
  width: 740px;
  height: 398px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-19-19B3002A-D084-40B4-A5E7-33A34585CA1C {
    background-image: url("/assets/works/MovingBoxes/images/19-19B3002A-D084-40B4-A5E7-33A34585CA1C@2x.png");
    background-size: 740px 398px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-19-19B3002A-D084-40B4-A5E7-33A34585CA1C {
    background-image: url("/assets/works/MovingBoxes/images/19-19B3002A-D084-40B4-A5E7-33A34585CA1C@3x.png");
    background-size: 740px 398px;
  }
}
.sk-asset.sk-20-A190A3E2-C706-4A5C-90CF-25107DD88570 {
  background-image: url("/assets/works/MovingBoxes/images/20-A190A3E2-C706-4A5C-90CF-25107DD88570.png");
  left: 130px;
  top: 11613px;
  width: 740px;
  height: 398px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-20-A190A3E2-C706-4A5C-90CF-25107DD88570 {
    background-image: url("/assets/works/MovingBoxes/images/20-A190A3E2-C706-4A5C-90CF-25107DD88570@2x.png");
    background-size: 740px 398px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-20-A190A3E2-C706-4A5C-90CF-25107DD88570 {
    background-image: url("/assets/works/MovingBoxes/images/20-A190A3E2-C706-4A5C-90CF-25107DD88570@3x.png");
    background-size: 740px 398px;
  }
}
.sk-asset.sk-Fill-1-9EA8B027-0F4D-4357-9F7A-D66DD355F9E6 {
  background-image: url("/assets/works/MovingBoxes/images/Fill-1-9EA8B027-0F4D-4357-9F7A-D66DD355F9E6.png");
  left: 0px;
  top: 0px;
  width: 22px;
  height: 22px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Fill-1-9EA8B027-0F4D-4357-9F7A-D66DD355F9E6 {
    background-image: url("/assets/works/MovingBoxes/images/Fill-1-9EA8B027-0F4D-4357-9F7A-D66DD355F9E6@2x.png");
    background-size: 22px 22px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Fill-1-9EA8B027-0F4D-4357-9F7A-D66DD355F9E6 {
    background-image: url("/assets/works/MovingBoxes/images/Fill-1-9EA8B027-0F4D-4357-9F7A-D66DD355F9E6@3x.png");
    background-size: 22px 22px;
  }
}
.sk-asset.sk-Stroke-3-635EEC69-1606-4F5C-882A-23597D14855C {
  background-image: url("/assets/works/MovingBoxes/images/Stroke-3-635EEC69-1606-4F5C-882A-23597D14855C.png");
  left: 0px;
  top: -1px;
  width: 23px;
  height: 23px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Stroke-3-635EEC69-1606-4F5C-882A-23597D14855C {
    background-image: url("/assets/works/MovingBoxes/images/Stroke-3-635EEC69-1606-4F5C-882A-23597D14855C@2x.png");
    background-size: 23px 23px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Stroke-3-635EEC69-1606-4F5C-882A-23597D14855C {
    background-image: url("/assets/works/MovingBoxes/images/Stroke-3-635EEC69-1606-4F5C-882A-23597D14855C@3x.png");
    background-size: 23px 23px;
  }
}
.sk-asset.sk-Group-5-419CCCBA-3815-4CC1-B9EE-8ECB75434593 {
  left: 0px;
  top: 1px;
  width: 22px;
  height: 22px;
}

.sk-asset.sk-Fill-6-00EDE34F-E2A3-4768-B559-BBE421065432 {
  background-image: url("/assets/works/MovingBoxes/images/Fill-6-00EDE34F-E2A3-4768-B559-BBE421065432.png");
  left: 5px;
  top: 3px;
  width: 18px;
  height: 15px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Fill-6-00EDE34F-E2A3-4768-B559-BBE421065432 {
    background-image: url("/assets/works/MovingBoxes/images/Fill-6-00EDE34F-E2A3-4768-B559-BBE421065432@2x.png");
    background-size: 18px 15px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Fill-6-00EDE34F-E2A3-4768-B559-BBE421065432 {
    background-image: url("/assets/works/MovingBoxes/images/Fill-6-00EDE34F-E2A3-4768-B559-BBE421065432@3x.png");
    background-size: 18px 15px;
  }
}
.sk-asset.sk-Stroke-7-1EFA41F1-23E8-4FE7-95F1-37D169A0DFD9 {
  background-image: url("/assets/works/MovingBoxes/images/Stroke-7-1EFA41F1-23E8-4FE7-95F1-37D169A0DFD9.png");
  left: 4px;
  top: 2px;
  width: 20px;
  height: 16px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Stroke-7-1EFA41F1-23E8-4FE7-95F1-37D169A0DFD9 {
    background-image: url("/assets/works/MovingBoxes/images/Stroke-7-1EFA41F1-23E8-4FE7-95F1-37D169A0DFD9@2x.png");
    background-size: 20px 16px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Stroke-7-1EFA41F1-23E8-4FE7-95F1-37D169A0DFD9 {
    background-image: url("/assets/works/MovingBoxes/images/Stroke-7-1EFA41F1-23E8-4FE7-95F1-37D169A0DFD9@3x.png");
    background-size: 20px 16px;
  }
}
.sk-asset.sk-Group-9-F08314C8-D324-49FA-AE3F-17FADB9FF38C {
  left: 932px;
  top: 10759px;
  width: 23px;
  height: 23px;
}

.sk-asset.sk-Fill-1-90A3CE80-BFB0-4161-84C2-0006CD80E857 {
  background-image: url("/assets/works/MovingBoxes/images/Fill-1-90A3CE80-BFB0-4161-84C2-0006CD80E857.png");
  left: 0px;
  top: 0px;
  width: 22px;
  height: 22px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Fill-1-90A3CE80-BFB0-4161-84C2-0006CD80E857 {
    background-image: url("/assets/works/MovingBoxes/images/Fill-1-90A3CE80-BFB0-4161-84C2-0006CD80E857@2x.png");
    background-size: 22px 22px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Fill-1-90A3CE80-BFB0-4161-84C2-0006CD80E857 {
    background-image: url("/assets/works/MovingBoxes/images/Fill-1-90A3CE80-BFB0-4161-84C2-0006CD80E857@3x.png");
    background-size: 22px 22px;
  }
}
.sk-asset.sk-Stroke-3-22F260B3-5E3A-490A-9159-F31E2F736DA4 {
  background-image: url("/assets/works/MovingBoxes/images/Stroke-3-22F260B3-5E3A-490A-9159-F31E2F736DA4.png");
  left: 0px;
  top: -1px;
  width: 23px;
  height: 23px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Stroke-3-22F260B3-5E3A-490A-9159-F31E2F736DA4 {
    background-image: url("/assets/works/MovingBoxes/images/Stroke-3-22F260B3-5E3A-490A-9159-F31E2F736DA4@2x.png");
    background-size: 23px 23px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Stroke-3-22F260B3-5E3A-490A-9159-F31E2F736DA4 {
    background-image: url("/assets/works/MovingBoxes/images/Stroke-3-22F260B3-5E3A-490A-9159-F31E2F736DA4@3x.png");
    background-size: 23px 23px;
  }
}
.sk-asset.sk-Group-5-41726151-F7A5-4CD0-BA98-0DDF60C38927 {
  left: 0px;
  top: 1px;
  width: 22px;
  height: 22px;
}

.sk-asset.sk-Fill-6-AA2C039D-7D33-4B5B-B4D1-BE297C2D3D77 {
  background-image: url("/assets/works/MovingBoxes/images/Fill-6-AA2C039D-7D33-4B5B-B4D1-BE297C2D3D77.png");
  left: 5px;
  top: 3px;
  width: 18px;
  height: 15px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Fill-6-AA2C039D-7D33-4B5B-B4D1-BE297C2D3D77 {
    background-image: url("/assets/works/MovingBoxes/images/Fill-6-AA2C039D-7D33-4B5B-B4D1-BE297C2D3D77@2x.png");
    background-size: 18px 15px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Fill-6-AA2C039D-7D33-4B5B-B4D1-BE297C2D3D77 {
    background-image: url("/assets/works/MovingBoxes/images/Fill-6-AA2C039D-7D33-4B5B-B4D1-BE297C2D3D77@3x.png");
    background-size: 18px 15px;
  }
}
.sk-asset.sk-Stroke-7-B69EA206-7ABA-4038-9D44-C47FAA373FBF {
  background-image: url("/assets/works/MovingBoxes/images/Stroke-7-B69EA206-7ABA-4038-9D44-C47FAA373FBF.png");
  left: 4px;
  top: 2px;
  width: 20px;
  height: 16px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Stroke-7-B69EA206-7ABA-4038-9D44-C47FAA373FBF {
    background-image: url("/assets/works/MovingBoxes/images/Stroke-7-B69EA206-7ABA-4038-9D44-C47FAA373FBF@2x.png");
    background-size: 20px 16px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Stroke-7-B69EA206-7ABA-4038-9D44-C47FAA373FBF {
    background-image: url("/assets/works/MovingBoxes/images/Stroke-7-B69EA206-7ABA-4038-9D44-C47FAA373FBF@3x.png");
    background-size: 20px 16px;
  }
}
.sk-asset.sk-Group-9-Copy-2-2B5D37EB-7274-43B1-B77F-C4CA69DDBB3A {
  left: 932px;
  top: 10868px;
  width: 23px;
  height: 23px;
}

.sk-asset.sk-Fill-1-0AF994D3-47BF-4A67-8B16-25FD5D4545BB {
  background-image: url("/assets/works/MovingBoxes/images/Fill-1-0AF994D3-47BF-4A67-8B16-25FD5D4545BB.png");
  left: 0px;
  top: 0px;
  width: 22px;
  height: 22px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Fill-1-0AF994D3-47BF-4A67-8B16-25FD5D4545BB {
    background-image: url("/assets/works/MovingBoxes/images/Fill-1-0AF994D3-47BF-4A67-8B16-25FD5D4545BB@2x.png");
    background-size: 22px 22px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Fill-1-0AF994D3-47BF-4A67-8B16-25FD5D4545BB {
    background-image: url("/assets/works/MovingBoxes/images/Fill-1-0AF994D3-47BF-4A67-8B16-25FD5D4545BB@3x.png");
    background-size: 22px 22px;
  }
}
.sk-asset.sk-Stroke-3-C25E7F58-FCB6-47A7-9810-60C3573DA730 {
  background-image: url("/assets/works/MovingBoxes/images/Stroke-3-C25E7F58-FCB6-47A7-9810-60C3573DA730.png");
  left: 0px;
  top: -1px;
  width: 23px;
  height: 23px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Stroke-3-C25E7F58-FCB6-47A7-9810-60C3573DA730 {
    background-image: url("/assets/works/MovingBoxes/images/Stroke-3-C25E7F58-FCB6-47A7-9810-60C3573DA730@2x.png");
    background-size: 23px 23px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Stroke-3-C25E7F58-FCB6-47A7-9810-60C3573DA730 {
    background-image: url("/assets/works/MovingBoxes/images/Stroke-3-C25E7F58-FCB6-47A7-9810-60C3573DA730@3x.png");
    background-size: 23px 23px;
  }
}
.sk-asset.sk-Group-5-5242C3AA-95A5-4400-AE2F-E60E4DB2EB38 {
  left: 0px;
  top: 1px;
  width: 22px;
  height: 22px;
}

.sk-asset.sk-Fill-6-76F44AEF-4F53-4488-BFFE-7868DE24AE33 {
  background-image: url("/assets/works/MovingBoxes/images/Fill-6-76F44AEF-4F53-4488-BFFE-7868DE24AE33.png");
  left: 5px;
  top: 3px;
  width: 18px;
  height: 15px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Fill-6-76F44AEF-4F53-4488-BFFE-7868DE24AE33 {
    background-image: url("/assets/works/MovingBoxes/images/Fill-6-76F44AEF-4F53-4488-BFFE-7868DE24AE33@2x.png");
    background-size: 18px 15px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Fill-6-76F44AEF-4F53-4488-BFFE-7868DE24AE33 {
    background-image: url("/assets/works/MovingBoxes/images/Fill-6-76F44AEF-4F53-4488-BFFE-7868DE24AE33@3x.png");
    background-size: 18px 15px;
  }
}
.sk-asset.sk-Stroke-7-526A7FD3-003D-488E-BABB-9FA0FF1A65AF {
  background-image: url("/assets/works/MovingBoxes/images/Stroke-7-526A7FD3-003D-488E-BABB-9FA0FF1A65AF.png");
  left: 4px;
  top: 2px;
  width: 20px;
  height: 16px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Stroke-7-526A7FD3-003D-488E-BABB-9FA0FF1A65AF {
    background-image: url("/assets/works/MovingBoxes/images/Stroke-7-526A7FD3-003D-488E-BABB-9FA0FF1A65AF@2x.png");
    background-size: 20px 16px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Stroke-7-526A7FD3-003D-488E-BABB-9FA0FF1A65AF {
    background-image: url("/assets/works/MovingBoxes/images/Stroke-7-526A7FD3-003D-488E-BABB-9FA0FF1A65AF@3x.png");
    background-size: 20px 16px;
  }
}
.sk-asset.sk-Group-9-Copy-58CDA9BC-66F4-48C8-A60C-C0C84EFC5667 {
  left: 932px;
  top: 10825px;
  width: 23px;
  height: 23px;
}

.sk-asset.sk-Fill-1-E3D62B99-EFBE-4AF9-B418-C0B34DB690EF {
  background-image: url("/assets/works/MovingBoxes/images/Fill-1-E3D62B99-EFBE-4AF9-B418-C0B34DB690EF.png");
  left: 0px;
  top: 0px;
  width: 22px;
  height: 22px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Fill-1-E3D62B99-EFBE-4AF9-B418-C0B34DB690EF {
    background-image: url("/assets/works/MovingBoxes/images/Fill-1-E3D62B99-EFBE-4AF9-B418-C0B34DB690EF@2x.png");
    background-size: 22px 22px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Fill-1-E3D62B99-EFBE-4AF9-B418-C0B34DB690EF {
    background-image: url("/assets/works/MovingBoxes/images/Fill-1-E3D62B99-EFBE-4AF9-B418-C0B34DB690EF@3x.png");
    background-size: 22px 22px;
  }
}
.sk-asset.sk-Stroke-3-5ACCAD82-D556-441D-B44F-AE3DE029C8D6 {
  background-image: url("/assets/works/MovingBoxes/images/Stroke-3-5ACCAD82-D556-441D-B44F-AE3DE029C8D6.png");
  left: 0px;
  top: -1px;
  width: 23px;
  height: 23px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Stroke-3-5ACCAD82-D556-441D-B44F-AE3DE029C8D6 {
    background-image: url("/assets/works/MovingBoxes/images/Stroke-3-5ACCAD82-D556-441D-B44F-AE3DE029C8D6@2x.png");
    background-size: 23px 23px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Stroke-3-5ACCAD82-D556-441D-B44F-AE3DE029C8D6 {
    background-image: url("/assets/works/MovingBoxes/images/Stroke-3-5ACCAD82-D556-441D-B44F-AE3DE029C8D6@3x.png");
    background-size: 23px 23px;
  }
}
.sk-asset.sk-Group-5-7F2114ED-F802-497B-A92D-D7D8041206D2 {
  left: 0px;
  top: 1px;
  width: 22px;
  height: 22px;
}

.sk-asset.sk-Fill-6-1B138D9C-3CE7-4305-B017-4A0A702C2642 {
  background-image: url("/assets/works/MovingBoxes/images/Fill-6-1B138D9C-3CE7-4305-B017-4A0A702C2642.png");
  left: 5px;
  top: 3px;
  width: 18px;
  height: 15px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Fill-6-1B138D9C-3CE7-4305-B017-4A0A702C2642 {
    background-image: url("/assets/works/MovingBoxes/images/Fill-6-1B138D9C-3CE7-4305-B017-4A0A702C2642@2x.png");
    background-size: 18px 15px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Fill-6-1B138D9C-3CE7-4305-B017-4A0A702C2642 {
    background-image: url("/assets/works/MovingBoxes/images/Fill-6-1B138D9C-3CE7-4305-B017-4A0A702C2642@3x.png");
    background-size: 18px 15px;
  }
}
.sk-asset.sk-Stroke-7-000B5D52-EAA1-41A9-8FC9-91EDB846E7CA {
  background-image: url("/assets/works/MovingBoxes/images/Stroke-7-000B5D52-EAA1-41A9-8FC9-91EDB846E7CA.png");
  left: 4px;
  top: 2px;
  width: 20px;
  height: 16px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Stroke-7-000B5D52-EAA1-41A9-8FC9-91EDB846E7CA {
    background-image: url("/assets/works/MovingBoxes/images/Stroke-7-000B5D52-EAA1-41A9-8FC9-91EDB846E7CA@2x.png");
    background-size: 20px 16px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Stroke-7-000B5D52-EAA1-41A9-8FC9-91EDB846E7CA {
    background-image: url("/assets/works/MovingBoxes/images/Stroke-7-000B5D52-EAA1-41A9-8FC9-91EDB846E7CA@3x.png");
    background-size: 20px 16px;
  }
}
.sk-asset.sk-Group-9-Copy-3-A01EF832-71F2-4490-8591-8DE92CE551A2 {
  left: 932px;
  top: 10934px;
  width: 23px;
  height: 23px;
}

.sk-asset.sk-Fill-1-B11B4800-B0E7-431E-89B6-ECC286EC4C95 {
  background-image: url("/assets/works/MovingBoxes/images/Fill-1-B11B4800-B0E7-431E-89B6-ECC286EC4C95.png");
  left: 0px;
  top: 0px;
  width: 22px;
  height: 22px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Fill-1-B11B4800-B0E7-431E-89B6-ECC286EC4C95 {
    background-image: url("/assets/works/MovingBoxes/images/Fill-1-B11B4800-B0E7-431E-89B6-ECC286EC4C95@2x.png");
    background-size: 22px 22px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Fill-1-B11B4800-B0E7-431E-89B6-ECC286EC4C95 {
    background-image: url("/assets/works/MovingBoxes/images/Fill-1-B11B4800-B0E7-431E-89B6-ECC286EC4C95@3x.png");
    background-size: 22px 22px;
  }
}
.sk-asset.sk-Stroke-3-ABE7ADDA-D0C9-4F3A-9FD4-7576A7D04A65 {
  background-image: url("/assets/works/MovingBoxes/images/Stroke-3-ABE7ADDA-D0C9-4F3A-9FD4-7576A7D04A65.png");
  left: 0px;
  top: -1px;
  width: 23px;
  height: 23px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Stroke-3-ABE7ADDA-D0C9-4F3A-9FD4-7576A7D04A65 {
    background-image: url("/assets/works/MovingBoxes/images/Stroke-3-ABE7ADDA-D0C9-4F3A-9FD4-7576A7D04A65@2x.png");
    background-size: 23px 23px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Stroke-3-ABE7ADDA-D0C9-4F3A-9FD4-7576A7D04A65 {
    background-image: url("/assets/works/MovingBoxes/images/Stroke-3-ABE7ADDA-D0C9-4F3A-9FD4-7576A7D04A65@3x.png");
    background-size: 23px 23px;
  }
}
.sk-asset.sk-Group-5-4ABE4F57-43CA-4123-A934-6FFEDD103555 {
  left: 0px;
  top: 1px;
  width: 22px;
  height: 22px;
}

.sk-asset.sk-Fill-6-2665CA3B-CAFE-43F7-AE9C-A2DFBD10319A {
  background-image: url("/assets/works/MovingBoxes/images/Fill-6-2665CA3B-CAFE-43F7-AE9C-A2DFBD10319A.png");
  left: 5px;
  top: 3px;
  width: 18px;
  height: 15px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Fill-6-2665CA3B-CAFE-43F7-AE9C-A2DFBD10319A {
    background-image: url("/assets/works/MovingBoxes/images/Fill-6-2665CA3B-CAFE-43F7-AE9C-A2DFBD10319A@2x.png");
    background-size: 18px 15px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Fill-6-2665CA3B-CAFE-43F7-AE9C-A2DFBD10319A {
    background-image: url("/assets/works/MovingBoxes/images/Fill-6-2665CA3B-CAFE-43F7-AE9C-A2DFBD10319A@3x.png");
    background-size: 18px 15px;
  }
}
.sk-asset.sk-Stroke-7-7B69A0A5-F2D8-49CD-8380-35A9A10C57C1 {
  background-image: url("/assets/works/MovingBoxes/images/Stroke-7-7B69A0A5-F2D8-49CD-8380-35A9A10C57C1.png");
  left: 4px;
  top: 2px;
  width: 20px;
  height: 16px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Stroke-7-7B69A0A5-F2D8-49CD-8380-35A9A10C57C1 {
    background-image: url("/assets/works/MovingBoxes/images/Stroke-7-7B69A0A5-F2D8-49CD-8380-35A9A10C57C1@2x.png");
    background-size: 20px 16px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Stroke-7-7B69A0A5-F2D8-49CD-8380-35A9A10C57C1 {
    background-image: url("/assets/works/MovingBoxes/images/Stroke-7-7B69A0A5-F2D8-49CD-8380-35A9A10C57C1@3x.png");
    background-size: 20px 16px;
  }
}
.sk-asset.sk-Group-9-Copy-4-36855F70-F440-4AD1-A7E3-CFF986718E8A {
  left: 932px;
  top: 10999px;
  width: 23px;
  height: 23px;
}

.sk-asset.sk-CONSTRUCTION-DETAILS-F4A74FB7-9648-473F-A5B3-149B1E5CC5BF {
  left: 300px;
  top: 12261px;
  width: 520px;
  height: 33px;
}

.sk-asset.sk-CONSTRUCTION-DETAILS-F4A74FB7-9648-473F-A5B3-149B1E5CC5BF {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #ce7182ff;
  font-size: 21px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-CONSTRUCTION-DETAILS-F4A74FB7-9648-473F-A5B3-149B1E5CC5BF .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #ce7182;
  font-size: 21px;
}

.sk-asset.sk-155-773F136E-4F02-4206-8927-1D3E3DF03D4E {
  left: 178px;
  top: 193px;
  width: 18px;
  height: 11px;
}

.sk-asset.sk-155-773F136E-4F02-4206-8927-1D3E3DF03D4E {
  font-family: "Yantramanav", sans-serif;
  font-stretch: "undefined";
  font-weight: 200;
  font-style: undefined;
  display: table;
  color: #fefefeff;
  font-size: 8.478.0px;
  line-height: null.0px;
  opacity: 1;
  text-align: left;
  font-kerning: null;
}

.sk-asset.sk-155-773F136E-4F02-4206-8927-1D3E3DF03D4E .span1 {
  font-family: "Yantramanav", sans-serif;
  font-weight: 300;
  display: table-cell;
  vertical-align: top;
  color: #fefefe;
  font-size: 8.478.0px;
}

.sk-asset.sk-Stroke-15690-37A91F5E-CD25-47EF-BD6C-63B2548F5957 {
  background-image: url("/assets/works/MovingBoxes/images/Stroke-15690-37A91F5E-CD25-47EF-BD6C-63B2548F5957.png");
  left: 154px;
  top: 195px;
  width: 6px;
  height: 1px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Stroke-15690-37A91F5E-CD25-47EF-BD6C-63B2548F5957 {
    background-image: url("/assets/works/MovingBoxes/images/Stroke-15690-37A91F5E-CD25-47EF-BD6C-63B2548F5957@2x.png");
    background-size: 6px 1px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Stroke-15690-37A91F5E-CD25-47EF-BD6C-63B2548F5957 {
    background-image: url("/assets/works/MovingBoxes/images/Stroke-15690-37A91F5E-CD25-47EF-BD6C-63B2548F5957@3x.png");
    background-size: 6px 1px;
  }
}
.sk-asset.sk-Group-15765-44E09454-3C63-4C0E-90A0-BF0044E0FE44 {
  left: 0px;
  top: 0px;
  width: 194px;
  height: 198px;
}

.sk-asset.sk-Group-29-C58726B2-72E8-49E9-8862-C4142AEB28B5 {
  left: 799px;
  top: 12649px;
  width: 275px;
  height: 204px;
}

.sk-asset.sk-STORY-BOARD-ABE0E935-F990-42E4-8EA1-413EF82F80D3 {
  left: 305px;
  top: 5245px;
  width: 537px;
  height: 33px;
}

.sk-asset.sk-STORY-BOARD-ABE0E935-F990-42E4-8EA1-413EF82F80D3 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #ce7182ff;
  font-size: 21px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-STORY-BOARD-ABE0E935-F990-42E4-8EA1-413EF82F80D3 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #ce7182;
  font-size: 21px;
}

.sk-asset.sk-The-daily-story-of-o-4A002358-419F-46B1-A358-527B420F86F8 {
  left: 306px;
  top: 5278px;
  width: 299px;
  height: 33px;
}

.sk-asset.sk-The-daily-story-of-o-4A002358-419F-46B1-A358-527B420F86F8 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #7e7e7eff;
  font-size: 21px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  font-kerning: 0;
}

.sk-asset.sk-The-daily-story-of-o-4A002358-419F-46B1-A358-527B420F86F8 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #7e7e7e;
  font-size: 21px;
}

.sk-asset.sk-Rectangle-34625DBB-8E8F-4CEE-84F4-CBC2D6B60265 {
  background-image: url("/assets/works/MovingBoxes/images/Rectangle-34625DBB-8E8F-4CEE-84F4-CBC2D6B60265.png");
  left: -6px;
  top: -6px;
  width: 437px;
  height: 70px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Rectangle-34625DBB-8E8F-4CEE-84F4-CBC2D6B60265 {
    background-image: url("/assets/works/MovingBoxes/images/Rectangle-34625DBB-8E8F-4CEE-84F4-CBC2D6B60265@2x.png");
    background-size: 437px 70px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Rectangle-34625DBB-8E8F-4CEE-84F4-CBC2D6B60265 {
    background-image: url("/assets/works/MovingBoxes/images/Rectangle-34625DBB-8E8F-4CEE-84F4-CBC2D6B60265@3x.png");
    background-size: 437px 70px;
  }
}
.sk-asset.sk-Previous-Roomsmart-61D013F3-0707-4AA6-8D18-03E0C9D5580F {
  left: 8px;
  top: 19px;
  width: 417px;
  height: 33px;
}

.sk-asset.sk-Previous-Roomsmart-61D013F3-0707-4AA6-8D18-03E0C9D5580F {
  font-family: "Andale Mono";
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #826febff;
  font-size: 18px;
  line-height: null.0px;
  opacity: 1;
  text-align: center;
  font-kerning: -1;
}

.sk-asset.sk-Previous-Roomsmart-61D013F3-0707-4AA6-8D18-03E0C9D5580F .span1 {
  font-family: "Andale Mono";
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #826feb;
  font-size: 18px;
}

.sk-asset.sk-Path-6-AA8661A9-B816-44C5-8C3D-97A2C58BD911 {
  background-image: url("/assets/works/MovingBoxes/images/Path-6-AA8661A9-B816-44C5-8C3D-97A2C58BD911.png");
  left: 19px;
  top: 16px;
  width: 15px;
  height: 26px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Path-6-AA8661A9-B816-44C5-8C3D-97A2C58BD911 {
    background-image: url("/assets/works/MovingBoxes/images/Path-6-AA8661A9-B816-44C5-8C3D-97A2C58BD911@2x.png");
    background-size: 15px 26px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Path-6-AA8661A9-B816-44C5-8C3D-97A2C58BD911 {
    background-image: url("/assets/works/MovingBoxes/images/Path-6-AA8661A9-B816-44C5-8C3D-97A2C58BD911@3x.png");
    background-size: 15px 26px;
  }
}
.sk-asset.sk-Group-2-Copy-0CB1ED92-B726-4719-A41E-B694951ED040 {
  left: 0px;
  top: 0px;
  width: 425px;
  height: 58px;
}

.sk-asset.sk-Path-7-37CF0F9F-F9A7-40D7-936E-A62C6ACAB28F {
  background-image: url("/assets/works/MovingBoxes/images/Path-7-37CF0F9F-F9A7-40D7-936E-A62C6ACAB28F.png");
  left: 40px;
  top: 29px;
  width: 1px;
  height: 1px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Path-7-37CF0F9F-F9A7-40D7-936E-A62C6ACAB28F {
    background-image: url("/assets/works/MovingBoxes/images/Path-7-37CF0F9F-F9A7-40D7-936E-A62C6ACAB28F@2x.png");
    background-size: 1px 1px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Path-7-37CF0F9F-F9A7-40D7-936E-A62C6ACAB28F {
    background-image: url("/assets/works/MovingBoxes/images/Path-7-37CF0F9F-F9A7-40D7-936E-A62C6ACAB28F@3x.png");
    background-size: 1px 1px;
  }
}
.sk-asset.sk-Group-31-7A59E674-63C9-404B-BE17-8A8932C38E18 {
  left: 40px;
  top: 13597px;
  width: 425px;
  height: 58px;
}

.sk-asset.sk-Rectangle-FADE1527-B1B1-4E25-8215-8304C96149CE {
  background-image: url("/assets/works/MovingBoxes/images/Rectangle-FADE1527-B1B1-4E25-8215-8304C96149CE.png");
  left: 16px;
  top: -6px;
  width: 571px;
  height: 70px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Rectangle-FADE1527-B1B1-4E25-8215-8304C96149CE {
    background-image: url("/assets/works/MovingBoxes/images/Rectangle-FADE1527-B1B1-4E25-8215-8304C96149CE@2x.png");
    background-size: 571px 70px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Rectangle-FADE1527-B1B1-4E25-8215-8304C96149CE {
    background-image: url("/assets/works/MovingBoxes/images/Rectangle-FADE1527-B1B1-4E25-8215-8304C96149CE@3x.png");
    background-size: 571px 70px;
  }
}
.sk-asset.sk-Next-UX-Product-o-75BC796B-3D07-4F02-ADD2-0811D59B2D29 {
  left: 0px;
  top: 19px;
  width: 581px;
  height: 33px;
}

.sk-asset.sk-Next-UX-Product-o-75BC796B-3D07-4F02-ADD2-0811D59B2D29 {
  font-family: "Andale Mono";
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #826febff;
  font-size: 18px;
  line-height: null.0px;
  opacity: 1;
  text-align: center;
  font-kerning: -1;
}

.sk-asset.sk-Next-UX-Product-o-75BC796B-3D07-4F02-ADD2-0811D59B2D29 .span1 {
  font-family: "Andale Mono";
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #826feb;
  font-size: 18px;
}

.sk-asset.sk-Path-6-E08B407C-F5FF-4D5F-8E21-9BED4D106128 {
  background-image: url("/assets/works/MovingBoxes/images/Path-6-E08B407C-F5FF-4D5F-8E21-9BED4D106128.png");
  left: 547px;
  top: 16px;
  width: 15px;
  height: 26px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Path-6-E08B407C-F5FF-4D5F-8E21-9BED4D106128 {
    background-image: url("/assets/works/MovingBoxes/images/Path-6-E08B407C-F5FF-4D5F-8E21-9BED4D106128@2x.png");
    background-size: 15px 26px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Path-6-E08B407C-F5FF-4D5F-8E21-9BED4D106128 {
    background-image: url("/assets/works/MovingBoxes/images/Path-6-E08B407C-F5FF-4D5F-8E21-9BED4D106128@3x.png");
    background-size: 15px 26px;
  }
}
.sk-asset.sk-Group-2-Copy-2-B9FED4C4-5BE1-4EF2-99FD-F1895620D0B0 {
  left: 0px;
  top: 0px;
  width: 581px;
  height: 58px;
}

.sk-asset.sk-Path-7-Copy-212CE0B6-23D2-49CA-930C-0F893FF3AAC1 {
  background-image: url("/assets/works/MovingBoxes/images/Path-7-Copy-212CE0B6-23D2-49CA-930C-0F893FF3AAC1.png");
  left: 13px;
  top: 29px;
  width: 1px;
  height: 1px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Path-7-Copy-212CE0B6-23D2-49CA-930C-0F893FF3AAC1 {
    background-image: url("/assets/works/MovingBoxes/images/Path-7-Copy-212CE0B6-23D2-49CA-930C-0F893FF3AAC1@2x.png");
    background-size: 1px 1px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Path-7-Copy-212CE0B6-23D2-49CA-930C-0F893FF3AAC1 {
    background-image: url("/assets/works/MovingBoxes/images/Path-7-Copy-212CE0B6-23D2-49CA-930C-0F893FF3AAC1@3x.png");
    background-size: 1px 1px;
  }
}
.sk-asset.sk-Group-32-249AB99E-5EE0-49DF-97AE-D594A1470CA8 {
  left: 819px;
  top: 13597px;
  width: 581px;
  height: 58px;
}

.sk-asset.sk-Group-4-7A5BFAB8-97E7-4C4E-AA78-0ADAFA170412 {
  background-image: url("/assets/works/MovingBoxes/images/Group-4-7A5BFAB8-97E7-4C4E-AA78-0ADAFA170412.png");
  left: 0px;
  top: 0px;
  width: 40px;
  height: 40px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Group-4-7A5BFAB8-97E7-4C4E-AA78-0ADAFA170412 {
    background-image: url("/assets/works/MovingBoxes/images/Group-4-7A5BFAB8-97E7-4C4E-AA78-0ADAFA170412@2x.png");
    background-size: 40px 40px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Group-4-7A5BFAB8-97E7-4C4E-AA78-0ADAFA170412 {
    background-image: url("/assets/works/MovingBoxes/images/Group-4-7A5BFAB8-97E7-4C4E-AA78-0ADAFA170412@3x.png");
    background-size: 40px 40px;
  }
}
.sk-asset.sk-Group-7-87472541-2D64-4549-A373-DEBA73798B53 {
  background-image: url("/assets/works/MovingBoxes/images/Group-7-87472541-2D64-4549-A373-DEBA73798B53.png");
  left: 155px;
  top: 0px;
  width: 40px;
  height: 40px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Group-7-87472541-2D64-4549-A373-DEBA73798B53 {
    background-image: url("/assets/works/MovingBoxes/images/Group-7-87472541-2D64-4549-A373-DEBA73798B53@2x.png");
    background-size: 40px 40px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Group-7-87472541-2D64-4549-A373-DEBA73798B53 {
    background-image: url("/assets/works/MovingBoxes/images/Group-7-87472541-2D64-4549-A373-DEBA73798B53@3x.png");
    background-size: 40px 40px;
  }
}
.sk-asset.sk-Group-2-605F5F22-0E1D-4B39-B189-4BD4C2C18147 {
  background-image: url("/assets/works/MovingBoxes/images/Group-2-605F5F22-0E1D-4B39-B189-4BD4C2C18147.png");
  left: 80px;
  top: 0px;
  width: 40px;
  height: 40px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-Group-2-605F5F22-0E1D-4B39-B189-4BD4C2C18147 {
    background-image: url("/assets/works/MovingBoxes/images/Group-2-605F5F22-0E1D-4B39-B189-4BD4C2C18147@2x.png");
    background-size: 40px 40px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-Group-2-605F5F22-0E1D-4B39-B189-4BD4C2C18147 {
    background-image: url("/assets/works/MovingBoxes/images/Group-2-605F5F22-0E1D-4B39-B189-4BD4C2C18147@3x.png");
    background-size: 40px 40px;
  }
}
.sk-asset.sk-Group-12-DF7419D7-0D7B-476F-B8D6-4377B7D18E9E {
  left: 323px;
  top: 161px;
  width: 195px;
  height: 40px;
}

.sk-asset.sk-If-you-would-like-to-D941655C-6E52-4E8A-A029-CF59F75F650E {
  left: 0px;
  top: 0px;
  width: 840px;
  height: 72px;
}

.sk-asset.sk-If-you-would-like-to-D941655C-6E52-4E8A-A029-CF59F75F650E {
  font-family: "Avenir", sans-serif;
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 24px;
  line-height: 36px;
  opacity: 1;
  text-align: justified;
  font-kerning: 0;
}

.sk-asset.sk-If-you-would-like-to-D941655C-6E52-4E8A-A029-CF59F75F650E .span1 {
  font-family: "Avenir", sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 24px;
}

.sk-asset.sk--0FB45336-9DA0-4944-95D1-C96AC1AD5BC0 {
  left: 403px;
  top: 96px;
  width: 36px;
  height: 36px;
}

.sk-asset.sk--0FB45336-9DA0-4944-95D1-C96AC1AD5BC0 {
  font-family: "Apple Color Emoji";
  font-stretch: "undefined";
  font-weight: 400;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 36px;
  line-height: 36px;
  opacity: 1;
  text-align: justified;
  font-kerning: 0;
}

.sk-asset.sk--0FB45336-9DA0-4944-95D1-C96AC1AD5BC0 .span1 {
  font-family: "Apple Color Emoji";
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 36px;
}

.sk-asset.sk-Connect-with-me-169D3EAB-C1A2-4614-801E-CC55FE00DAA8 {
  left: 89px;
  top: 153px;
  width: 223px;
  height: 36px;
}

.sk-asset.sk-Connect-with-me-169D3EAB-C1A2-4614-801E-CC55FE00DAA8 {
  font-family: "Nunito", sans-serif;
  font-stretch: "undefined";
  font-weight: 700;
  font-style: undefined;
  display: table;
  color: #101010ff;
  font-size: 24px;
  line-height: 36px;
  opacity: 1;
  text-align: center;
  font-kerning: 0;
}

.sk-asset.sk-Connect-with-me-169D3EAB-C1A2-4614-801E-CC55FE00DAA8 .span1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  color: #101010;
  font-size: 24px;
}

.sk-asset.sk-Group-17-5C4928EE-3338-4E4E-AF23-00C1F44B7B2B {
  left: 300px;
  top: 13769px;
  width: 840px;
  height: 201px;
}

.sk-asset.sk-2-0476C3E8-E6B2-4A42-8B96-313A838EBC2C {
  background-image: url("/assets/works/MovingBoxes/images/2-0476C3E8-E6B2-4A42-8B96-313A838EBC2C.png");
  left: 302px;
  top: 1393px;
  width: 839px;
  height: 627px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-2-0476C3E8-E6B2-4A42-8B96-313A838EBC2C {
    background-image: url("/assets/works/MovingBoxes/images/2-0476C3E8-E6B2-4A42-8B96-313A838EBC2C@2x.png");
    background-size: 839px 627px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-2-0476C3E8-E6B2-4A42-8B96-313A838EBC2C {
    background-image: url("/assets/works/MovingBoxes/images/2-0476C3E8-E6B2-4A42-8B96-313A838EBC2C@3x.png");
    background-size: 839px 627px;
  }
}
.sk-asset.sk-3-8CC93CA1-C4FC-4960-A81F-D7800C0CAB9C {
  background-image: url("/assets/works/MovingBoxes/images/3-8CC93CA1-C4FC-4960-A81F-D7800C0CAB9C.png");
  left: 302px;
  top: 2371px;
  width: 842px;
  height: 914px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-3-8CC93CA1-C4FC-4960-A81F-D7800C0CAB9C {
    background-image: url("/assets/works/MovingBoxes/images/3-8CC93CA1-C4FC-4960-A81F-D7800C0CAB9C@2x.png");
    background-size: 842px 914px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-3-8CC93CA1-C4FC-4960-A81F-D7800C0CAB9C {
    background-image: url("/assets/works/MovingBoxes/images/3-8CC93CA1-C4FC-4960-A81F-D7800C0CAB9C@3x.png");
    background-size: 842px 914px;
  }
}
.sk-asset.sk-4-980EA145-EBA6-42AC-9E01-A8B9DD346F58 {
  background-image: url("/assets/works/MovingBoxes/images/4-980EA145-EBA6-42AC-9E01-A8B9DD346F58.png");
  left: 331px;
  top: 3669px;
  width: 389px;
  height: 261px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-4-980EA145-EBA6-42AC-9E01-A8B9DD346F58 {
    background-image: url("/assets/works/MovingBoxes/images/4-980EA145-EBA6-42AC-9E01-A8B9DD346F58@2x.png");
    background-size: 389px 261px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-4-980EA145-EBA6-42AC-9E01-A8B9DD346F58 {
    background-image: url("/assets/works/MovingBoxes/images/4-980EA145-EBA6-42AC-9E01-A8B9DD346F58@3x.png");
    background-size: 389px 261px;
  }
}
.sk-asset.sk-5-E53D199F-5340-46C8-B2D6-D40F803B70A0 {
  background-image: url("/assets/works/MovingBoxes/images/5-E53D199F-5340-46C8-B2D6-D40F803B70A0.png");
  left: 784px;
  top: 3692px;
  width: 333px;
  height: 235px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-5-E53D199F-5340-46C8-B2D6-D40F803B70A0 {
    background-image: url("/assets/works/MovingBoxes/images/5-E53D199F-5340-46C8-B2D6-D40F803B70A0@2x.png");
    background-size: 333px 235px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-5-E53D199F-5340-46C8-B2D6-D40F803B70A0 {
    background-image: url("/assets/works/MovingBoxes/images/5-E53D199F-5340-46C8-B2D6-D40F803B70A0@3x.png");
    background-size: 333px 235px;
  }
}
.sk-asset.sk-6-D99F7D7D-9827-478F-B7FA-692787D34510 {
  background-image: url("/assets/works/MovingBoxes/images/6-D99F7D7D-9827-478F-B7FA-692787D34510.png");
  left: 300px;
  top: 4075px;
  width: 839px;
  height: 969px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-6-D99F7D7D-9827-478F-B7FA-692787D34510 {
    background-image: url("/assets/works/MovingBoxes/images/6-D99F7D7D-9827-478F-B7FA-692787D34510@2x.png");
    background-size: 839px 969px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-6-D99F7D7D-9827-478F-B7FA-692787D34510 {
    background-image: url("/assets/works/MovingBoxes/images/6-D99F7D7D-9827-478F-B7FA-692787D34510@3x.png");
    background-size: 839px 969px;
  }
}
.sk-asset.sk-7-377085EC-5711-4DA5-BED0-459A2EAD22D3 {
  background-image: url("/assets/works/MovingBoxes/images/7-377085EC-5711-4DA5-BED0-459A2EAD22D3.png");
  left: 300px;
  top: 5376px;
  width: 841px;
  height: 790px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-7-377085EC-5711-4DA5-BED0-459A2EAD22D3 {
    background-image: url("/assets/works/MovingBoxes/images/7-377085EC-5711-4DA5-BED0-459A2EAD22D3@2x.png");
    background-size: 841px 790px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-7-377085EC-5711-4DA5-BED0-459A2EAD22D3 {
    background-image: url("/assets/works/MovingBoxes/images/7-377085EC-5711-4DA5-BED0-459A2EAD22D3@3x.png");
    background-size: 841px 790px;
  }
}
.sk-asset.sk-8-B2B72AA7-B28B-4A18-BAC4-4D3338F5772E {
  background-image: url("/assets/works/MovingBoxes/images/8-B2B72AA7-B28B-4A18-BAC4-4D3338F5772E.png");
  left: 300px;
  top: 6226px;
  width: 841px;
  height: 828px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-8-B2B72AA7-B28B-4A18-BAC4-4D3338F5772E {
    background-image: url("/assets/works/MovingBoxes/images/8-B2B72AA7-B28B-4A18-BAC4-4D3338F5772E@2x.png");
    background-size: 841px 828px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-8-B2B72AA7-B28B-4A18-BAC4-4D3338F5772E {
    background-image: url("/assets/works/MovingBoxes/images/8-B2B72AA7-B28B-4A18-BAC4-4D3338F5772E@3x.png");
    background-size: 841px 828px;
  }
}
.sk-asset.sk-9-E014ED80-7759-416D-97ED-6C7669F39FE6 {
  background-image: url("/assets/works/MovingBoxes/images/9-E014ED80-7759-416D-97ED-6C7669F39FE6.png");
  left: 297px;
  top: 7073px;
  width: 843px;
  height: 731px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-9-E014ED80-7759-416D-97ED-6C7669F39FE6 {
    background-image: url("/assets/works/MovingBoxes/images/9-E014ED80-7759-416D-97ED-6C7669F39FE6@2x.png");
    background-size: 843px 731px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-9-E014ED80-7759-416D-97ED-6C7669F39FE6 {
    background-image: url("/assets/works/MovingBoxes/images/9-E014ED80-7759-416D-97ED-6C7669F39FE6@3x.png");
    background-size: 843px 731px;
  }
}
.sk-asset.sk-10-A4CE260E-1584-4F69-86F7-71B7CF2EB0A5 {
  background-image: url("/assets/works/MovingBoxes/images/10-A4CE260E-1584-4F69-86F7-71B7CF2EB0A5.png");
  left: 300px;
  top: 8103px;
  width: 400px;
  height: 530px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-10-A4CE260E-1584-4F69-86F7-71B7CF2EB0A5 {
    background-image: url("/assets/works/MovingBoxes/images/10-A4CE260E-1584-4F69-86F7-71B7CF2EB0A5@2x.png");
    background-size: 400px 530px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-10-A4CE260E-1584-4F69-86F7-71B7CF2EB0A5 {
    background-image: url("/assets/works/MovingBoxes/images/10-A4CE260E-1584-4F69-86F7-71B7CF2EB0A5@3x.png");
    background-size: 400px 530px;
  }
}
.sk-asset.sk-11-9F824A3E-E692-4FBA-9330-A5B887EFBEC0 {
  background-image: url("/assets/works/MovingBoxes/images/11-9F824A3E-E692-4FBA-9330-A5B887EFBEC0.png");
  left: 740px;
  top: 8103px;
  width: 400px;
  height: 530px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-11-9F824A3E-E692-4FBA-9330-A5B887EFBEC0 {
    background-image: url("/assets/works/MovingBoxes/images/11-9F824A3E-E692-4FBA-9330-A5B887EFBEC0@2x.png");
    background-size: 400px 530px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-11-9F824A3E-E692-4FBA-9330-A5B887EFBEC0 {
    background-image: url("/assets/works/MovingBoxes/images/11-9F824A3E-E692-4FBA-9330-A5B887EFBEC0@3x.png");
    background-size: 400px 530px;
  }
}
.sk-asset.sk-12-21F3B8FE-6468-41A9-AF4A-0530176A4F22 {
  background-image: url("/assets/works/MovingBoxes/images/12-21F3B8FE-6468-41A9-AF4A-0530176A4F22.png");
  left: 300px;
  top: 8673px;
  width: 400px;
  height: 530px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-12-21F3B8FE-6468-41A9-AF4A-0530176A4F22 {
    background-image: url("/assets/works/MovingBoxes/images/12-21F3B8FE-6468-41A9-AF4A-0530176A4F22@2x.png");
    background-size: 400px 530px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-12-21F3B8FE-6468-41A9-AF4A-0530176A4F22 {
    background-image: url("/assets/works/MovingBoxes/images/12-21F3B8FE-6468-41A9-AF4A-0530176A4F22@3x.png");
    background-size: 400px 530px;
  }
}
.sk-asset.sk-13-F4C529CA-0708-4C01-B45C-DAB2981E7484 {
  background-image: url("/assets/works/MovingBoxes/images/13-F4C529CA-0708-4C01-B45C-DAB2981E7484.png");
  left: 740px;
  top: 8673px;
  width: 400px;
  height: 530px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-13-F4C529CA-0708-4C01-B45C-DAB2981E7484 {
    background-image: url("/assets/works/MovingBoxes/images/13-F4C529CA-0708-4C01-B45C-DAB2981E7484@2x.png");
    background-size: 400px 530px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-13-F4C529CA-0708-4C01-B45C-DAB2981E7484 {
    background-image: url("/assets/works/MovingBoxes/images/13-F4C529CA-0708-4C01-B45C-DAB2981E7484@3x.png");
    background-size: 400px 530px;
  }
}
.sk-asset.sk-14-760818D6-7113-4D3D-B2B2-7F98FC0D3CCA {
  background-image: url("/assets/works/MovingBoxes/images/14-760818D6-7113-4D3D-B2B2-7F98FC0D3CCA.png");
  left: 300px;
  top: 9241px;
  width: 840px;
  height: 807px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-14-760818D6-7113-4D3D-B2B2-7F98FC0D3CCA {
    background-image: url("/assets/works/MovingBoxes/images/14-760818D6-7113-4D3D-B2B2-7F98FC0D3CCA@2x.png");
    background-size: 840px 807px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-14-760818D6-7113-4D3D-B2B2-7F98FC0D3CCA {
    background-image: url("/assets/works/MovingBoxes/images/14-760818D6-7113-4D3D-B2B2-7F98FC0D3CCA@3x.png");
    background-size: 840px 807px;
  }
}
.sk-asset.sk-15-B0E3DC2A-C110-4670-B524-B4D06DDB5D3B {
  background-image: url("/assets/works/MovingBoxes/images/15-B0E3DC2A-C110-4670-B524-B4D06DDB5D3B.png");
  left: 107px;
  top: 10415px;
  width: 416px;
  height: 171px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-15-B0E3DC2A-C110-4670-B524-B4D06DDB5D3B {
    background-image: url("/assets/works/MovingBoxes/images/15-B0E3DC2A-C110-4670-B524-B4D06DDB5D3B@2x.png");
    background-size: 416px 171px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-15-B0E3DC2A-C110-4670-B524-B4D06DDB5D3B {
    background-image: url("/assets/works/MovingBoxes/images/15-B0E3DC2A-C110-4670-B524-B4D06DDB5D3B@3x.png");
    background-size: 416px 171px;
  }
}
.sk-asset.sk-16-6C003A71-FF8E-4F0E-A0E0-7BF0C7EADE7E {
  background-image: url("/assets/works/MovingBoxes/images/16-6C003A71-FF8E-4F0E-A0E0-7BF0C7EADE7E.png");
  left: 596px;
  top: 10429px;
  width: 300px;
  height: 171px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-16-6C003A71-FF8E-4F0E-A0E0-7BF0C7EADE7E {
    background-image: url("/assets/works/MovingBoxes/images/16-6C003A71-FF8E-4F0E-A0E0-7BF0C7EADE7E@2x.png");
    background-size: 300px 171px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-16-6C003A71-FF8E-4F0E-A0E0-7BF0C7EADE7E {
    background-image: url("/assets/works/MovingBoxes/images/16-6C003A71-FF8E-4F0E-A0E0-7BF0C7EADE7E@3x.png");
    background-size: 300px 171px;
  }
}
.sk-asset.sk-17-5D5184C1-66A1-477B-917A-2193D659D0C2 {
  background-image: url("/assets/works/MovingBoxes/images/17-5D5184C1-66A1-477B-917A-2193D659D0C2.png");
  left: 955px;
  top: 10420px;
  width: 380px;
  height: 171px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-17-5D5184C1-66A1-477B-917A-2193D659D0C2 {
    background-image: url("/assets/works/MovingBoxes/images/17-5D5184C1-66A1-477B-917A-2193D659D0C2@2x.png");
    background-size: 380px 171px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-17-5D5184C1-66A1-477B-917A-2193D659D0C2 {
    background-image: url("/assets/works/MovingBoxes/images/17-5D5184C1-66A1-477B-917A-2193D659D0C2@3x.png");
    background-size: 380px 171px;
  }
}
.sk-asset.sk-21-3AB3E7E0-5532-42B7-99E9-A9B21413FF84 {
  background-image: url("/assets/works/MovingBoxes/images/21-3AB3E7E0-5532-42B7-99E9-A9B21413FF84.png");
  left: 300px;
  top: 12348px;
  width: 841px;
  height: 501px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-21-3AB3E7E0-5532-42B7-99E9-A9B21413FF84 {
    background-image: url("/assets/works/MovingBoxes/images/21-3AB3E7E0-5532-42B7-99E9-A9B21413FF84@2x.png");
    background-size: 841px 501px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-21-3AB3E7E0-5532-42B7-99E9-A9B21413FF84 {
    background-image: url("/assets/works/MovingBoxes/images/21-3AB3E7E0-5532-42B7-99E9-A9B21413FF84@3x.png");
    background-size: 841px 501px;
  }
}
.sk-asset.sk-22-EEF65462-D6E2-47EC-BABC-F09B23E2DC45 {
  background-image: url("/assets/works/MovingBoxes/images/22-EEF65462-D6E2-47EC-BABC-F09B23E2DC45.png");
  left: 300px;
  top: 12906px;
  width: 840px;
  height: 497px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .sk-asset.sk-22-EEF65462-D6E2-47EC-BABC-F09B23E2DC45 {
    background-image: url("/assets/works/MovingBoxes/images/22-EEF65462-D6E2-47EC-BABC-F09B23E2DC45@2x.png");
    background-size: 840px 497px;
  }
}
@media only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-device-pixel-ratio: 3) {
  .sk-asset.sk-22-EEF65462-D6E2-47EC-BABC-F09B23E2DC45 {
    background-image: url("/assets/works/MovingBoxes/images/22-EEF65462-D6E2-47EC-BABC-F09B23E2DC45@3x.png");
    background-size: 840px 497px;
  }
}
</style>
